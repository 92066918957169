import { DictionaryInfo, DictionaryModel, DictionaryScheme, DictionarySchemeColumn } from '@models/dictionary-model';
import { APIUtilError } from '@utils/api';
import { ObjectLiteral } from '@utils/object.utils';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';

export interface DictionaryViewModelStartParams {
    t: any;
    altname: string | undefined;
    id: string | undefined;
    showError: (title: string, description: string) => void;
}

export class DictionaryViewModel {
    private dictionaryModel = new DictionaryModel();

    public loading: boolean = false;
    public currentPage: number = 1;
    public pageSize: number = 20;
    public dataTotal: number = 0;
    public dictionaryInfo?: DictionaryInfo | undefined;
    public rawData: ObjectLiteral[] = [];
    public scheme?: DictionaryScheme;
    public primaryKey: string = '';
    public visibleColumns: DictionarySchemeColumn[] = [];
    public dataOnEdit?: ObjectLiteral | undefined;
    private _searchValue: string = '';
    public get searchValue() {
        return this._searchValue;
    }
    public set searchValue(value: string) {
        runInAction(() => {
            this._searchValue = value;
        });
        if (!this.startParams.altname) return;
        if (value.length === 0) {
            runInAction(() => {
                this.currentPage = 1;
            });
            this.debouncedFetch(this.startParams.altname, 1, this.pageSize, '');
        } else {
            this.debouncedFetch(this.startParams.altname, 1, this.pageSize, value);
        }
    }

    constructor(private startParams: DictionaryViewModelStartParams) {
        makeAutoObservable(this);

        if (startParams.altname) {
            this.fetchScheme(startParams.altname);
            this.fetchDictionary(startParams.altname, this.currentPage, this.pageSize);
        }
    }

    private async fetchScheme(altname: string) {
        runInAction(() => {
            this.loading = true;
        });
        try {
            const result = await this.dictionaryModel.getScheme(altname);
            const primaryKey = this.dictionaryModel.getPrimaryKey(result.data);

            if (primaryKey) {
                runInAction(() => {
                    this.primaryKey = primaryKey;
                    this.scheme = result.data;
                    this.visibleColumns = result.data.columns.filter((item) => item.is_table_show);
                });
            }
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    public onSearchValueChange = (value: string) => {};

    private debouncedFetch = debounce(async (altname: string, page: number, pageSize: number, search: string) => {
        this.fetchDictionary(altname, page, pageSize, search);
    }, 400);

    private async fetchDictionary(altname: string, page: number, pageSize: number, search?: string) {
        runInAction(() => {
            this.loading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: altname, page: page, page_size: pageSize, search: search });
            runInAction(() => {
                this.dictionaryInfo = result.info;
                this.rawData = result.data;
                this.dataTotal = result.total;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    public createTemplate = () => {
        runInAction(() => {
            this.dataOnEdit = {};
        });
    };

    public setOnEdit = (id: string | number) => {
        let copy = [...this.rawData];
        const index = copy.map((item) => `${item[this.primaryKey]}`).indexOf(`${id}`);
        if (index >= 0) {
            runInAction(() => {
                this.dataOnEdit = copy[index];
            });
        }
    };

    public onCancelEdit = () => {
        runInAction(() => {
            this.dataOnEdit = undefined;
        });
    };

    public onCreate = async (data: ObjectLiteral) => {
        if (!this.startParams.altname) return;
        await this.fetchDictionary(this.startParams.altname, 1, this.pageSize);
    };

    public onUpdate = async (id: string, data: ObjectLiteral) => {
        if (!this.startParams.altname) return;

        let copy = [...this.rawData];
        const index = copy.map((item) => item[this.primaryKey]).indexOf(id);
        copy[index] = data;
        this.rawData = copy;
    };

    public onDelete = async (id: string | number) => {
        if (!this.startParams.altname) return;
        runInAction(() => {
            this.loading = true;
        });
        try {
            await this.dictionaryModel.deleteDictionaryItem(this.startParams.altname, id);
            runInAction(() => {
                this.rawData = [...this.rawData].filter((i) => i.id !== id);
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.empty'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    public onChangePagination = async (page: number, pageSize: number) => {
        if (!this.startParams.altname) return;
        if (pageSize !== this.pageSize) {
            runInAction(() => {
                this.pageSize = pageSize;
            });
        }

        runInAction(() => {
            this.currentPage = page;
        });
        this.fetchDictionary(this.startParams.altname, page, pageSize, this._searchValue);
    };

    public onExportExcel = async () => {
        if (!this.startParams.altname) return;
        runInAction(() => {
            this.loading = true;
        });
        try {
            await this.dictionaryModel.downloadExcelFile(
                this.startParams.altname,
                `${this.startParams.t('dictionaries.excel.filename')}_${this.dictionaryInfo?.name || ''}_${dayjs(new Date()).format(
                    'DD.MM.YYYY_HH:mm'
                )}.xlsx`
            );
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.empty'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };
}
