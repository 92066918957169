import { RootModel } from '@models/root-model';
import { APIUtilError } from '@utils/api';
import { makeAutoObservable, runInAction } from 'mobx';

export interface AuthViewModelStartParams {
    t: any;
    showError: (title: string, description: string) => void;
}

export class AuthViewModel {
    public isLoading: boolean = false;
    public username = '';
    public password = '';
    public isLoginEnabled = false;

    constructor(private startParams: AuthViewModelStartParams) {
        makeAutoObservable(this);
    }

    public onChangeUsername = (username: string) => {
        runInAction(() => {
            this.username = username;
            this.isLoginEnabled = this.isValidCredentials();
        });
    };

    public onChangePassword = (password: string) => {
        runInAction(() => {
            this.password = password;
            this.isLoginEnabled = this.isValidCredentials();
        });
    };

    public async onClickLogin() {
        if (!this.isLoginEnabled) {
            return;
        }

        await this.login(this.username, this.password);
    }

    private isValidCredentials = () => {
        return (
            (String(this.username)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ) &&
                this.password.length >= 3) ||
            false
        );
    };

    private async login(username: string, password: string) {
        runInAction(() => {
            this.isLoading = true;
        });

        try {
            const result = await RootModel.authModel.signIn(username, password);
            const profile = RootModel.authModel.extractProfileFromToken(result.access_token);

            if (!profile.permissions.find((item) => item === (process.env.REACT_APP_ADMIN_PERMISSION || 'admin-permission'))) {
                this.startParams.showError(this.startParams.t('auth.error'), this.startParams.t('auth.access_denied'));
                return;
            }
            RootModel.authModel.applyTokens(result);
            RootModel.authModel.applyProfile(profile);
        } catch (error: any) {
            this.startParams.showError(this.startParams.t('auth.error'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }
}
