import { observer } from 'mobx-react-lite';
import React from 'react';
import { Col, Divider, Row, Typography, Button, notification } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useViewModel } from '@models/model';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StatementsViewModel } from './statements.view.model';
import { ContentTable } from './table';
import { StatementData } from '@models/statements-model';

const StatementsView: React.FC = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (description: string) => {
        notifyer.error({
            message: t('common.error.empty'),
            description: description
        });
    };

    const viewModel = useViewModel(() => new StatementsViewModel((description: string) => showErrorNotification(description)));

    const onCreate = () => {
        navigate('create');
    };

    const onEdit = (statementId?: number | undefined) => {
        if (!statementId) return;
        window.open(`/statements-manage/edit/${statementId || ''}`, '_blank', 'noreferrer');
    };

    return (
        <div>
            {contextHolder}

            <Row gutter={12} align="middle" justify="space-between">
                <Col>
                    <Typography.Title>{t('statements.title')}</Typography.Title>
                </Col>
                <Col>
                    <Space>
                        <Button type="primary" icon={<PlusCircleOutlined />} onClick={onCreate}>
                            {t('common.create')}
                        </Button>
                    </Space>
                </Col>
            </Row>

            <Divider style={{ marginTop: 0 }} />

            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <ContentTable loading={viewModel.isLoading} rows={viewModel.statements} onEdit={(statement: StatementData) => onEdit(statement.id)} />
            </Space>
        </div>
    );
});

export default StatementsView;
