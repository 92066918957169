import {
    UserFull,
    UserModel,
    UserInst,
    UserOrg,
    UserActivity,
    UserMember,
    UserMemberRaw,
    UserInstPermissions,
    UserInstPermissionsRequest,
    UserPermissions,
    UserOrgFile,
    UserRole,
    UserHistoryLoggerData
} from '@models/users-model';
import { APIUtilError } from '@utils/api';
import { makeAutoObservable, runInAction } from 'mobx';

export interface UserEdtiViewModelStartParams {
    t: any;
    userId: string | undefined;
    showError: (title: string, description: string) => void;
    showSuccess: (title: string, description: string) => void;
    onClose: () => void;
}

export interface UserActivityYearsItem {
    value: number;
    label: string;
}

export class UserEditViewModel {
    private userModel = new UserModel();

    public isLoading: boolean = false;
    public user: UserFull = new UserFull();

    public loadingOrg: boolean = false;
    public orgs: UserOrg[] = [];
    public inst: UserInst[] = [];

    public userActivity: UserActivity[] = [];
    public userActivityYears: UserActivityYearsItem[] = [];
    public userActivitySelectedYear: number = new Date().getFullYear();
    public userActivityTotal: number = 0;
    public userActivityCurrentPage: number = 1;
    public userActivityAvailablePageSizes = [10, 20, 50, 100];
    public userActivityPageSize: number = 20;

    public userMember: UserMember = new UserMember();
    public userMembersRaw: UserMemberRaw[] = [];

    public userInstPermissions: UserInstPermissions[] = [];
    public editedInstPermission: UserInstPermissions = new UserInstPermissions();
    public userInstPermissionsRequest: UserInstPermissionsRequest[] = [];

    public userPermissions: UserPermissions[] = [];
    public userPermissionsDict: UserPermissions[] = [];

    public userFiles: UserOrgFile[] = [];
    public rolesDict: UserRole[] = [];

    public isHistoryLoading: boolean = false;
    public historyItems: UserHistoryLoggerData[] = [];
    public historyTotal: number = 0;
    public historyPageSize = 4;
    public historyCurrentItem?: UserHistoryLoggerData | undefined;

    constructor(private startParams: UserEdtiViewModelStartParams) {
        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        if (this.startParams.userId && this.startParams.userId.length > 0) {
            await this.fetchUser(this.startParams.userId);
            await this.fetchRolesDict();
            await this.fetchUserActivity(this.startParams.userId);
            await this.fetchUserMember(this.startParams.userId);
            await this.fetchUserMembersRaw(this.startParams.userId);
            await this.fetchUserInstPermissions(this.startParams.userId);
            await this.fetchUserInstPermissionsRequest(this.startParams.userId);
            await this.fetchUserPermissionsDict();
            await this.fetchUserPermissions(this.startParams.userId);
            await this.getUserFiles(this.startParams.userId);

            await this.fetchHistoryItems(this.startParams.userId, 1, this.historyPageSize);
        } else {
            this.startParams.showError(this.startParams.t('common.error.fetch'), '');
        }
    }

    private async fetchUser(userId: string) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.userModel.getUserById(userId);
            runInAction(() => {
                this.user = result.data;
            });
            if (result.data.org_dadata_id) this.fetchOrg(this.user.org_short_name);
            if (result.data.inst_id) this.fetchInst(this.user.inst_name);
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('common.error.fetch')}: ${error}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchRolesDict() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.userModel.getRolesDict();
            runInAction(() => {
                this.rolesDict = result.data;
            });
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('common.error.fetch')}: ${error}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public async fetchOrg(search: string = '') {
        runInAction(() => {
            this.loadingOrg = true;
        });
        try {
            const result = await this.userModel.searhOrg(search);
            runInAction(() => {
                this.orgs = result.data;
            });
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('users.content.orgs.error')}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loadingOrg = false;
            });
        }
    }

    public async fetchInst(search: string = '') {
        runInAction(() => {
            this.loadingOrg = true;
        });
        try {
            const result = await this.userModel.searhInst(search);
            runInAction(() => {
                this.inst = result.data;
            });
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('users.content.inst.error')}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loadingOrg = false;
            });
        }
    }

    private async fetchUserActivity(userId: string, limit: number = this.userActivityPageSize, offset: number = 0) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.userModel.getUserActivity({ userId, limit, offset });
            runInAction(() => {
                this.userActivity = result.data;
                this.userActivityTotal = result.total || 0;
            });
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('common.error.fetch')}: ${error}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public onChangeActivityPagination = async (page: number, pageSize: number) => {
        if (pageSize !== this.userActivityPageSize) {
            runInAction(() => {
                this.userActivityPageSize = pageSize;
            });
        }
        runInAction(() => {
            this.userActivityCurrentPage = page;
        });
        if (this.startParams.userId)
            this.fetchUserActivity(this.startParams.userId, this.userActivityPageSize, (page - 1) * this.userActivityPageSize);
    };

    public async deleteActivityItem(userId: string, itemId: number, status: boolean = false) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.userModel.deleteActivityItem(userId, itemId, status);
            this.userActivity.map((i: any) => {
                if (i.id === itemId) i.is_deleted = status;
                return i;
            });
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('common.error.fetch')}: ${error}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public onSave = async (data: any) => {
        await this.update(data);
    };

    private update = async (data: any) => {
        if (!this.user?.id) return;
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.userModel.updateUser(this.user.id, data);
            this.startParams.showSuccess(this.startParams.t('common.saved'), '');
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.save'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public resetUserPassword = async () => {
        if (!this.user.id) {
            return;
        }
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.userModel.resetUserPassword(this.user.id);
            this.startParams.showSuccess(this.startParams.t('users.content.reset_pwd.success_text'), '');
        } catch (error) {
            this.startParams.showError(this.startParams.t('users.content.reset_pwd.error_text'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public deleteUser = async () => {
        if (!this.user.id) {
            return;
        }
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.userModel.deleteUser(this.user.id);
            this.startParams.showSuccess(this.startParams.t('users.content.delete.success_text'), '');
            this.startParams.onClose();
        } catch (error) {
            this.startParams.showError(this.startParams.t('users.content.delete.error_text'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public toggleUserActiveStatus = async () => {
        if (!this.user.id) {
            return;
        }
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.userModel.updateUser(this.user.id, { is_active: !this.user.is_active });

            this.startParams.showSuccess(
                this.startParams.t(!this.user.is_active ? 'users.content.active.unlocked' : 'users.content.active.locked'),
                ''
            );
            runInAction(() => {
                this.user.is_active = !this.user.is_active;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.save'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public onSaveSecure = async (data: any) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.userModel.saveSecure(data);
            this.startParams.showSuccess(this.startParams.t('common.saved'), '');
        } catch (error: any) {
            this.startParams.showError(`${this.startParams.t('common.error.fetch')}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public onSaveOrg = async (data: any) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            // ORG
            if ('org_dadata_id' in data && data.org_dadata_id) {
                await this.userModel.bindOrg(data.id, data.org_dadata_id);
            } else if ('org_dadata_id' in data) {
                await this.userModel.deleteUserOrg(data.id);
            }
            // INST
            if ('inst_id' in data) {
                if (data.inst_id) {
                    await this.userModel.bindInst(data.id, data.inst_id);
                } else await this.userModel.deleteUserInst(data.id);
                if (this.startParams.userId) {
                    await this.fetchUserInstPermissions(this.startParams.userId);
                    await this.fetchUserInstPermissionsRequest(this.startParams.userId);
                }
            }
            this.startParams.showSuccess(this.startParams.t('common.saved'), '');
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('common.error.fetch')}: ${error}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public fetchUserMember = async (userId: string) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.userModel.getUserMember(userId);
            runInAction(() => {
                this.userMember = result.data;
            });
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('common.error.fetch')}: ${error}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public fetchUserMembersRaw = async (userId: string) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.userModel.getUserMembersRaw(userId);
            runInAction(() => {
                this.userMembersRaw = result.data;
            });
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('common.error.fetch')}: ${error}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public fetchUserInstPermissions = async (userId: string) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.userModel.getUserInstPermissions(userId);
            runInAction(() => {
                this.userInstPermissions = result.data;
            });
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('common.error.fetch')}: ${error}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public fetchUserInstPermissionsRequest = async (userId: string) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.userModel.getUserInstPermissionsRequest(userId);
            runInAction(() => {
                this.userInstPermissionsRequest = result.data;
            });
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('common.error.fetch')}: ${error}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public editUserInstPermissions = async () => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const params = {
                user_inst_id: this.editedInstPermission.user_inst_id,
                can_read: this.editedInstPermission.can_read,
                can_edit: this.editedInstPermission.can_edit,
                can_approve: this.editedInstPermission.can_approve,
                is_deleted: this.editedInstPermission.permission_is_deleted
            };
            if (this.startParams.userId) {
                const res = await this.userModel.editUserInstPermissions(this.startParams.userId, params);

                let copy = [...this.userInstPermissions];
                const index = copy.map((item) => item.user_inst_id).indexOf(res.data.user_inst_id);
                copy[index].can_read = res.data.can_read;
                copy[index].can_edit = res.data.can_edit;
                copy[index].can_approve = res.data.can_approve;
                copy[index].permission_is_deleted = this.editedInstPermission.permission_is_deleted;
                this.userInstPermissions = copy;
            }
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('common.error.fetch')}: ${error}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public acceptRejectInstPermissionsRequest = async (userInstId: number, status: boolean) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            if (this.startParams.userId) {
                const res = await this.userModel.acceptRejectInstPermissionsRequest(this.startParams.userId, userInstId, status);
                runInAction(() => {
                    let copy = [...this.userInstPermissionsRequest];
                    const index = copy.map((item) => item.user_inst_id).indexOf(res.data.user_inst_id);
                    copy[index].status = status ? 2 : 3;
                    copy[index].status_desc = res.data.status_desc;
                    this.userInstPermissionsRequest = copy;
                });
                await this.fetchUserInstPermissions(this.startParams.userId);
            }
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('common.error.fetch')}: ${error}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public fetchUserPermissions = async (userId: string) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.userModel.getUserPermissions(userId);
            runInAction(() => {
                this.userPermissions = result.data;
            });
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('common.error.fetch')}: ${error}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public fetchUserPermissionsDict = async () => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.userModel.getUserPermissionsDict();
            runInAction(() => {
                this.userPermissionsDict = result.data;
            });
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('common.error.fetch')}: ${error}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public editUserPermissions = async (userId: string, permissions: number[]) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.userModel.editUserPermissions(userId, permissions);
            await this.fetchUserPermissions(userId);
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('common.error.fetch')}: ${error}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    // FILES

    public getUploaderURL() {
        if (!this.startParams.userId) return '';
        return this.userModel.uploadURL(this.startParams.userId);
    }

    public getUploaderHeaders() {
        return this.userModel.uploadHeaders;
    }

    public getDownloadUrl(uuid: string): string | undefined {
        if (!this.startParams.userId) return undefined;
        return this.userModel.getDownloarFileUrl(this.startParams.userId, uuid);
    }

    public getUserFiles = async (userId: string) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const res = await this.userModel.getUserFiles(userId);
            this.userFiles = res.data;
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('common.error.fetch')}: ${error}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public async deleteFile(uuid: string): Promise<boolean> {
        if (!this.startParams.userId) return false;

        try {
            await this.userModel.deleteFile(this.startParams.userId, uuid);
            return true;
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('common.error.fetch')}: ${error}`, (error as APIUtilError).localizedDescription);
            return false;
        }
    }

    public async onEditFileTitle(uuid: string, title: string) {
        if (!this.startParams.userId) return false;

        try {
            await this.userModel.saveFileTitle(this.startParams.userId, uuid, title);
            return true;
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('common.error.fetch')}: ${error}`, (error as APIUtilError).localizedDescription);
            return false;
        }
    }

    // HISTORY

    public async fetchNextHistoryItemsPage() {
        if (!this.startParams.userId) return;
        await this.fetchHistoryItems(this.startParams.userId, 1, this.historyPageSize + 1);
    }

    private async fetchHistoryItems(user_id: string, page: number, pageSize: number) {
        runInAction(() => {
            this.isHistoryLoading = true;
        });
        try {
            const result = await this.userModel.getHistoryItems(user_id, page, pageSize);
            runInAction(() => {
                this.historyItems = result.data;
                this.historyTotal = result.total;
                this.historyPageSize = pageSize;
            });
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('common.error.fetch')}: ${error}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isHistoryLoading = false;
            });
        }
    }

    public async onSelectHistoryItem(historyId: number) {
        if (!this.startParams.userId) return;
        await this.fetchHistoryItem(this.startParams.userId, historyId);
    }

    private async fetchHistoryItem(userId: string, historyId: number) {
        runInAction(() => {
            this.isHistoryLoading = true;
        });
        try {
            const result = await this.userModel.getHistoryItem(userId, historyId);
            runInAction(() => {
                this.historyCurrentItem = result.data;
            });
        } catch (error) {
            this.startParams.showError(`${this.startParams.t('common.error.fetch')}: ${error}`, (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isHistoryLoading = false;
            });
        }
    }
}
