import { Table, Tooltip, Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { ObjectLiteral } from '@utils/object.utils';
import { DictionarySchemeColumn } from '@models/dictionary-model';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

interface TableProps {
    rows: ObjectLiteral[];
    primaryKey: string;
    columns: DictionarySchemeColumn[];
    total: number;
    currentPage: number;
    pageSize: number;
    loading: boolean;
    onEdit: (id: string | number) => void;
    onDelete: (id: string | number) => void;
    onChangePagination: (page: number, pageSize: number) => void;
}

export const DictionaryTable = (props: TableProps) => {
    const { t } = useTranslation();

    const onChangePage = (page: number, pageSize: number) => {
        props.onChangePagination(page, pageSize);
    };

    const getColumns = () => {
        let columns: any[] = props.columns.map((column) => {
            return {
                title: column.name,
                dataIndex: column.key
            };
        });
        columns.push({
            title: '',
            width: '5%',
            dataIndex: 'operations',
            fixed: 'right',
            render: (_: any, record: ObjectLiteral) => {
                return (
                    <Space key={`operation-${record.index}`} direction="horizontal" size="small" align="center">
                        <Tooltip title={t('common.edit')}>
                            <Button onClick={() => (record[props.primaryKey] !== undefined ? props.onEdit(record[props.primaryKey]) : {})}>
                                <EditOutlined />
                            </Button>
                        </Tooltip>
                        <Tooltip title={t('common.delete')}>
                            <Button onClick={() => (record[props.primaryKey] !== undefined ? props.onDelete(record[props.primaryKey]) : {})}>
                                <DeleteOutlined />
                            </Button>
                        </Tooltip>
                    </Space>
                );
            }
        });
        return columns;
    };

    return (
        <div style={{ maxWidth: '100%', overflowX: 'scroll', backgroundColor: 'white' }}>
            <Table
                size="small"
                loading={props.loading}
                bordered
                dataSource={props.rows}
                columns={getColumns()}
                rowClassName="row"
                rowKey={props.primaryKey}
                pagination={{
                    current: props.currentPage,
                    pageSize: props.pageSize,
                    total: props.total,
                    position: ['bottomCenter'],
                    onChange: onChangePage
                }}
            />
        </div>
    );
};
