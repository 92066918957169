import { Badge, Button, Calendar, Divider, Drawer, List, Space, Spin, Typography, notification, Row, Col, Card, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { createSearchParams } from 'react-router-dom';
import { useViewModel } from '@models/model';
import { EventsDashboardViewModel } from './events-dashboard.view.model';
import { format, startOfDay, startOfMonth } from 'date-fns';
import { CalendarMode } from 'antd/es/calendar/generateCalendar';
import { useState } from 'react';
import { CloseOutlined, ClockCircleOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { EventData } from '@models/event-model';
import ListCard from './events-dashboard.list-card.view';
import { EventsFilterParamConfig } from '@interfaces/events.interface';

import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
dayjs.locale('ru');

const EventsDashboardView: React.FC = observer(() => {
    const { t } = useTranslation();
    const [isEventsListVisible, setIsEventsListVisible] = useState(false);
    const [eventsListTitle, setEventsListTitle] = useState('');

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const viewModel = useViewModel(
        () =>
            new EventsDashboardViewModel({
                t,
                showError: showErrorNotification
            })
    );

    const geDayData = (value: Dayjs) => {
        let listData: any[] = [];
        const valueDate = value.toDate();
        const valueStartOfDay = startOfDay(valueDate);

        const eventsCount = viewModel.calendarEvents[format(valueStartOfDay, 'yyyy-MM-dd')];
        if (eventsCount) {
            listData = Array(eventsCount).map(() => {
                return { type: 'success', content: 'event' };
            });
        }
        return listData;
    };

    const getMonthData = (value: Dayjs) => {
        let listData: any[] = [];

        const valueDate = value.toDate();
        const valueStartOfMonth = startOfMonth(valueDate);

        const eventsCount = viewModel.calendarEvents[format(valueStartOfMonth, 'yyyy-MM-dd')];
        if (eventsCount) {
            listData = Array(eventsCount).map(() => {
                return { type: 'success', content: 'event' };
            });
        }
        return listData;
    };

    const onCalendarPanelChange = (date: any, mode: CalendarMode) => {
        viewModel.onChangeDate(date.toDate(), mode);
    };

    const onSelectCalendarCell = (date: any) => {
        setIsEventsListVisible(true);
        switch (viewModel.calendarMode) {
            case 'month':
                setEventsListTitle(date.format('D MMM YYYY'));
                break;
            case 'year':
                setEventsListTitle(date.format('MMMM YYYY'));
                break;
        }
        viewModel.onClickDate(date.toDate());
    };

    const onClickShowEventsByDate = () => {
        const params = createSearchParams({
            range_start: dayjs(viewModel.getRangeStart(viewModel.selectedDate, viewModel.calendarMode, 'list')).format(
                EventsFilterParamConfig.dayjs_date_format
            ),
            range_end: dayjs(viewModel.getRangeEnd(viewModel.selectedDate, viewModel.calendarMode, 'list')).format(
                EventsFilterParamConfig.dayjs_date_format
            )
        }).toString();
        window.open('/events-manage?' + params, '_blank', 'noreferrer');
    };

    const opApiGridStyle: React.CSSProperties = {
        width: '50%',
        textAlign: 'center',
        boxShadow: '0 0 0 0'
    };

    return (
        <div>
            <Typography.Title>{t('reports-api.index.title')}</Typography.Title>
            <Row gutter={[16, 16]}>
                {viewModel.operatorsApi.map((api) => (
                    <Col span={6} key={`operator_api_${api.id}`}>
                        <Card
                            title={
                                <Row align="middle" justify="space-between">
                                    <Col>
                                        <img alt="operator_logo" src={api.color_logo_url} height={28} />
                                    </Col>
                                    <Col>{api.name}</Col>
                                </Row>
                            }
                            loading={viewModel.operatorsApiLoading}
                            onClick={() => window.open(`/reports-api-manage/edit/${api.id || ''}`, '_blank', 'noreferrer')}
                            style={{ cursor: 'pointer', height: '100%' }}
                        >
                            <Card.Grid style={opApiGridStyle} hoverable={false}>
                                <Row justify="space-around">
                                    <Tooltip title={t('reports-api.index.last_update')}>
                                        <Row align="middle">
                                            {window.innerWidth > 1660 && (
                                                <Col>
                                                    <Button icon={<ClockCircleOutlined />} size="small" type="text" />
                                                </Col>
                                            )}
                                            <Col>
                                                {dayjs(api.last_update).isValid() && (
                                                    <Typography.Text strong>{dayjs(api.last_update).format('DD.MM.YY HH:mm')}</Typography.Text>
                                                )}
                                                {!dayjs(api.last_update).isValid() && <Typography.Text strong>-</Typography.Text>}
                                            </Col>
                                        </Row>
                                    </Tooltip>
                                </Row>
                            </Card.Grid>
                            <Card.Grid style={opApiGridStyle} hoverable={false}>
                                <Row justify="space-around">
                                    <Tooltip title={t('reports-api.index.has_new_data')}>
                                        <Row align="middle">
                                            {window.innerWidth > 1660 && (
                                                <Col>
                                                    <Button icon={<ThunderboltOutlined />} size="small" type="text" />
                                                </Col>
                                            )}
                                            <Col>
                                                {api.has_new_data ? (
                                                    <Badge status="processing" color="primary" text={t('common.yes')} />
                                                ) : (
                                                    <Badge status="error" text={`${t('common.no')}`} />
                                                )}
                                            </Col>
                                        </Row>
                                    </Tooltip>
                                </Row>
                            </Card.Grid>
                        </Card>
                    </Col>
                ))}
            </Row>

            <Typography.Title>{t('events_dashboard.title')}</Typography.Title>
            <Divider />
            {contextHolder}
            <div className="edit-content-view">
                <Spin spinning={viewModel.isCalendarLoading}>
                    <Calendar
                        cellRender={(date: any, info: any) => {
                            const data = info.type === 'month' ? getMonthData(date) : geDayData(date);
                            return data.length > 0 ? <Badge status="success" text={data.length} /> : <ul className="events"></ul>;
                        }}
                        onPanelChange={onCalendarPanelChange}
                        onSelect={onSelectCalendarCell}
                    />
                </Spin>
                <Drawer
                    title={eventsListTitle}
                    width={500}
                    placement="right"
                    closable={false}
                    onClose={() => setIsEventsListVisible(false)}
                    open={isEventsListVisible}
                    extra={
                        <Space>
                            <Button type="primary" onClick={() => onClickShowEventsByDate()}>
                                {t('events_dashboard.list.go_edit')}
                            </Button>
                            <Button
                                style={{ padding: 0, paddingTop: 5 }}
                                type="dashed"
                                icon={<CloseOutlined />}
                                onClick={() => setIsEventsListVisible(false)}
                            />
                        </Space>
                    }
                >
                    <div>
                        <Spin spinning={viewModel.isListLoading}>
                            <List
                                grid={{ gutter: 16, column: 1 }}
                                itemLayout="horizontal"
                                dataSource={viewModel.listEvents}
                                renderItem={(item: EventData) => (
                                    <List.Item actions={[]}>
                                        <ListCard event={item} />
                                    </List.Item>
                                )}
                            />
                        </Spin>
                    </div>
                </Drawer>
            </div>
            <br />
        </div>
    );
});

export default EventsDashboardView;
