import { NoticeTemplateData, NoticeTemplateModel } from '@models/notice-template-model';
import { APIUtilError } from '@utils/api';
import { makeAutoObservable, runInAction } from 'mobx';

export interface NoticeTemplateEditViewModelStartParams {
    t: any;
    templateId: string | undefined;
    showError: (title: string, description: string) => void;
    showSuccess: (title: string, description: string) => void;
    close: () => void;
}

export class NoticeTemplateEditViewModel {
    private noticeTemplateModel = new NoticeTemplateModel();
    public isLoading: boolean = false;
    public isPublished = false;
    public template: NoticeTemplateData = new NoticeTemplateData();

    constructor(public props: NoticeTemplateEditViewModelStartParams) {
        if (this.props.templateId && this.props.templateId.length > 0) this.isPublished = true;
        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        if (this.isPublished && this.props.templateId && this.props.templateId.length > 0) this.fetchTemplate(this.props.templateId);
    }

    private async fetchTemplate(templateId: string) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const template = await this.noticeTemplateModel.getTemplateById(templateId);
            runInAction(() => {
                this.template = template.data;
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
            if ((error as APIUtilError).response?.status === 404) {
                this.props.close();
            }
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public onSave = async (template: NoticeTemplateData) => {
        await this.save(template);
    };

    private save = async (template: NoticeTemplateData) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.noticeTemplateModel.update(template);
            this.props.showSuccess(this.props.t('common.saved'), '');
        } catch (error) {
            this.props.showError(this.props.t('common.error.save'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };
}
