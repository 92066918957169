import { APIInstance } from '@utils/api';
import { GetWebsiteMenuResponse, GetWebsiteMenusResponse, WebsiteMenuData } from './website-menu.contract';
import { ObjectLiteral } from '@utils/object.utils';

export class WebsiteMenuModel {
    public async get(): Promise<GetWebsiteMenusResponse> {
        try {
            const result = await APIInstance.get<GetWebsiteMenusResponse>('/dict/schema_menu');
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getById(id: string | number): Promise<GetWebsiteMenuResponse> {
        try {
            const result = await APIInstance.get<GetWebsiteMenuResponse>(`/dict/schema_menu/${id}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async updateMenuItems(menuId: string | number, items: ObjectLiteral) {
        try {
            const result = await APIInstance.put(`/cp-manager/schema_menu/${menuId}`, { items: items });
            return result.data;
        } catch (error) {
            throw error;
        }
    }
}
