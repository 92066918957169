import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Breadcrumb, Button, Col, Divider, Form, Input, Row, Typography, notification } from 'antd';
import { useViewModel } from '@models/model';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DictionaryViewModel } from './dictionary.view.model';
import { DictionaryTable } from './table';
import { ObjectLiteral } from '@utils/object.utils';
import EditData from './edit/dictionary.edit.view';
import { when } from 'mobx';
import { FileExcelOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';

const DictionaryView: React.FC = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { altname, id } = useParams();

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const viewModel = useViewModel(
        () =>
            new DictionaryViewModel({
                t,
                altname,
                id,
                showError: showErrorNotification
            })
    );

    useEffect(() => {
        when(
            () => viewModel.rawData.length > 0 && viewModel.primaryKey.length > 0 && id !== undefined,
            () => {
                viewModel.setOnEdit(id!);
            }
        );
    }, []);

    const onEdit = (id: string | number) => {
        navigate(`edit/${id}`);
        viewModel.setOnEdit(id);
    };

    const onCloseConstructor = () => {
        navigate(`/dictionaries-manage/${altname}`);
        viewModel.onCancelEdit();
    };

    const titleText = viewModel.dictionaryInfo?.name || t('dictionaries.title');

    return (
        <div style={{ marginTop: 10 }}>
            {contextHolder}
            <Breadcrumb items={[{ title: <a href="/dictionaries-manage">{t('dictionaries.title')}</a> }, { title: titleText }]} />
            <Typography.Title>{titleText}</Typography.Title>

            <Space direction="vertical">
                <div>
                    <Typography.Text strong>{t('dictionaries.content.altname')}: </Typography.Text>
                    <Typography.Text copyable>{viewModel.dictionaryInfo?.altname}</Typography.Text>
                </div>
                <div>
                    <Typography.Text strong>{t('dictionaries.content.public_api_link')}: </Typography.Text>
                    <Typography.Text copyable>{viewModel.dictionaryInfo?.public_api_link}</Typography.Text>
                </div>
            </Space>
            <Divider />
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <Row gutter={12} justify="end" align="middle">
                    <Col>
                        <Button type="dashed" icon={<FileExcelOutlined />} onClick={viewModel.onExportExcel}>
                            {t('dictionaries.excel.title')}
                        </Button>
                    </Col>
                    {viewModel.scheme?.is_creatable === true && (
                        <Col>
                            <Button type="primary" icon={<PlusOutlined />} onClick={viewModel.createTemplate}>
                                {t('common.create')}
                            </Button>
                        </Col>
                    )}
                </Row>

                <Input
                    prefix={<SearchOutlined />}
                    size="large"
                    allowClear
                    placeholder={t('dictionaries.search.placeholder') || ''}
                    value={viewModel.searchValue}
                    onChange={(event) => (viewModel.searchValue = event.currentTarget.value)}
                />

                <DictionaryTable
                    loading={viewModel.loading}
                    rows={viewModel.rawData}
                    total={viewModel.dataTotal}
                    primaryKey={viewModel.primaryKey}
                    columns={viewModel.visibleColumns}
                    currentPage={viewModel.currentPage}
                    pageSize={viewModel.pageSize}
                    onChangePagination={viewModel.onChangePagination}
                    onEdit={onEdit}
                    onDelete={viewModel.onDelete}
                />
            </Space>
            {viewModel.dataOnEdit && (
                <EditData
                    visible={!!viewModel.dataOnEdit}
                    data={viewModel.dataOnEdit}
                    primaryKey={viewModel.primaryKey}
                    schemeColumns={viewModel.scheme?.columns || []}
                    altname={altname || ''}
                    onClose={onCloseConstructor}
                    onCreate={(data: ObjectLiteral) => viewModel.onCreate(data)}
                    onUpdate={(id: string, data: ObjectLiteral) => viewModel.onUpdate(id, data)}
                />
            )}
        </div>
    );
});

export default DictionaryView;
