import { APIInstance } from '@utils/api';
import { plainToInstance } from 'class-transformer';
import { GetIndicatorsResponse, Indicator, IndicatorFormulaPart, IndicatorResponse } from './indicator.contract';

export interface GetIndicatorParams {}

export class IndicatorModel {
    public async get(params: GetIndicatorParams): Promise<GetIndicatorsResponse> {
        try {
            const result = await APIInstance.get<GetIndicatorsResponse>('/cp-manager/indicator');
            let instancedData = result.data;
            instancedData.data = instancedData.data.map((item) => {
                let indicator = plainToInstance(Indicator, item);
                indicator.indicator_formula = plainToInstance(IndicatorFormulaPart, indicator.indicator_formula);
                return indicator;
            });
            return instancedData;
        } catch (error) {
            throw error;
        }
    }

    public async getIndicatorById(id: string): Promise<IndicatorResponse> {
        try {
            const result = await APIInstance.get<IndicatorResponse>(`/cp-manager/indicator/${id}`);
            let instancedData: IndicatorResponse = new IndicatorResponse();
            instancedData.data = plainToInstance(Indicator, result.data.data);
            instancedData.data.indicator_formula = plainToInstance(IndicatorFormulaPart, instancedData.data.indicator_formula);

            return instancedData;
        } catch (error) {
            throw error;
        }
    }

    public async create(indicator: Indicator): Promise<IndicatorResponse> {
        try {
            const result = await APIInstance.post<IndicatorResponse>('/cp-manager/indicator', { ...indicator });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async update(indicator: Indicator): Promise<IndicatorResponse> {
        try {
            const result = await APIInstance.patch<IndicatorResponse>(`/cp-manager/indicator/${indicator.id}`, { ...indicator });
            return result.data;
        } catch (error) {
            throw error;
        }
    }
}
