import { Divider, Form, Input, Typography, notification, Row, Col, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { ObjectLiteral } from '@utils/object.utils';
import { ReportsApiViewModel } from './reports-api.view.model';
import { ContentTable } from './table';
import { ReportApiData } from '@models/report-api';
import { useViewModel } from '@models/model';

const ReportsApiView: React.FC = observer(() => {
    const { t } = useTranslation();
    const [search, setSearch] = useSearchParams();
    const setSearchParams = (params: any) => setSearch(params);

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const viewModel = useViewModel(() => new ReportsApiViewModel({ t, searchParams: search, setSearchParams, showError: showErrorNotification }));
    const [filterForm] = Form.useForm();

    useEffect(() => {
        updateIfNeeded();
    }, [search]);

    const updateIfNeeded = async () => {
        const isResetNeeded = await viewModel.updateSearchParamsIfNeeded(search);
        if (isResetNeeded) {
            filterForm.resetFields();
        }
    };

    const onEdit = (reportId?: number | undefined) => {
        if (!reportId) return;
        window.open(`/reports-api-manage/edit/${reportId || ''}`, '_blank', 'noreferrer');
    };

    const onFilterValueChange = () => {
        const valFromFields: ObjectLiteral = filterForm.getFieldsValue();
        viewModel.onFilterValueChange(valFromFields);
    };

    return (
        <div>
            {contextHolder}

            <Row gutter={12} align="middle" justify="space-between">
                <Col>
                    <Typography.Title>{t('reports-api.title')}</Typography.Title>
                </Col>
            </Row>

            <Divider style={{ marginTop: 0 }} />

            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <Form form={filterForm} initialValues={viewModel.currentFilter} layout="vertical" onValuesChange={onFilterValueChange}>
                    <Form.Item style={{ margin: 0 }} name={'search'}>
                        <Input prefix={<SearchOutlined />} size="large" allowClear placeholder={t('reports.filter.search.placeholder') || ''} />
                    </Form.Item>
                </Form>

                <ContentTable
                    currentPage={viewModel.currentPage}
                    loading={viewModel.isLoading}
                    rows={viewModel.reports}
                    total={viewModel.reportsTotal}
                    pageSize={viewModel.pageSize}
                    onChangePagination={viewModel.onChangePagination}
                    onEdit={(report: ReportApiData) => onEdit(report.id)}
                />
            </Space>
        </div>
    );
});

export default ReportsApiView;
