import { APIUtilError } from '@utils/api';
import { makeAutoObservable, runInAction } from 'mobx';
import { StatementsModel, StatementData, StatementFilterData, StatementFilterItem, StatementDictItem } from '@models/statements-model';

export class FiltersEditViewModel {
    private statementModel = new StatementsModel();
    public loading: boolean = false;
    public statement: StatementData = new StatementData();

    public loadingFilters: boolean = false;
    public filter: StatementFilterData = new StatementFilterData();
    public filters: StatementFilterData[] = [];
    public loadingStatementFilterItems: boolean = false;
    public StatementFilterItems: StatementFilterItem[] = [];
    public StatementFilterItemOnEdit?: StatementFilterItem;
    public dicts: StatementDictItem[] = [];

    public loadingData: boolean = false;
    public statementData: any[] = [];
    public statementDataTotal: number = 0;

    constructor(
        public statementId: string | undefined,
        public filterId: string | undefined,
        private showError: (description: string) => void,
        private showSuccess: (description: string) => void,
        private onFinish: () => void
    ) {
        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        if (this.statementId && this.statementId.length > 0 && this.filterId && this.filterId.length > 0) {
            this.fetchFilters(this.statementId, this.filterId);
            this.fetchStatementFilterItems(this.statementId, this.filterId);
            this.fetchStatement(this.statementId);
        }
        if (this.statementId && this.statementId.length > 0) {
            this.fetchStatement(this.statementId);
        }
        this.fetchDicts();
    }

    private async fetchStatement(statementId: string) {
        runInAction(() => {
            this.loading = true;
        });
        try {
            const statement = await this.statementModel.getStatementById(statementId);
            runInAction(() => {
                this.statement = statement.data;
            });
        } catch (error) {
            this.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    private async fetchFilters(statementId: string, filterId: string) {
        runInAction(() => {
            this.loadingFilters = true;
        });
        try {
            const filters = await this.statementModel.getFilters(statementId);
            runInAction(() => {
                this.filters = filters.data;
                this.filter = this.filters.find((i) => i.id === parseInt(filterId)) || new StatementFilterData();
            });
        } catch (error) {
            this.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loadingFilters = false;
            });
        }
    }

    public async editFilter(item: StatementFilterData) {
        runInAction(() => {
            this.loadingFilters = true;
        });
        try {
            const res = await this.statementModel.editFilter(this.statementId || '', this.filterId || '', item);
            if (res) this.showSuccess('');
            this.filter = res.data;
        } catch (error) {
            this.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loadingFilters = false;
            });
        }
    }

    public async createFilter(item: StatementFilterData) {
        runInAction(() => {
            this.loadingFilters = true;
        });
        try {
            const res = await this.statementModel.createFilter(this.statementId || '', item);
            if (res) this.showSuccess('');
            this.filter = res.data;
            return res.data;
        } catch (error) {
            this.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loadingFilters = false;
            });
        }
    }

    private async fetchStatementFilterItems(statementId: string, filterId: string | number) {
        runInAction(() => {
            this.loadingStatementFilterItems = true;
        });
        try {
            const res = await this.statementModel.getStatementFilterItems(statementId, filterId);
            runInAction(() => {
                this.StatementFilterItems = res.data;
            });
        } catch (error) {
            this.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loadingStatementFilterItems = false;
            });
        }
    }

    public onEditItem = (id: number) => {
        // get item for edit
        this.StatementFilterItemOnEdit = this.StatementFilterItems.find((i) => i.id === id);
    };

    public async onDeleteItem(id: number) {
        runInAction(() => {
            this.loadingStatementFilterItems = true;
        });
        try {
            await this.statementModel.deleteStatementFilterItem(this.statementId || '', this.filterId || '', id);
            let copy = [...this.StatementFilterItems];
            const index = copy.map((item) => item.id).indexOf(id);
            copy.splice(index, 1);
            this.StatementFilterItems = copy;
        } catch (error) {
            this.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loadingStatementFilterItems = false;
            });
        }
    }

    public async editStatementFilterItem(item: StatementFilterItem) {
        runInAction(() => {
            this.loadingStatementFilterItems = true;
        });
        try {
            await this.statementModel.editStatementFilterItems(this.statementId || '', this.filterId || '', item);
            let copy = [...this.StatementFilterItems];
            const index = copy.map((item) => item.id).indexOf(item.id);
            copy[index] = item;
            this.StatementFilterItems = copy;
        } catch (error) {
            this.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loadingStatementFilterItems = false;
            });
        }
    }

    public async createStatementFilterItem(item: StatementFilterItem) {
        runInAction(() => {
            this.loadingStatementFilterItems = true;
        });
        try {
            await this.statementModel.createStatementFilterItems(this.statementId || '0', this.filterId || '0', item);
            await this.fetchStatementFilterItems(this.statementId || '', this.filterId || '');
        } catch (error) {
            this.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loadingStatementFilterItems = false;
            });
        }
    }

    public onCancelEdit = () => {
        runInAction(() => {
            this.StatementFilterItemOnEdit = undefined;
        });
    };

    public onCreateStatementFilterItem = () => {
        runInAction(() => {
            this.StatementFilterItemOnEdit = new StatementFilterItem();
        });
    };

    private async fetchDicts() {
        try {
            const res = await this.statementModel.getDicts();
            runInAction(() => {
                this.dicts = res.data;
            });
        } catch (error) {
            this.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loadingFilters = false;
            });
        }
    }
}
