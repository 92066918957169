import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Button, Col, Divider, Form, Input, Row, Skeleton, Tag, Typography, notification } from 'antd';
import { EditableTable } from './table';
import { useViewModel } from '@models/model';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { MembersViewModel } from './members.view.model';
import EditMember from './member.edit.view';
import { MemberData } from '@models/member-model';
import { FileExcelOutlined, FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { ObjectLiteral } from '@utils/object.utils';

const MembersView: React.FC = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [search, setSearch] = useSearchParams();
    const setSearchParams = (params: any) => setSearch(params);
    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const viewModel = useViewModel(() => new MembersViewModel({ t, searchParams: search, setSearchParams, showError: showErrorNotification }));
    const [filterForm] = Form.useForm();

    useEffect(() => {
        updateIfNeeded();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const updateIfNeeded = async () => {
        const isResetNeeded = await viewModel.updateSearchParamsIfNeeded(search);
        if (isResetNeeded) {
            filterForm.resetFields();
        }
    };

    const onShowUser = (userId?: string | undefined) => {
        if (!userId) return;
        navigate({
            pathname: `/users-manage/edit/${userId}`
        });
    };

    const onShowEvents = (memberId: number) => {
        navigate({
            pathname: '/events-manage',
            search: createSearchParams({
                member_id: `${memberId}`
            }).toString()
        });
    };

    const onShowStartups = (memberId: number) => {
        navigate({
            pathname: '/startups-manage',
            search: createSearchParams({
                member_id: `${memberId}`
            }).toString()
        });
    };

    const onFilterValueChange = () => {
        const valFromFields: ObjectLiteral = filterForm.getFieldsValue();
        viewModel.onFilterValueChange(valFromFields);
    };

    const filters = () => {
        const infoCount = viewModel.filterInfoCount(viewModel.currentFilter);
        const infoFake = Array(infoCount).fill(0);
        if (infoCount > 0) {
            return (
                <Space style={{ width: '100%', marginTop: '10px' }} direction="horizontal" size="small">
                    {viewModel.isCurrentFilterLoading === true
                        ? infoFake.map((_, index) => <Skeleton.Input key={`skeleton-${index}`} active={true} size="small" />)
                        : viewModel.currentFilterInfo.map((infoItem, index) => (
                              <Tag
                                  key={`tag-${index}`}
                                  closable
                                  onClose={() => {
                                      viewModel.onRemoveFilterInfo(infoItem);
                                  }}
                                  color="purple"
                              >
                                  <Space>
                                      <FilterOutlined />
                                      <Typography.Text strong>
                                          {infoItem.name}: {infoItem.value}
                                      </Typography.Text>
                                  </Space>
                              </Tag>
                          ))}
                </Space>
            );
        } else {
            return <div />;
        }
    };

    return (
        <div>
            {contextHolder}
            <Row gutter={12} align="middle" justify="space-between">
                <Col>
                    <Space>
                        <Typography.Title>{t('members.title')}</Typography.Title>
                        <Typography.Title style={{ marginTop: 25 }} level={2} type="secondary">
                            {viewModel.membersTotal}
                        </Typography.Title>
                    </Space>
                </Col>
                <Col>
                    <Space>
                        <Button
                            disabled={viewModel.members.length === 0}
                            type="dashed"
                            icon={<FileExcelOutlined />}
                            onClick={viewModel.onExportExcel}
                        >
                            {t('members.export.excel.title')}
                        </Button>
                    </Space>
                </Col>
            </Row>

            <Divider style={{ marginTop: 0, marginBottom: 0 }} />
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                {filters()}

                <Form form={filterForm} initialValues={viewModel.currentFilter} layout="vertical" onValuesChange={onFilterValueChange}>
                    <Form.Item style={{ margin: 0 }} name={'search'}>
                        <Input prefix={<SearchOutlined />} size="large" allowClear placeholder={t('members.filter.search.placeholder') || ''} />
                    </Form.Item>
                </Form>

                <EditableTable
                    currentPage={viewModel.currentPage}
                    loading={viewModel.isLoading}
                    rows={viewModel.members}
                    total={viewModel.membersTotal}
                    pageSize={viewModel.pageSize}
                    onChangePagination={viewModel.onChangePagination}
                    onEdit={(id: number) => viewModel.onEdit(id)}
                    onShowUser={onShowUser}
                    onShowEvents={onShowEvents}
                    onShowStartups={onShowStartups}
                />
            </Space>
            {viewModel.memberOnEdit && (
                <EditMember
                    visible={!!viewModel.memberOnEdit}
                    member={viewModel.memberOnEdit}
                    onClose={() => viewModel.onCancelEdit()}
                    onSave={(member: MemberData) => viewModel.update(member)}
                />
            )}
        </div>
    );
});

export default MembersView;
