import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Button, Col, DatePicker, Divider, Form, Input, Row, Select, Switch, Typography, notification } from 'antd';
import { EditableTable } from './table';
import { useViewModel } from '@models/model';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { EventsViewModel } from './events.view.model';
import { EventData } from '@models/event-model';
import { FileExcelOutlined, FilterOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { ObjectLiteral } from '@utils/object.utils';

const EventsView: React.FC = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [search, setSearch] = useSearchParams();
    const setSearchParams = (params: any) => setSearch(params);

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    let viewModel = useViewModel(() => new EventsViewModel({ t, searchParams: search, setSearchParams, showError: showErrorNotification }));
    const [filterForm] = Form.useForm();

    useEffect(() => {
        updateIfNeeded();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const updateIfNeeded = async () => {
        const isResetNeeded = await viewModel.updateSearchParamsIfNeeded(search);
        if (isResetNeeded) {
            filterForm.resetFields();
        }
    };

    const onCreate = () => {
        navigate('create');
    };

    const onEdit = (eventId?: number | undefined) => {
        if (!eventId) return;
        window.open(`/events-manage/edit/${eventId || ''}`, '_blank', 'noreferrer');
    };

    const onShowMembers = (event: EventData) => {
        if (!event.id) return;
        navigate({
            pathname: '/members-manage',
            search: createSearchParams({
                event_id: `${event.id}`
            }).toString()
        });
    };

    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
        { label: t('events.filter.range_picker.presets.today'), value: [dayjs(), dayjs()] },
        { label: t('events.filter.range_picker.presets.this_week'), value: [dayjs(new Date()).startOf('week'), dayjs(new Date()).endOf('week')] },
        { label: t('events.filter.range_picker.presets.this_month'), value: [dayjs(new Date()).startOf('month'), dayjs(new Date()).endOf('month')] },
        { label: t('events.filter.range_picker.presets.this_year'), value: [dayjs(new Date()).startOf('year'), dayjs(new Date()).endOf('year')] }
    ];

    const getRegionSelectorContent = () =>
        viewModel.regionDictionary.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        });
    const getResultTypeSelectorItems = () =>
        viewModel.resultTypeDictionary.map((item) => {
            return {
                value: item.id,
                label: item.title
            };
        });
    const getResultSelectorItems = () =>
        viewModel.resultsData.map((item) => {
            return {
                value: item.id,
                label: item.title
            };
        });
    const getMemberSelectorItems = () =>
        viewModel.membersData.map((item) => {
            return {
                value: item.id,
                label: `${item.last_name} ${item.first_name} ${item.middle_name}`
            };
        });

    const getOperatorSelectorItems = () =>
        viewModel.opearatorDictionary.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        });

    const getEventTypeSelectorItems = () =>
        viewModel.eventTypesDictionary.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        });

    const onFilterValueChange = () => {
        const valFromFields: ObjectLiteral = filterForm.getFieldsValue();
        viewModel.onFilterValueChange(valFromFields);
    };

    type FilterMode = 'full' | 'result' | 'member';
    const getFilterMode = (): FilterMode => {
        if (viewModel.currentFilter.result_id !== undefined && viewModel.currentFilter.result_id.length > 0) {
            return 'result';
        } else if (viewModel.currentFilter.member_id !== undefined && viewModel.currentFilter.member_id.length > 0) {
            return 'member';
        } else {
            return 'full';
        }
    };

    const filters = () => {
        return (
            <div className="edit-content-view">
                <Space style={{ width: '100%' }} direction="vertical" size="large">
                    <Space direction="horizontal" align="center" size="small">
                        <FilterOutlined />
                        <Typography.Title level={4} style={{ margin: 0 }}>
                            {t('events.filter.title')}
                        </Typography.Title>
                    </Space>
                    <Form form={filterForm} initialValues={viewModel.currentFilter} layout="vertical" onValuesChange={onFilterValueChange}>
                        <Form.Item name={'date_ranges'} label={t('events.filter.range_picker.title')} tooltip={t('events.filter.range_picker.hint')}>
                            <DatePicker.RangePicker style={{ width: '100%' }} allowClear presets={rangePresets} format="DD.MM.YYYY" />
                        </Form.Item>

                        {getFilterMode() === 'result' && (
                            <Form.Item name={'result_id'} label={t('events.filter.result')}>
                                <Select
                                    mode="multiple"
                                    options={getResultSelectorItems()}
                                    showSearch
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    allowClear
                                    placeholder={t('events.filter.not_selected')}
                                />
                            </Form.Item>
                        )}

                        {getFilterMode() === 'member' && (
                            <Form.Item name={'member_id'} label={t('events.filter.member')}>
                                <Select
                                    mode="multiple"
                                    options={getMemberSelectorItems()}
                                    showSearch
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    allowClear
                                    placeholder={t('events.filter.not_selected')}
                                />
                            </Form.Item>
                        )}

                        {getFilterMode() === 'full' && (
                            <div>
                                <Form.Item name={'event_type_id'} label={t('events.content.event_type')}>
                                    <Select
                                        mode="multiple"
                                        options={getEventTypeSelectorItems()}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        allowClear
                                        placeholder={t('events.filter.not_selected')}
                                    />
                                </Form.Item>
                                <Form.Item name={'result_type_id'} label={t('events.content.result_type.title')}>
                                    <Select
                                        mode="multiple"
                                        options={getResultTypeSelectorItems()}
                                        showSearch
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        allowClear
                                        placeholder={t('events.filter.not_selected')}
                                    />
                                </Form.Item>
                                <Form.Item name={'operator_id'} label={t('events.content.operator.title')}>
                                    <Select
                                        mode="multiple"
                                        options={getOperatorSelectorItems()}
                                        showSearch
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        allowClear
                                        placeholder={t('events.filter.not_selected')}
                                    />
                                </Form.Item>
                                <Form.Item name={'region_id'} label={t('events.content.region.title')}>
                                    <Select
                                        mode="multiple"
                                        options={getRegionSelectorContent()}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        allowClear
                                        placeholder={t('events.filter.not_selected')}
                                    />
                                </Form.Item>
                                <Form.Item name={'top_50'} label={t('events.content.top_50.title')} valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                            </div>
                        )}
                    </Form>
                </Space>
            </div>
        );
    };

    return (
        <div>
            {contextHolder}
            <Row gutter={12} align="middle" justify="space-between">
                <Col>
                    <Space>
                        <Typography.Title>{t('events.title')}</Typography.Title>
                        <Typography.Title style={{ marginTop: 25 }} level={2} type="secondary">
                            {viewModel.eventsTotal}
                        </Typography.Title>
                    </Space>
                </Col>
                <Col>
                    <Space>
                        <Button disabled={viewModel.events.length === 0} type="dashed" icon={<FileExcelOutlined />} onClick={viewModel.onExportExcel}>
                            {t('events.export.excel.title')}
                        </Button>
                        <Button type="primary" icon={<PlusCircleOutlined />} onClick={onCreate}>
                            {t('common.create')}
                        </Button>
                    </Space>
                </Col>
            </Row>
            <Divider style={{ marginTop: 0 }} />
            <Row gutter={24}>
                <Col span={4}>{filters()}</Col>
                <Col span={20}>
                    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <Form form={filterForm} initialValues={viewModel.currentFilter} layout="vertical" onValuesChange={onFilterValueChange}>
                            <Form.Item style={{ margin: 0 }} name={'search'}>
                                <Input
                                    prefix={<SearchOutlined />}
                                    size="large"
                                    allowClear
                                    placeholder={t('events.filter.search.placeholder') || ''}
                                />
                            </Form.Item>
                        </Form>

                        <EditableTable
                            currentPage={viewModel.currentPage}
                            loading={viewModel.isLoading}
                            rows={viewModel.events}
                            total={viewModel.eventsTotal}
                            pageSize={viewModel.pageSize}
                            onChangePagination={viewModel.onChangePagination}
                            onEdit={(event: EventData) => onEdit(event.id)}
                            onDelete={(event: EventData) => (event.id ? viewModel.delete(event.id) : {})}
                            onShowMembers={onShowMembers}
                        />
                    </Space>
                </Col>
            </Row>
        </div>
    );
});

export default EventsView;
