import { useState } from 'react';
import { Table, Form, Space, Tooltip, Button, Modal } from 'antd';
import { useEffect } from 'react';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { StatementFilterItem } from '@models/statements-model';

interface TableProps {
    rows: StatementFilterItem[];
    loading: boolean;
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
}

export const EditableTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [data, setData] = useState<StatementFilterItem[]>([]);
    const [modal, contextHolder] = Modal.useModal();

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const confirmDelete = (item: StatementFilterItem) => {
        modal.confirm({
            title: t('common.confirmation_required'),
            icon: <ExclamationCircleOutlined />,
            content: t('statements.filters.items.delete'),
            okText: t('common.delete'),
            cancelText: t('common.cancel'),
            onOk: () => (item.id ? props.onDelete(item.id) : '')
        });
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: t('statements.filters.items.title'),
            dataIndex: 'title'
        },
        {
            title: t('statements.filters.items.value'),
            dataIndex: 'value'
        },
        {
            title: t('statements.filters.items.sort_id'),
            dataIndex: 'sort_id'
        },
        {
            width: '10%',
            dataIndex: 'operations',
            render: (_: any, record: StatementFilterItem) => {
                return (
                    <Space direction="horizontal" size="small">
                        <Tooltip title={t('common.edit')}>
                            <Button onClick={() => (record.id ? props.onEdit(record.id) : '')}>
                                <EditOutlined />
                            </Button>
                        </Tooltip>
                        <Tooltip title={t('common.delete')}>
                            <Button onClick={() => confirmDelete(record)}>
                                <DeleteOutlined />
                            </Button>
                        </Tooltip>
                    </Space>
                );
            }
        }
    ];

    return (
        <Form form={form} component={false}>
            <Table
                size="small"
                loading={props.loading}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="row"
                rowKey={'id'}
                pagination={false}
            />
            {contextHolder}
        </Form>
    );
};
