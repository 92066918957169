import React from 'react';
import { Button, Divider, Form, Input, Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import '@assets/css/App.css';
import 'katex/dist/katex.min.css';
import { IndicatorFormulaPart, indicatorFormulaPartValues } from '@models/indicator-model';
import { DashOutlined, DeleteOutlined } from '@ant-design/icons';
import { plainToInstance } from 'class-transformer';
import { ResultTypeDictionaryData } from '@models/dictionary-model';

interface Props {
    part: IndicatorFormulaPart;
    resultTypes: ResultTypeDictionaryData[];
    onEdit: (part: IndicatorFormulaPart) => void;
    onDelete: (part: IndicatorFormulaPart) => void;
}

const PartView: React.FC<Props> = ({ part, resultTypes, onEdit, onDelete }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm<IndicatorFormulaPart>();

    const type = Form.useWatch('type', form);

    const getValueTitle = () => {
        return type === indicatorFormulaPartValues[0]
            ? t('indicator.content.indicator_formula.value.value.title')
            : t('indicator.content.indicator_formula.value.symbol.title');
    };

    const getValuePlaceholder = () => {
        return type === indicatorFormulaPartValues[0]
            ? t('indicator.content.indicator_formula.value.value.placeholder')
            : t('indicator.content.indicator_formula.value.symbol.placeholder');
    };

    const onChangeForm = () => {
        form.submit();
    };

    const onSuccessFormValidation = async () => {
        const data = form.getFieldsValue();
        let converted: IndicatorFormulaPart = plainToInstance(IndicatorFormulaPart, { ...part, ...data });
        onEdit(converted);
    };

    const getResultTypeSelectorItems = () => {
        let resultTypeTags: Array<{ value: string; label: string }> = resultTypes.map((item) => {
            return {
                value: item.tag,
                label: `${item.alt_title} (${item.tag})`
            };
        });
        resultTypeTags.push({ value: 'sum', label: 'Сумма (sum)' });
        return resultTypeTags;
    };
    const partTypes = [
        { value: indicatorFormulaPartValues[0], label: t('indicator.content.indicator_formula.type.value') },
        { value: indicatorFormulaPartValues[1], label: t('indicator.content.indicator_formula.type.symbol') }
    ];

    return (
        <div className="indicator-edit-formula-dnd-item">
            <Form form={form} initialValues={part} layout="vertical" onFinish={onSuccessFormValidation} onChange={onChangeForm}>
                <Form.Item name="type">
                    <Select options={partTypes} />
                </Form.Item>
                {type && type === indicatorFormulaPartValues[0] && (
                    <Form.Item name="tag">
                        <Select options={getResultTypeSelectorItems()} onChange={onChangeForm} />
                    </Form.Item>
                )}

                <Form.Item name="value" label={getValueTitle() || ''}>
                    <Input placeholder={getValuePlaceholder() || ''} />
                </Form.Item>
            </Form>

            <Divider />
            <Tooltip title={t('indicator.drag.hint')}>
                <DashOutlined />
            </Tooltip>
            <Tooltip title={t('common.delete')}>
                <Button style={{ padding: 0, paddingTop: 5 }} type="dashed" icon={<DeleteOutlined />} onClick={() => onDelete(part)} />
            </Tooltip>
        </div>
    );
};

export default PartView;
