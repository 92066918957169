export class ReportData {
    id: number;
    name: string;
    file_id: number;
    type_id: number;
    is_deleted_report: boolean;
    type_name: string;
    file_name: string;
    size: number;
    is_private: boolean;
    is_deleted_file: boolean;
    file_updated_at: string;
    url?: string;
    file_uuid?: string;
}

export class ReportUser {
    id: number;
    user_id: string;
    user_info: {
        first_name: string;
        last_name: string;
        middle_name: string;
        email: string;
    };
}

export class ReportAttachedFile {
    name: string;
    size: number;
    id: number;
    report_id: number;
    file_id: number;
    created_at: string;
    updated_at: string;
    is_deleted: boolean;
    user_id: string;
    type_id: number;
    url: string;
    status_id: number;
    status_name: string;
    user_info: {
        first_name: string;
        last_name: string;
        middle_name: string;
        email: string;
        error?: string;
    };
    api_data_storage_id?: number;
    status_desc?: string;
}

export interface ReportsGetFilter {
    search?: string | undefined;
    page?: number | undefined;
    page_size?: number | undefined;
}

export class GetReportsResponse {
    data: ReportData[];
    total: number;
    count: number;
}

export class ReportResponse {
    data: ReportData;
}

export class ReportGetUsersFilter {
    reportId: number;
    search?: string | undefined;
    page?: number | undefined;
    page_size?: number | undefined;
}

export class ReportUsersReponse {
    data: ReportUser[];
    total: number;
    count: number;
}

export class ReportGetFilesFilter {
    reportId: number;
    page?: number | undefined;
    page_size?: number | undefined;
}

export class GetReportAttachedFilesResponse {
    data: ReportAttachedFile[];
    total: number;
    count: number;
}

export class ReportType {
    id: number;
    name: string;
}

export class GetReportTypes {
    data: ReportType[];
    total: number;
    count: number;
}

export class UpdateReportData {
    name: string;
    type_id: number;
}
