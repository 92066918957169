import { DigitalFootprintUsersFilterParamName, DigitalFootprintUsersGetFilter } from '@interfaces/digital-footprint.interface';
import { DigitalFootprintUserData, DigitalFootprintUsersModel } from '@models/digital-footprint/users';
import { EventData, EventModel } from '@models/event-model';
import { MemberData, MemberModel } from '@models/member-model';
import { User, UserModel } from '@models/users-model';
import { APIUtilError } from '@utils/api';
import { ObjectLiteral, deleteUndefined, isObjectsHasDifferences } from '@utils/object.utils';
import { debounce } from 'lodash';
import { makeAutoObservable, runInAction, toJS } from 'mobx';

export interface FootprintUsersViewModelStartParams {
    t: any;
    searchParams: URLSearchParams;
    setSearchParams: (params: any) => void;
    showError: (title: string, description: string) => void;
}

export class FootprintUsersViewModel {
    private digitalFootprintUsersModel = new DigitalFootprintUsersModel();
    private eventModel = new EventModel();
    private memberModel = new MemberModel();
    private userModel = new UserModel();

    public isLoading: boolean = false;

    public currentPage: number = 1;
    public pageSize: number = 20;
    public availablePageSizes = [10, 20, 50, 100];
    public currentFilter: DigitalFootprintUsersGetFilter = {};

    public userFootprintsTotal: number = 0;
    public userFootprints: DigitalFootprintUserData[] = [];

    public isEventsLoading: boolean = false;
    public events: EventData[] = [];

    public isMembersLoading: boolean = false;
    public members: MemberData[] = [];

    public isUsersLoading: boolean = false;
    public users: User[] = [];

    constructor(private props: FootprintUsersViewModelStartParams) {
        const incomingFilter = this.getFilterFromSearchParams(props.searchParams);
        this.currentFilter = incomingFilter;
        this.currentPage = incomingFilter.page || 0;
        this.pageSize = incomingFilter.page_size || this.availablePageSizes[1];

        makeAutoObservable(this);
        this.wakeUpSir();
    }

    public async updateSearchParamsIfNeeded(searchParams: URLSearchParams): Promise<Boolean> {
        const newFilter = this.getFilterFromSearchParams(searchParams);
        if (isObjectsHasDifferences(newFilter, toJS(this.currentFilter))) {
            runInAction(() => {
                this.currentPage = newFilter.page || 1;
                this.pageSize = newFilter.page_size || this.availablePageSizes[1];
                this.currentFilter = newFilter;
            });
            await this.fetchUsers(newFilter);
            return true;
        }
        return false;
    }

    private async wakeUpSir() {
        await this.fetchUsers(this.currentFilter);
    }

    private getFilterFromSearchParams = (searchParams: URLSearchParams) => {
        let filter: DigitalFootprintUsersGetFilter = { page: 1, page_size: this.pageSize };
        const keys: DigitalFootprintUsersFilterParamName[] = ['event_id', 'member_id', 'user_id', 'page', 'page_size'];

        for (const key of keys) {
            const value = searchParams.get(key);
            if (!value) continue;
            switch (key) {
                case 'event_id':
                    try {
                        filter[key] = parseInt(value);
                    } catch {
                        filter[key] = undefined;
                    }
                    break;
                case 'member_id':
                    try {
                        filter[key] = parseInt(value);
                    } catch {
                        filter[key] = undefined;
                    }
                    break;
                case 'user_id':
                    filter[key] = value;
                    break;
                case 'page':
                    try {
                        const parsedPage = parseInt(value);
                        filter[key] = parsedPage;
                    } catch {
                        filter[key] = 1;
                    }
                    break;
                case 'page_size':
                    try {
                        const parsedSize = parseInt(value);
                        if (!this.availablePageSizes.includes(parsedSize)) throw new Error();
                        filter.page_size = parsedSize;
                    } catch {
                        filter.page_size = this.availablePageSizes[0];
                    }
                    break;

                default:
                    break;
            }
        }
        return filter;
    };

    private extractSearchParamsFromFilter = (filter: ObjectLiteral) => {
        let searchParams: ObjectLiteral = {};
        for (const key in filter) {
            switch (key) {
                case 'event_id':
                    searchParams.event_id = filter[key];
                    break;
                case 'member_id':
                    searchParams.member_id = filter[key];
                    break;
                case 'user_id':
                    searchParams.user_id = filter[key];
                    break;
                default:
                    break;
            }
        }
        return deleteUndefined(searchParams);
    };

    public onFilterValueChange = (values: ObjectLiteral) => {
        const formsValues = deleteUndefined(values);
        let filter: DigitalFootprintUsersGetFilter = { page: 1, page_size: this.pageSize };

        for (const key in formsValues) {
            switch (key) {
                case 'event_id':
                    filter.event_id = formsValues[key];
                    break;
                case 'member_id':
                    filter.member_id = formsValues[key];
                    break;
                case 'user_id':
                    filter.user_id = formsValues[key];
                    break;
                default:
                    break;
            }
        }
        this.currentPage = 1;
        this.currentFilter = deleteUndefined(filter);

        // const searchParams = this.extractSearchParamsFromFilter(deleteUndefined(filter));
        // this.props.setSearchParams(searchParams as any);
        this.debouncedFetch(deleteUndefined(filter));
    };

    private debouncedFetch = debounce(async (params: DigitalFootprintUsersGetFilter) => {
        this.fetchUsers(params);
    }, 400);

    private async fetchUsers(params: DigitalFootprintUsersGetFilter) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.digitalFootprintUsersModel.get(params);
            runInAction(() => {
                this.userFootprints = result.data;
                this.userFootprintsTotal = result.total || 0;
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public delete = async (footprintId: number) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.digitalFootprintUsersModel.deleteById(footprintId);
            const updated = this.userFootprints.filter((item) => item.id !== footprintId);
            this.userFootprints = updated;
        } catch (error) {
            this.props.showError(this.props.t('common.error.empty'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public onChangePagination = async (page: number, pageSize: number) => {
        if (pageSize !== this.pageSize) {
            runInAction(() => {
                this.pageSize = pageSize;
            });
        }
        const filter: DigitalFootprintUsersGetFilter = { ...this.currentFilter, page: page, page_size: pageSize };
        runInAction(() => {
            this.currentPage = page;
            this.currentFilter = filter;
        });

        const currentFiltersSearchParams = this.extractSearchParamsFromFilter(filter);
        this.props.setSearchParams(currentFiltersSearchParams);

        this.fetchUsers(filter);
    };

    public async searchEvents(query: string) {
        runInAction(() => {
            this.isEventsLoading = true;
        });
        try {
            const result = await this.eventModel.getEvents({ search: query, page_size: 10 });
            runInAction(() => {
                this.events = result.data as EventData[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isEventsLoading = false;
            });
        }
    }

    public async searchMembers(query: string) {
        runInAction(() => {
            this.isMembersLoading = true;
        });
        try {
            const result = await this.memberModel.getMembers({ search: query, page_size: 10 });
            runInAction(() => {
                this.members = result.data as MemberData[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isMembersLoading = false;
            });
        }
    }

    public async searchUsers(query: string) {
        runInAction(() => {
            this.isUsersLoading = true;
        });
        try {
            const result = await this.userModel.getUsers({ search: query, page_size: 10 });
            runInAction(() => {
                this.users = result.data as User[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isUsersLoading = false;
            });
        }
    }
}
