import { observer } from 'mobx-react-lite';
import { useViewModel } from '@models/model';
import { Button, Col, Divider, Form, Row, Space, Typography, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import ResourcesTable from './table';
import { ResourcesViewModel } from './resources.view.model';
import { PopularizationResourceData } from '@models/popularization/resource';

const PopularizationResourcesView: React.FC = observer(() => {
    const { t } = useTranslation();
    const [search, setSearch] = useSearchParams();
    const setSearchParams = (params: any) => setSearch(params);

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    let viewModel = useViewModel(() => new ResourcesViewModel({ t, searchParams: search, setSearchParams, showError: showErrorNotification }));
    const [filterForm] = Form.useForm();

    const onCreate = () => {
        window.open(`/popularization-resource/create`, '_blank', 'noreferrer');
    };

    const onEdit = (id: number) => {
        window.open(`/popularization-resource/edit/${id || ''}`, '_blank', 'noreferrer');
    };

    useEffect(() => {
        updateIfNeeded();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const updateIfNeeded = async () => {
        const isResetNeeded = await viewModel.updateSearchParamsIfNeeded(search);
        if (isResetNeeded) {
            filterForm.resetFields();
        }
    };

    return (
        <div>
            {contextHolder}
            <Row gutter={12} align="middle" justify="space-between">
                <Col>
                    <Space>
                        <Typography.Title>{t('popularization.resources.title')}</Typography.Title>
                        <Typography.Title style={{ marginTop: 25 }} level={2} type="secondary">
                            {viewModel.resourcesTotal}
                        </Typography.Title>
                    </Space>
                </Col>
                <Col>
                    <Button type="primary" icon={<PlusCircleOutlined />} onClick={onCreate}>
                        {t('common.create')}
                    </Button>
                </Col>
            </Row>
            <Divider style={{ marginTop: 0 }} />
            <Row gutter={[8, 8]} justify="center">
                <Col span={24}>
                    <ResourcesTable
                        currentPage={viewModel.currentPage}
                        loading={viewModel.isLoading}
                        rows={viewModel.resources}
                        total={viewModel.resourcesTotal}
                        pageSize={viewModel.pageSize}
                        onChangePagination={viewModel.onChangePagination}
                        onEdit={(data: PopularizationResourceData) => onEdit(data.id!)}
                        onDelete={(data: PopularizationResourceData) => viewModel.delete(data.id!)}
                    />
                </Col>
            </Row>
        </div>
    );
});

export default PopularizationResourcesView;
