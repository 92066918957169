import { DigitalFootprintUsersGetFilter } from '@interfaces/digital-footprint.interface';
import { GetDigitalFootprintUsersResponse } from './digital-footprint-users.contract';
import { deleteUndefined } from '@utils/object.utils';
import { APIInstance } from '@utils/api';

export class DigitalFootprintUsersModel {
    public async get(params: DigitalFootprintUsersGetFilter): Promise<GetDigitalFootprintUsersResponse> {
        try {
            const result = await APIInstance.get<GetDigitalFootprintUsersResponse>('/cp-manager/digital-footprint', {
                params: deleteUndefined({
                    event_id: params.event_id,
                    member_id: params.member_id,
                    user_id: params.user_id,
                    limit: params.page_size,
                    offset: params.page_size && params.page ? params.page_size * (params.page - 1) : 0
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async deleteById(id: number) {
        try {
            const result = await APIInstance.delete(`/cp-manager/digital-footprint/${id}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }
}
