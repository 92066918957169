import {
    DictionaryModel,
    EventTypeDictionaryData,
    OperatorDictionaryData,
    RegionDictionaryData,
    ResultTypeDictionaryData,
    TimezoneDictionaryData
} from '@models/dictionary-model';
import { EventData, EventModel } from '@models/event-model';
import { EventsFilterParamConfig, EventsFilterParamName, EventsGetFilter } from '@interfaces/events.interface';
import { ObjectLiteral, deleteUndefined, isObjectsHasDifferences } from '@utils/object.utils';
import dayjs, { Dayjs } from 'dayjs';
import { debounce } from 'lodash';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { ResultData, ResultModel } from '@models/result-model';
import { APIUtilError } from '@utils/api';
import { MemberData, MemberModel } from '@models/member-model';

export interface EventsViewModelStartParams {
    t: any;
    searchParams: URLSearchParams;
    setSearchParams: (params: any) => void;
    showError: (title: string, description: string) => void;
}

export class EventsViewModel {
    private dictionaryModel = new DictionaryModel();
    private eventModel = new EventModel();
    private resultModel = new ResultModel();
    private memberModel = new MemberModel();

    public currentPage: number = 1;
    public pageSize: number = 20;
    public availablePageSizes = [10, 20, 50, 100];
    public currentFilter: EventsGetFilter = {};
    public isLoading: boolean = false;
    public eventsTotal: number = 0;
    public events: EventData[] = [];

    public resultTypeDictionary: ResultTypeDictionaryData[] = [];
    public resultsData: ResultData[] = [];
    public membersData: MemberData[] = [];
    public regionDictionary: RegionDictionaryData[] = [];
    public opearatorDictionary: OperatorDictionaryData[] = [];
    public timezoneDictionary: TimezoneDictionaryData[] = [];
    public eventTypesDictionary: EventTypeDictionaryData[] = [];

    constructor(private startParams: EventsViewModelStartParams) {
        const incomingFilter = this.getFilterFromSearchParams(startParams.searchParams);
        this.currentFilter = incomingFilter;
        this.currentPage = incomingFilter.page || 0;
        this.pageSize = incomingFilter.page_size || this.availablePageSizes[1];

        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        await this.fetchResultTypeDictionary();
        if (this.currentFilter.result_id !== undefined && this.currentFilter.result_id.length > 0) {
            await this.fetchResults(this.currentFilter.result_id);
        }
        if (this.currentFilter.member_id !== undefined && this.currentFilter.member_id.length > 0) {
            await this.fetchMembers(this.currentFilter.member_id);
        }
        await this.fetchRegionDictionary();
        await this.fetchOperatorDictionary();
        await this.fetchTimezoneDictionary();
        await this.fetchEventTypesDictionary();
        await this.fetchEvents(this.currentFilter);
    }

    public async updateSearchParamsIfNeeded(searchParams: URLSearchParams): Promise<Boolean> {
        const newFilter = this.getFilterFromSearchParams(searchParams);
        if (isObjectsHasDifferences(newFilter, toJS(this.currentFilter))) {
            runInAction(() => {
                this.currentPage = newFilter.page || 1;
                this.pageSize = newFilter.page_size || this.availablePageSizes[1];
                this.currentFilter = newFilter;
            });
            await this.fetchEvents(newFilter);
            return true;
        }
        return false;
    }

    private getFilterFromSearchParams = (searchParams: URLSearchParams) => {
        let filter: EventsGetFilter = { page: 1, page_size: this.pageSize };
        const keys: EventsFilterParamName[] = [
            'search',
            'range_start',
            'range_end',
            'operator_id',
            'region_id',
            'result_type_id',
            'result_id',
            'member_id',
            'event_type_id',
            'top_50',
            'page',
            'page_size'
        ];

        for (const key of keys) {
            const value = searchParams.get(key);
            if (!value) continue;
            switch (key) {
                case 'search':
                    filter[key] = value.length > 0 ? value : undefined;
                    break;
                case 'range_start':
                    try {
                        const parsedDate = dayjs(value, EventsFilterParamConfig.dayjs_date_format);
                        if (!parsedDate.isValid()) throw new Error();
                        filter[key] = parsedDate.startOf('day'); //.toDate();
                    } catch {
                        filter[key] = undefined;
                    }
                    break;
                case 'range_end':
                    try {
                        const parsedDate = dayjs(value, EventsFilterParamConfig.dayjs_date_format);
                        if (!parsedDate.isValid()) throw new Error();
                        filter[key] = parsedDate.endOf('day'); //.toDate();
                    } catch {
                        filter[key] = undefined;
                    }
                    break;
                case 'result_type_id':
                    try {
                        const parsedIds = value.split(',').map((item) => parseInt(item));
                        filter[key] = parsedIds;
                    } catch {
                        filter[key] = undefined;
                    }
                    break;
                case 'result_id':
                    try {
                        const parsedIds = value.split(',').map((item) => parseInt(item));
                        filter[key] = parsedIds;
                    } catch {
                        filter[key] = undefined;
                    }
                    break;
                case 'region_id':
                    try {
                        const parsedIds = value.split(',').map((item) => parseInt(item));
                        filter[key] = parsedIds;
                    } catch {
                        filter[key] = undefined;
                    }
                    break;
                case 'operator_id':
                    try {
                        const parsedIds = value.split(',').map((item) => parseInt(item));
                        filter[key] = parsedIds;
                    } catch {
                        filter[key] = undefined;
                    }
                    break;
                case 'member_id':
                    try {
                        const parsedIds = value.split(',').map((item) => parseInt(item));
                        filter[key] = parsedIds;
                    } catch {
                        filter[key] = undefined;
                    }
                    break;
                case 'event_type_id':
                    try {
                        const parsedIds = value.split(',').map((item) => parseInt(item));
                        filter[key] = parsedIds;
                    } catch {
                        filter[key] = undefined;
                    }
                    break;
                case 'top_50':
                    filter[key] = value === 'true';
                    break;
                case 'page':
                    try {
                        const parsedPage = parseInt(value);
                        filter[key] = parsedPage;
                    } catch {
                        filter[key] = 1;
                    }
                    break;
                case 'page_size':
                    try {
                        const parsedSize = parseInt(value);
                        if (!this.availablePageSizes.includes(parsedSize)) throw new Error();
                        filter.page_size = parsedSize;
                    } catch {
                        filter.page_size = undefined;
                    }
                    break;

                default:
                    break;
            }
        }
        /// Добавляем это для Form
        if (filter.range_start && filter.range_end) filter.date_ranges = [filter.range_start as Dayjs, filter.range_end as Dayjs];
        return filter;
    };

    private extractSearchParamsFromFilter = (filter: ObjectLiteral) => {
        let searchParams: ObjectLiteral = {};
        for (const key in filter) {
            switch (key) {
                case 'search':
                    searchParams.search = filter[key];
                    break;
                case 'range_start':
                    searchParams.range_start = dayjs(filter[key]).startOf('day').format(EventsFilterParamConfig.dayjs_date_format);
                    break;
                case 'range_end':
                    searchParams.range_end = dayjs(filter[key]).endOf('day').format(EventsFilterParamConfig.dayjs_date_format);
                    break;
                case 'result_type_id':
                    searchParams.result_type_id = filter[key].join(',').length > 0 ? filter[key].join(',') : undefined;
                    break;
                case 'result_id':
                    searchParams.result_type_id = filter[key].join(',').length > 0 ? filter[key].join(',') : undefined;
                    break;
                case 'operator_id':
                    searchParams.operator_id = filter[key].join(',').length > 0 ? filter[key].join(',') : undefined;
                    break;
                case 'member_id':
                    searchParams.member_id = filter[key].join(',').length > 0 ? filter[key].join(',') : undefined;
                    break;
                case 'event_type_id':
                    searchParams.event_type_id = filter[key].join(',').length > 0 ? filter[key].join(',') : undefined;
                    break;
                case 'region_id':
                    searchParams.region_id = filter[key].join(',').length > 0 ? filter[key].join(',') : undefined;
                    break;
                case 'top_50':
                    searchParams.top_50 = filter[key] ? filter[key] : undefined;
                    break;
                case 'page':
                    searchParams[key] = filter[key] ? filter[key] : undefined;
                    break;
                case 'page_size':
                    searchParams[key] = filter[key] ? filter[key] : undefined;
                    break;
                case 'date_ranges':
                    continue;
                default:
                    break;
            }
        }

        return deleteUndefined(searchParams);
    };

    public onFilterValueChange = (values: ObjectLiteral) => {
        const formsValues = deleteUndefined(values);
        let filter: EventsGetFilter = { page: 1, page_size: this.pageSize };

        for (const key in formsValues) {
            switch (key) {
                case 'search':
                    filter.search = formsValues[key];
                    break;
                case 'date_ranges':
                    {
                        const value = formsValues[key];
                        if (!(value && typeof value === 'object' && value.length === 2 && value[0] !== null && value[1] !== null)) continue;
                        filter.range_start = dayjs(value[0]).startOf('day');
                        filter.range_end = dayjs(value[1]).endOf('day');
                    }
                    break;
                case 'result_type_id':
                    filter.result_type_id = formsValues[key].length > 0 ? formsValues[key] : undefined;
                    break;
                case 'operator_id':
                    filter.operator_id = formsValues[key].length > 0 ? formsValues[key] : undefined;
                    break;
                case 'region_id':
                    filter.region_id = formsValues[key].length > 0 ? formsValues[key] : undefined;
                    break;
                case 'member_id':
                    filter.member_id = formsValues[key].length > 0 ? formsValues[key] : undefined;
                    break;
                case 'event_type_id':
                    filter.event_type_id = formsValues[key].length > 0 ? formsValues[key] : undefined;
                    break;
                case 'top_50':
                    filter.top_50 = formsValues[key];
                    break;
                default:
                    break;
            }
        }

        /// Добавляем это для Form
        if (filter.range_start && filter.range_end) filter.date_ranges = [filter.range_start as Dayjs, filter.range_end as Dayjs];

        this.currentPage = 1;
        this.currentFilter = deleteUndefined(filter);

        const searchParams = this.extractSearchParamsFromFilter(deleteUndefined(filter));
        this.startParams.setSearchParams(searchParams as any);
        this.debouncedFetch(deleteUndefined(filter));
    };

    private debouncedFetch = debounce(async (params: EventsGetFilter) => {
        this.fetchEvents(params);
    }, 400);

    private async fetchResultTypeDictionary() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'result_type' });
            runInAction(() => {
                this.resultTypeDictionary = result.data as ResultTypeDictionaryData[];
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchResults(resultIds: number[]) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            let results: ResultData[] = [];
            for (const resultId of resultIds) {
                const result = await this.resultModel.getById(resultId);
                results.push(result.data);
            }

            runInAction(() => {
                this.resultsData = results;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchMembers(memberIds: number[]) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            let members: MemberData[] = [];
            for (const memberId of memberIds) {
                const member = await this.memberModel.getMemberById(memberId);
                members.push(member.data);
            }

            runInAction(() => {
                this.membersData = members;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchTimezoneDictionary() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'timezone' });
            runInAction(() => {
                this.timezoneDictionary = result.data as TimezoneDictionaryData[];
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchRegionDictionary() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'region' });
            runInAction(() => {
                this.regionDictionary = result.data as RegionDictionaryData[];
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchOperatorDictionary() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'operator' });
            runInAction(() => {
                this.opearatorDictionary = result.data as OperatorDictionaryData[];
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchEventTypesDictionary() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'event-type' });
            runInAction(() => {
                this.eventTypesDictionary = result.data as EventTypeDictionaryData[];
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchEvents(params: EventsGetFilter) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.eventModel.getEvents(params);
            let merged = this.mergeEventsWithTimezone(result.data || []);
            merged = this.mergeEventsWithRegion(merged);
            runInAction(() => {
                this.events = merged;
                this.eventsTotal = result.total || 0;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private mergeEventsWithTimezone(events: EventData[]): EventData[] {
        return events.map((event) => {
            if (!event.timezone_id) return event;
            return {
                ...event,
                timezone: this.getTimezoneById(event.timezone_id)
            };
        });
    }

    private mergeEventsWithRegion(events: EventData[]): EventData[] {
        return events.map((event) => {
            if (event.region_id === undefined) return event;
            return {
                ...event,
                region: this.getRegionById(event.region_id)
            };
        });
    }

    private getTimezoneById(id: number): TimezoneDictionaryData | undefined {
        const filtered = this.timezoneDictionary.filter((item) => item.id === id);
        if (filtered.length > 0) return filtered[0];
        return undefined;
    }

    private getRegionById(id: number): RegionDictionaryData | undefined {
        const filtered = this.regionDictionary.filter((item) => item.id === id);
        if (filtered.length > 0) return filtered[0];
        return undefined;
    }

    public delete = async (eventId: number) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.eventModel.delete(eventId);
            const updatedEvents = this.events.filter((item) => item.id !== eventId);
            this.events = updatedEvents;
            this.eventsTotal = this.eventsTotal - 1;
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.empty'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public onChangePagination = async (page: number, pageSize: number) => {
        if (pageSize !== this.pageSize) {
            runInAction(() => {
                this.pageSize = pageSize;
            });
        }
        const filter: EventsGetFilter = { ...this.currentFilter, page: page, page_size: pageSize };
        runInAction(() => {
            this.currentPage = page;
            this.currentFilter = filter;
        });
        const currentFiltersSearchParams = this.extractSearchParamsFromFilter(filter);

        this.startParams.setSearchParams(currentFiltersSearchParams);

        this.fetchEvents(filter);
    };

    public onExportExcel = async () => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.eventModel.getExcelExport(
                this.currentFilter,
                `${this.startParams.t('events.export.excel.filename')}_${dayjs(new Date()).format('DD.MM.YYYY_HH:mm')}.xlsx`
            );
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.empty'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };
}
