import { ObjectLiteral } from '@utils/object.utils';

export class User {
    id?: string;
    email: string;
    first_name: string;
    last_name: string;
    middle_name: string;
    birth_date: string;
    gender: string;
    live_places: string[];
    phone: string;
    languages: string[];
    groups: UserGroup[];
    member_id?: number;
    is_active: boolean;

    permissions?: UserPermissions[];
}

export class UserFull extends User {
    is_deleted: boolean;
    is_email_verify: boolean;
    is_phone_verify: boolean;
    security_activity: boolean;
    security_inst_member: boolean;
    created_at: Date;
    is_active: boolean;

    role_id: number;
    role_name: string;

    org_id: number;
    org_dadata_id: string;
    org_short_name: string;

    inst_id: number;
    inst_name: string;

    snils: string;
    inn: string;
}

export class UserCreateResponse {
    data: {
        id: string;
    };
}

export class UserGroup {
    id: string;
    name: string;
}

export interface UsersGetFilter {
    search?: string | undefined;
    page?: number | undefined;
    page_size?: number | undefined;
}

export class GetUsersResponse {
    data: User[] = [];
    count: number = 0;
    total: number = 0;
}

export class UserResponse {
    data: User;
}

export class UserFullResponse {
    data: UserFull;
}

// ORG INST

export class UserInst {
    id: number;
    name: string;
    short_name: string;
    post?: string;
}

export class UserOrg {
    dadata_id: string;
    full_name: string;
    short_name: string;
    address?: string;
}

export class UserInstResponse {
    data: UserInst[];
    count: number;
    total: number;
}

export class UserOrgResponse {
    data: UserOrg[];
    count: number;
    total: number;
}

// ACTIVITY

export class UserActivity {
    id: number;
    ref_id: number;
    event_member_date: string;
    institution_id: number;
    institution_name: string;
    institution_short_name: string;
    event_id: number;
    event_name: string;
    begin_date: string;
    end_date: string;
    year: number;
    region_id: number;
    region_name: string;
    type_id: number;
    type_name: string;
    primary_color: string;
    secondary_color: string;
    is_error?: boolean;
    is_deleted?: boolean;
}

export class UserActivityResponse {
    data: UserActivity[];
    count: number;
    total: number;
}

// MEMBERS

export class UserMember {
    id: number;
    first_name: string;
    last_name: string;
    middle_name: string;
    birth_date: string;
    email: string;
    phone: string;
    snils: string;
    inn: string;
    is_email_verify: boolean;
    is_phone_verify: boolean;
    is_snils_verify: boolean;
}

export class UserMemberResponse {
    data: UserMember;
}

export class UserMemberRaw extends UserMember {
    api_id: number;
    operator_api_name: string;
    operator_name: string;
    operator_site: string;
}

export class UserMemberRawResponse {
    data: UserMemberRaw[];
    count: number;
    total: number;
}

// INST PERMISSIONS

export class UserInstPermissions {
    user_inst_id: number;
    institution_id: number;
    institution_name: string;
    institution_short_name: string;
    institution_is_deleted: boolean;
    can_read: boolean;
    can_edit: boolean;
    can_approve: boolean;
    permission_is_deleted: boolean;
}

export class UserInstPermissionsResponse {
    data: UserInstPermissions[];
    count: number;
    total: number;
}

export class UserInstPermissionResponse {
    data: UserInstPermissions;
}

export class UserInstPermissionsRequest {
    user_inst_id: number;
    institution_id: number;
    institution_name: string;
    institution_short_name: string;
    institution_is_deleted: boolean;
    request_type: number;
    status: number;
    request_type_desc: string;
    status_desc: string;
    request_created_at: string;
    request_updated_at: string;
    request_is_deleted: boolean;
}

export class UserInstPermissionsRequestsResponse {
    data: UserInstPermissionsRequest[];
    count: number;
    total: number;
}

export class UserInstPermissionsRequestResponse {
    data: UserInstPermissionsRequest;
}

// USER PERMISSIONS

export class UserPermissions {
    id: number;
    name: string;
    alt_name: string;
}

export class UserPermissionsResponse {
    data: UserPermissions[];
    count: number;
    total: number;
}

// USER ORG FILE

export class UserOrgFile {
    created_at: string;
    downloads: number;
    id: number;
    is_private: boolean;
    name: string;
    size: number;
    uuid: string;
    title?: string;
}

export class UserOrgFilesResponse {
    data: UserOrgFile[];
    count: number;
    total: number;
}

// USER ROLE

export class UserRole {
    id: number;
    name: string;
}

export class UserRoleResponse {
    data: UserRole[];
    count: number;
    total: number;
}

export class UserHistoryLoggerData {
    id: number;
    user: HistoryLoggerDataUser;
    obj_id: number;
    /// Тип действия: 'create' | 'update' | 'delete'
    action: string;
    created_at: string;

    /// Объект с предыдущим состоянием
    prev_data?: UserFull;
    /// Объект с измененными полями
    changed_data?: ObjectLiteral;
}

export class HistoryLoggerDataUser {
    id: string;
    name: string;
}

export class UserHistoryLoggerDataListResponse {
    data: UserHistoryLoggerData[];
    total: number;
    count: number;
}

export class UserHistoryLoggerDataItemResponse {
    data: UserHistoryLoggerData;
}
