import { observer } from 'mobx-react-lite';
import React from 'react';
import { Button, Col, Divider, Row, Typography, notification } from 'antd';
import { useViewModel } from '@models/model';
import { useTranslation } from 'react-i18next';
import { FileTextOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { StaticPageViewModel } from './static-page.view.model';
import { StaticPageTable } from './static-page.table';
import { useNavigate } from 'react-router-dom';
import { StaticPage } from '@models/static-page-model';

const StaticPageView: React.FC = observer(() => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [notifyer, contextHolder] = notification.useNotification();

    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };
    const viewModel = useViewModel(
        () =>
            new StaticPageViewModel({
                t,
                showError: showErrorNotification
            })
    );

    const onCreate = () => {
        navigate('create');
    };

    const onEdit = (pageId?: number | undefined) => {
        if (!pageId) return;
        window.open(`/static-page-manage/edit/${pageId || ''}`, '_blank', 'noreferrer');
    };

    return (
        <div>
            {contextHolder}
            <Row gutter={12} align="middle" justify="space-between">
                <Col>
                    <Typography.Title>
                        {<FileTextOutlined />} {t('static_page.title')}
                    </Typography.Title>
                </Col>
                <Col>
                    <Button type="primary" icon={<PlusCircleOutlined />} onClick={onCreate}>
                        {t('common.create')}
                    </Button>
                </Col>
            </Row>

            <Divider style={{ marginTop: 0}} />
            <StaticPageTable
                currentPage={viewModel.currentPage}
                pageSize={viewModel.pageSize}
                rows={viewModel.staticPages}
                onEdit={(page: StaticPage) => onEdit(page.id)}
                onDelete={viewModel.delete}
                onChangePagination={viewModel.onChangePagination}
                loading={viewModel.loading}
                total={viewModel.spaticPagesTotal}
            />
        </div>
    );
});

export default StaticPageView;
