import { useState } from 'react';
import { Table, Form, Tooltip, Button, Space, Badge, Typography } from 'antd';
import { useEffect } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ReportApiData } from '@models/report-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

interface TableProps {
    currentPage: number;
    pageSize: number;
    rows: ReportApiData[];
    total: number;
    loading: boolean;
    onEdit: (report: ReportApiData) => void;
    onChangePagination: (page: number, pageSize: number) => void;
}

export const ContentTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [data, setData] = useState<ReportApiData[]>([]);

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const onChangePage = (page: number, pageSize: number) => {
        props.onChangePagination(page, pageSize);
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: t('reports-api.table.title'),
            dataIndex: 'name'
        },
        {
            title: t('reports-api.table.last_update'),
            render: (_: any, record: ReportApiData) => {
                dayjs.extend(utc);
                const date = dayjs(record.last_update);
                if (!date.isValid()) return <Typography.Text>{t('reports-api.noData')}</Typography.Text>;
                return <Typography.Text>{date.format('DD.MM.YYYY в HH:mm')}</Typography.Text>;
            }
        },
        {
            title: t('reports-api.table.has_new_data'),
            render: (_: any, record: ReportApiData) => {
                return (
                    <span>
                        {record.has_new_data ? (
                            <Badge status="processing" color="primary" text={t('common.yes')} />
                        ) : (
                            <Badge status="error" text={t('common.no')} />
                        )}
                    </span>
                );
            }
        },
        {
            title: '',
            width: '15%',
            dataIndex: 'operations',
            render: (_: any, record: ReportApiData) => {
                return (
                    <Space direction="horizontal" size="small" align="center">
                        <Tooltip title={t('statements.show.description')}>
                            <Button onClick={() => props.onEdit(record)}>
                                {t('statements.show.title')}
                                <ArrowRightOutlined />
                            </Button>
                        </Tooltip>
                    </Space>
                );
            }
        }
    ];

    return (
        <Form form={form} component={false}>
            <Table
                size="small"
                loading={props.loading}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="row"
                rowKey={'id'}
                pagination={{
                    current: props.currentPage,
                    pageSize: props.pageSize,
                    total: props.total,
                    position: ['bottomCenter'],
                    onChange: onChangePage
                }}
            />
        </Form>
    );
};
