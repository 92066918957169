import {
    Button,
    Card,
    Col,
    Collapse,
    DatePicker,
    Descriptions,
    Divider,
    Drawer,
    Form,
    Input,
    Row,
    Space,
    Switch,
    Typography,
    notification
} from 'antd';
import '@assets/css/App.css';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MemberData, MemberInstitution, MemberInstitutionInfo } from '@models/member-model';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { stringToDate } from '@utils/date.utils';
import dayjs from 'dayjs';

interface Props {
    visible: boolean;
    member: MemberData;
    onClose: () => void;
    onSave: (member: MemberData) => void;
}

const EditMember: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [notifyer, contextHolder] = notification.useNotification();
    const showError = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    useEffect(() => {
        form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.member]);

    const onClose = async () => {
        props.onClose();
    };

    const onSave = async () => {
        try {
            const valFromFields: Record<string, any> = form.getFieldsValue();
            var converted: MemberData = plainToInstance(MemberData, {
                id: props.member?.id,
                ...valFromFields,
                birth_date: valFromFields['birth_date'].format('YYYY-MM-DD')
            });
            await save(converted);
        } catch (error) {
            showError(t('common.error.save'), `${error}`);
        }
    };

    const save = async (member: MemberData) => {
        const result = await props.onSave(member);
        if (typeof result === 'string') {
            showError(t('common.error.save'), `${result}`);
        } else {
            onClose();
        }
    };

    const prepareDataForForm = () => {
        let member = instanceToPlain(props.member);
        if (typeof props.member.birth_date === 'string') {
            member.birth_date = dayjs(stringToDate(props.member.birth_date));
        }
        return member;
    };

    const institutionInfoView = (instInfo: MemberInstitutionInfo, index: number) => {
        return (
            <div key={`inst_desc_${index}`}>
                <Descriptions
                    key={`inst_desc_${index}`}
                    size="small"
                    items={[
                        {
                            key: '1',
                            label: t('members.content.institution.member_status'),
                            span: 3,
                            children: instInfo.member_status || t('members.content.institution.empty')
                        },
                        {
                            key: '2',
                            label: t('members.content.institution.spec'),
                            span: 3,
                            children: instInfo.spec || t('members.content.institution.empty')
                        },
                        {
                            key: '3',
                            label: t('members.content.institution.finance_source'),
                            span: 3,
                            children: instInfo.finance_source || t('members.content.institution.empty')
                        },
                        {
                            key: '4',
                            label: t('members.content.institution.vo'),
                            span: 3,
                            children: instInfo.vo || t('members.content.institution.empty')
                        },
                        {
                            key: '5',
                            label: t('members.content.institution.level_ed'),
                            span: 3,
                            children: instInfo.level_ed || t('members.content.institution.empty')
                        },
                        {
                            key: '6',
                            label: t('members.content.institution.course_ed'),
                            span: 3,
                            children: instInfo.course_ed || t('members.content.institution.empty')
                        }
                    ]}
                />
                <Divider />
            </div>
        );
    };

    const institutionView = (inst: MemberInstitution) => {
        return <div>{inst.info.map((item, index) => institutionInfoView(item, index))}</div>;
    };

    return (
        <Drawer
            forceRender
            title={t('members.edit.title')}
            width={650}
            onClose={onClose}
            open={props.visible}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
                <Space>
                    <Button onClick={onSave} type="primary">
                        {t('common.save')}
                    </Button>
                </Space>
            }
        >
            {contextHolder}
            <Card title="id" size="small">
                <Typography.Text copyable>{props.member.id}</Typography.Text>
            </Card>

            <Divider />
            <Form layout="vertical" form={form} initialValues={prepareDataForForm()} requiredMark={true}>
                <Form.Item name="last_name" label={t('common.last_name')} rules={[{ required: true, message: t('members.content.required') || '' }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="first_name"
                    label={t('common.first_name')}
                    rules={[{ required: true, message: t('members.content.required') || '' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="middle_name"
                    label={t('common.middle_name')}
                    rules={[{ required: true, message: t('members.content.required') || '' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="birth_date"
                    label={t('members.content.birth_date')}
                    rules={[{ required: true, message: t('members.content.required') || '' }]}
                >
                    <DatePicker format={'DD.MM.YYYY'} />
                </Form.Item>

                <Row gutter={12}>
                    <Col span={16}>
                        <Form.Item name="email" label={t('members.content.email')}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="is_email_verify" label={t('members.content.verified')} valuePropName="checked">
                            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={12}>
                    <Col span={16}>
                        <Form.Item name="phone" label={t('members.content.phone')}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="is_phone_verify" label={t('members.content.verified')} valuePropName="checked">
                            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={12}>
                    <Col span={16}>
                        <Form.Item name="snils" label={t('members.content.snils')}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="is_snils_verify" label={t('members.content.verified')} valuePropName="checked">
                            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="inn" label={t('members.content.inn')}>
                    <Input />
                </Form.Item>

                <Form.Item label={`${t('members.content.institution.title')} - ${props.member.institution ? props.member.institution.length : 0}`}>
                    <Collapse
                        // defaultActiveKey={['0']}
                        items={(props.member.institution || []).map((inst, index) => {
                            return {
                                key: `inst_${index}`,
                                label: inst.name,
                                children: institutionView(inst)
                            };
                        })}
                    />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default EditMember;
