import { LocalStorageKeys } from '@utils/local-storage/keys';
import LocalStorageUtil from '@utils/local-storage/local-storage.util';
import { makeAutoObservable, runInAction } from 'mobx';

export class AppModel {
    private localStorageUtil = new LocalStorageUtil();
    public isSidebarCollapsed: boolean = false;

    constructor() {
        makeAutoObservable(this);
        this.weakUp();
    }

    private async weakUp() {
        const isSidebarCollapsed: boolean | undefined = await this.localStorageUtil.get(LocalStorageKeys.sidebar.collapsed());
        this.isSidebarCollapsed = isSidebarCollapsed || false;
    }

    public toggleCollapsedSidebar = () => {
        this.localStorageUtil.set(!this.isSidebarCollapsed, LocalStorageKeys.sidebar.collapsed());
        runInAction(() => {
            this.isSidebarCollapsed = !this.isSidebarCollapsed;
        });
    };
}
