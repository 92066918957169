import React from 'react';
import { Outlet } from 'react-router-dom';
import LoginView from '@pages/auth/auth.view';
import Sidebar from '@components/layout/sidebar';
import { ConfigProvider, Layout } from 'antd';
import { observer } from 'mobx-react-lite';
import { RootModel } from '@models/root-model';
import { Content } from 'antd/es/layout/layout';
import AppHeader from '@components/layout/header';

import ruRU from 'antd/locale/ru_RU';
import 'dayjs/locale/ru';

const RootView: React.FC = observer(() => {
    if (!RootModel.authModel.isAuthenticated) {
        return <LoginView />;
    } else {
        return (
            <ConfigProvider
                locale={ruRU}
                theme={{
                    token: {
                        colorPrimary: '#a533ff',
                        colorBgLayout: '#EAF1F3',
                        fontFamily: 'Gilroy'
                    }
                }}
            >
                <Layout className="app">
                    <Sidebar permissions={[]} />
                    <Layout>
                        <AppHeader profile={RootModel.authModel.profile} />

                        <Content>
                            <div className="content-container" style={{ marginLeft: 20, marginRight: 20, minHeight: 300 }}>
                                <Outlet />
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </ConfigProvider>
        );
    }
});

export default RootView;
