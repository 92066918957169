import { observer } from 'mobx-react-lite';
import React from 'react';
import { Col, Divider, Row, Typography, notification } from 'antd';
import { useViewModel } from '@models/model';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { NoticeTemplateViewModel } from './notice-template.view.model';
import { NoticeTemplateData } from '@models/notice-template-model';
import { TemplateTable } from './notice-template.view.table';

const NoticeTemplateView: React.FC = observer(() => {
    const { t } = useTranslation();
    const [search, setSearch] = useSearchParams();
    const setSearchParams = (params: any) => setSearch(params);

    const [notifyer, contextHolder] = notification.useNotification();
    const showSuccessNotification = (title: string, description: string) => {
        notifyer.success({
            message: title,
            description: description
        });
    };
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const viewModel = useViewModel(
        () =>
            new NoticeTemplateViewModel({
                t,
                searchParams: search,
                setSearchParams,
                showSuccess: showSuccessNotification,
                showError: showErrorNotification
            })
    );

    const onEdit = (template: NoticeTemplateData) => {
        window.open(`/notice-templates/edit/${template.id || ''}`, '_blank', 'noreferrer');
    };

    return (
        <div>
            {contextHolder}
            <Row gutter={12} align="middle" justify="space-between">
                <Col>
                    <Typography.Title>{t('noticeTemplate.title')}</Typography.Title>
                </Col>
            </Row>

            <Divider style={{ marginTop: 0 }} />
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <TemplateTable
                    loading={viewModel.isLoading}
                    pageSize={viewModel.pageSize}
                    rows={viewModel.templates}
                    total={viewModel.templatesTotal}
                    onChangePagination={viewModel.onChangePagination}
                    onEdit={onEdit}
                />
            </Space>
        </div>
    );
});

export default NoticeTemplateView;
