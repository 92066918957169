import { APIInstance } from '@utils/api';
import { deleteUndefined } from '@utils/object.utils';
import { GetStartupsResponse, StartupData, StartupResponse } from './startup.contract';
import { StartupGetFilter } from '@interfaces/startup.interface';

export class StartupModel {
    public async getStartups(params: StartupGetFilter): Promise<GetStartupsResponse> {
        try {
            const result = await APIInstance.get<GetStartupsResponse>('/cp-manager/startup', {
                params: deleteUndefined({
                    search: params.search,
                    result_type_id: params.result_type_id ? [params.result_type_id] : undefined,
                    result_id: params.result_id ? [params.result_id] : undefined,
                    member_id: params.member_id,
                    finance_id: params.finance_id,
                    rid_id: params.rid_id,
                    limit: params.page_size,
                    offset: params.page_size && params.page ? params.page_size * (params.page - 1) : 0
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getStartupById(id: string | number): Promise<StartupResponse> {
        try {
            const result = await APIInstance.get<StartupResponse>(`/cp-manager/startup/${id}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async update(startup: StartupData): Promise<StartupResponse> {
        try {
            const result = await APIInstance.put<StartupResponse>(`/cp-manager/startup/${startup.id}`, { ...startup });
            return result.data;
        } catch (error) {
            throw error;
        }
    }
}
