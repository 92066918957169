import { useState } from 'react';
import { Table, Form, Typography, Tooltip, Button, Badge, Statistic } from 'antd';
import { useEffect } from 'react';
import { EditOutlined, UnorderedListOutlined, RocketOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { MemberData } from '@models/member-model';
import CountUp from 'react-countup';
import { formatDate } from '@utils/date.utils';

interface TableProps {
    currentPage: number;
    pageSize: number;
    rows: MemberData[];
    total: number;
    loading: boolean;
    onEdit: (id: number) => void;
    onShowEvents: (id: number) => void;
    onShowStartups: (id: number) => void;
    onShowUser: (userId?: string | undefined) => void;
    onChangePagination: (page: number, pageSize: number) => void;
}

export const EditableTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [data, setData] = useState<MemberData[]>([]);

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const onChangePage = (page: number, pageSize: number) => {
        props.onChangePagination(page, pageSize);
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: t('common.fio'),
            render: (_: any, record: MemberData) => {
                return (
                    <Typography.Text>
                        {record.last_name} {record.first_name} {record.middle_name}
                    </Typography.Text>
                );
            }
        },
        {
            title: t('members.content.birth_date'),
            render: (_: any, record: MemberData) => {
                return <Typography.Text>{formatDate(record.birth_date, t('common.date.pattern.date'))}</Typography.Text>;
            }
        },
        {
            title: t('members.content.email'),
            dataIndex: 'email'
        },
        {
            title: t('members.content.participation_count.title'),
            width: '16%',
            render: (_: any, record: MemberData) => (
                <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                    {/* events */}
                    <Tooltip title={t('members.content.participation_count.description_events')}>
                        <Button
                            style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                            type="text"
                            onClick={() => props.onShowEvents(record.id)}
                            disabled={record.event_count === 0 ? true : false}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', opacity: record.event_count ? 1 : 0.3 }}>
                                <UnorderedListOutlined style={{ fontSize: 18, color: 'gray' }} />
                                <Statistic
                                    style={{ paddingTop: 4, paddingLeft: 5 }}
                                    valueStyle={{ fontSize: 18 }}
                                    value={record.event_count}
                                    formatter={(value: number | string) => {
                                        return <CountUp end={Number(value)} separator=" " />;
                                    }}
                                />
                            </div>
                        </Button>
                    </Tooltip>
                    {/* startups */}
                    <Tooltip title={t('members.content.participation_count.description_startups')}>
                        <Button
                            style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                            type="text"
                            onClick={() => props.onShowStartups(record.id)}
                            disabled={record.startup_count === 0 ? true : false}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', opacity: record.startup_count ? 1 : 0.3 }}>
                                <RocketOutlined style={{ fontSize: 18, color: 'gray' }} />
                                <Statistic
                                    style={{ paddingTop: 4, paddingLeft: 5 }}
                                    valueStyle={{ fontSize: 18 }}
                                    value={record.startup_count}
                                    formatter={(value: number | string) => {
                                        return <CountUp end={Number(value)} separator=" " />;
                                    }}
                                />
                            </div>
                        </Button>
                    </Tooltip>
                </div>
            )
        },
        {
            title: t('members.content.user.title'),
            width: '8%',
            render: (_: any, record: MemberData) => {
                return (
                    <Tooltip title={record.user_id && record.user_id.length > 0 ? t('members.content.user.description') : undefined}>
                        <Button
                            style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                            type="text"
                            onClick={() => props.onShowUser(record.user_id)}
                        >
                            {record.user_id && record.user_id.length > 0 ? (
                                <Badge status="processing" color="primary" text={t('common.yes')} />
                            ) : (
                                <Badge status="error" text={t('common.no')} />
                            )}
                        </Button>
                    </Tooltip>
                );
            }
        },
        {
            title: '',
            width: '10%',
            dataIndex: 'operations',
            render: (_: any, record: MemberData) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Tooltip title={t('common.edit')}>
                            <Button onClick={() => props.onEdit(record.id)}>
                                <EditOutlined />
                            </Button>
                        </Tooltip>
                    </div>
                );
            }
        }
    ];

    return (
        <Form form={form} component={false}>
            <Table
                size="small"
                loading={props.loading}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="row"
                rowKey={'id'}
                pagination={{
                    current: props.currentPage,
                    pageSize: props.pageSize,
                    total: props.total,
                    position: ['bottomCenter'],
                    onChange: onChangePage
                }}
            />
        </Form>
    );
};
