import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import '@assets/css/App.css';
import {
    Button,
    Col,
    Form,
    Input,
    notification,
    Row,
    Spin,
    Switch,
    Divider,
    Breadcrumb,
    Table,
    Tooltip,
    Typography,
    Badge,
    Space,
    Select,
    Tag,
    Modal
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useViewModel } from '@models/model';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined, PlusCircleOutlined, ArrowRightOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { StatementsEditViewModel } from './statements.edit.view.model';
import { plainToInstance } from 'class-transformer';
import { useNavigate, useParams } from 'react-router-dom';
import { StatementData, StatementFilterData } from '@models/statements-model';

const StatementsEditView: React.FC = observer(() => {
    const { t } = useTranslation();
    const { id: statementId } = useParams();
    const navigate = useNavigate();
    const [filtersForm] = Form.useForm();
    const [form] = Form.useForm<StatementData>();
    const [changedFilters, setChangedFilters] = useState<any>({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalShowTestQuery, setModalShowTestQuery] = useState(false);

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (description: string) => {
        notifyer.error({
            message: t('common.error.empty'),
            description: description
        });
    };

    const onFinish = () => {
        onClickCancel();
    };

    const onClickCancel = () => {
        navigate('/statements-manage');
    };

    const viewModel = useViewModel(() => new StatementsEditViewModel({ t, statementId, showError: showErrorNotification, onFinish }));

    useEffect(() => {
        form.resetFields();
    }, [viewModel.statement]);

    const onClickSave = () => form.submit();

    const getFormData = () => {
        const valFromFields: Record<string, any> = form.getFieldsValue();
        var converted: StatementData = plainToInstance(StatementData, { ...viewModel.statement, ...valFromFields });

        return converted;
    };

    const onSuccessFormValidation = async () => {
        const data = getFormData();
        await viewModel.onSave(data);
    };

    const titleText = `${viewModel.statement?.name || ''}`;

    const filtersColumns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: t('statements.filters.name.title'),
            dataIndex: 'name'
        },
        {
            title: t('statements.filters.desc.title'),
            dataIndex: 'desc'
        },
        {
            title: (
                <Tooltip title={t('statements.filters.query.hint')}>
                    <Typography.Text>{t('statements.filters.query.title')}</Typography.Text>
                </Tooltip>
            ),
            dataIndex: 'query'
        },
        {
            title: t('statements.filters.depends_on.title'),
            render: (_: any, record: StatementFilterData) => {
                return (
                    <span>
                        {record.depends_on ? (
                            <span>
                                #{record.depends_on} {viewModel.filters.find((i) => i.id === record.depends_on)?.desc}
                            </span>
                        ) : (
                            <span></span>
                        )}
                    </span>
                );
            }
        },
        {
            title: (
                <Tooltip title={t('statements.filters.is_show.hint')}>
                    <Typography.Text>{t('statements.filters.is_show.title')}</Typography.Text>
                </Tooltip>
            ),
            width: '9%',
            render: (_: any, record: StatementFilterData) => {
                return (
                    <span>
                        {record.is_show ? (
                            <Badge status="processing" color="primary" text={t('common.yes')} />
                        ) : (
                            <Badge status="error" text={t('common.no')} />
                        )}
                    </span>
                );
            }
        },
        {
            title: (
                <Tooltip title={t('statements.filters.dict_ref.hint')}>
                    <Typography.Text>{t('statements.filters.dict_ref.title')}</Typography.Text>
                </Tooltip>
            ),
            dataIndex: 'dict_name',
            width: '9%'
        },
        {
            title: (
                <Tooltip title={t('statements.filters.parameter.hint')}>
                    <Typography.Text>{t('statements.filters.parameter.title')}</Typography.Text>
                </Tooltip>
            ),
            dataIndex: 'parameter'
        },
        {
            title: '',
            width: '5%',
            dataIndex: 'operations',
            render: (_: any, record: StatementFilterData) => {
                return (
                    <Space direction="horizontal" size="small" align="center">
                        <Tooltip title={t('statements.show.description')}>
                            <Button onClick={() => navigate(`filters/${record.id || ''}`)}>
                                <ArrowRightOutlined />
                            </Button>
                        </Tooltip>
                    </Space>
                );
            }
        }
    ];

    var dataColumns: any[] = viewModel.statementData.length
        ? Object.keys(viewModel.statementData[0]).map((i) => {
              return { title: i, dataIndex: i };
          })
        : [];

    var testDataColumns: any[] = viewModel.testResult.length
        ? Object.keys(viewModel.testResult[0]).map((i) => {
              return { title: i, dataIndex: i };
          })
        : [];

    const onFiltersChange = (changed: any) => {
        let tmpChangedObj: any = Object.keys(changed)[0] === 'ext' ? { ...changedFilters } : { ...changedFilters, ...changed };
        setChangedFilters(tmpChangedObj);
        var selectedFilters: any[] = [];
        for (const [key, item] of Object.entries(tmpChangedObj)) {
            if (key && item) selectedFilters.push({ id: parseInt(key), value: item });
        }

        if (statementId) {
            if (Object.keys(changed)[0] === 'ext') {
                viewModel.exportData(statementId, selectedFilters, changed['ext']);
                filtersForm.resetFields(['ext']);
            } else viewModel.fetchStatementData(statementId, selectedFilters);
        }
    };

    const onSelectDefaultFilter = (value: string) => {
        viewModel.createDefaultFilter(value);
        setIsModalVisible(false);
    };

    const testQuery = (type: string) => {
        const formData = getFormData();
        setModalShowTestQuery(true);
        switch (type) {
            case 'query':
                viewModel.testQuery(formData.query);
                break;
            case 'count_query':
                viewModel.testQuery(formData.count_query);
                break;
        }
    };

    const onCloseModalTestQuery = () => {
        viewModel.testResult = [];
        setModalShowTestQuery(false);
    };

    return (
        <div>
            <br />
            <Breadcrumb items={[{ title: <a href="/statements-manage">{t('statements.title')}</a> }, { title: titleText }]} />
            <Spin spinning={viewModel.loading}>
                <div>
                    {contextHolder}

                    <Divider orientation="left">{t('statements.content.edit')}</Divider>

                    <div className="edit-content-view">
                        <Row gutter={12} justify="end" align="middle">
                            <Col></Col>
                            <Col>
                                <Button type="primary" onClick={onClickSave} loading={viewModel.loading} disabled={viewModel.loading}>
                                    {t('common.save')}
                                </Button>
                            </Col>
                        </Row>
                        <br />

                        <Form form={form} initialValues={viewModel.statement} layout="horizontal" onFinish={onSuccessFormValidation}>
                            <Form.Item
                                label={t('statements.content.name.title')}
                                name="name"
                                rules={[{ required: true, message: t('common.required') || '' }]}
                            >
                                <Input placeholder={t('statements.content.name.placeholder') || ''} />
                            </Form.Item>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Form.Item
                                    label={t('statements.content.query.title')}
                                    name="query"
                                    rules={[{ required: true, message: t('common.required') || '' }]}
                                    style={{ width: '95%' }}
                                >
                                    <TextArea rows={6} placeholder={t('statements.content.query.hint') || ''} />
                                </Form.Item>
                                <Tooltip title={t('statements.content.query.test')}>
                                    <Button type="primary" icon={<PlayCircleOutlined />} onClick={() => testQuery('query')} />
                                </Tooltip>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Form.Item
                                    label={t('statements.content.count_query.title')}
                                    name="count_query"
                                    rules={[{ required: true, message: t('common.required') || '' }]}
                                    style={{ width: '95%' }}
                                >
                                    <TextArea rows={6} placeholder={t('statements.content.count_query.hint') || ''} />
                                </Form.Item>
                                <Tooltip title={t('statements.content.count_query.test')}>
                                    <Button type="primary" icon={<PlayCircleOutlined />} onClick={() => testQuery('count_query')} />
                                </Tooltip>
                            </div>

                            <Form.Item
                                name="is_visible"
                                label={t('statements.content.is_visible.title')}
                                tooltip={t('statements.content.is_visible.hint')}
                                valuePropName="checked"
                            >
                                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                            </Form.Item>
                        </Form>
                    </div>

                    <Divider orientation="left">{t('statements.content.filters')}</Divider>

                    <div className="edit-content-view">
                        <Row gutter={12} align="middle" justify="space-between">
                            <Col></Col>
                            <Col>
                                <Space>
                                    <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => setIsModalVisible(true)}>
                                        {t('common.create')}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <br />

                        <Table
                            size="small"
                            loading={viewModel.loadingFilters}
                            bordered
                            dataSource={viewModel.filters}
                            columns={filtersColumns}
                            rowClassName="row"
                            rowKey={'id'}
                            pagination={false}
                        />
                    </div>

                    <Divider orientation="left">{t('statements.content.statement')}</Divider>

                    <div className="edit-content-view">
                        <div style={{ fontWeight: 700, marginBottom: '16px' }}>Показано 20 результатов из {viewModel.statementDataTotal}</div>
                        <Form form={filtersForm} layout="vertical" onValuesChange={onFiltersChange}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Row align="middle">
                                    {viewModel.filters.map((filter, i) => (
                                        <Col key={`filter-${i}`} style={{ marginRight: '10px' }}>
                                            <Form.Item name={filter.id} label={filter.name}>
                                                <Select style={{ minWidth: '200px' }} allowClear={true}>
                                                    {filter.values &&
                                                        filter.values.map((item, j) => (
                                                            <Select.Option key={`filter-item-${j}`} value={item.value} label={item.title}>
                                                                <Space direction="vertical">
                                                                    <Tag>{item.title}</Tag>
                                                                </Space>
                                                            </Select.Option>
                                                        ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    ))}
                                </Row>
                                <div>
                                    <Form.Item name={'ext'} label={'Экспортировать'}>
                                        <Select style={{ minWidth: '200px' }}>
                                            <Select.Option value={'xlsx'} label={'xlsx'}>
                                                <Space direction="vertical">
                                                    <Tag>xlsx</Tag>
                                                </Space>
                                            </Select.Option>
                                            <Select.Option value={'ods'} label={'ods'}>
                                                <Space direction="vertical">
                                                    <Tag>ods</Tag>
                                                </Space>
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                        <br />
                        <Table
                            size="small"
                            loading={viewModel.loadingData}
                            bordered
                            dataSource={viewModel.statementData}
                            columns={dataColumns}
                            rowClassName="row"
                            rowKey={'id'}
                            pagination={false}
                        />
                    </div>
                    <br />

                    <Modal
                        title={t('statements.filters.create_modal.title')}
                        open={isModalVisible}
                        onCancel={() => setIsModalVisible(false)}
                        footer={null}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>{t('statements.filters.create_modal.v1')}</div>
                                <Select
                                    onChange={(value) => {
                                        onSelectDefaultFilter(value);
                                    }}
                                    options={[
                                        { value: 'year', label: t('statements.filters.create_modal.year') },
                                        { value: 'quarter', label: t('statements.filters.create_modal.quarter') },
                                        { value: 'month', label: t('statements.filters.create_modal.month') },
                                        { value: 'region_id', label: t('statements.filters.create_modal.region_id') }
                                    ]}
                                />
                                <Divider orientation="center">{t('statements.filters.create_modal.or')}</Divider>
                                <Button type="primary" onClick={() => navigate(`filters/create`)}>
                                    {t('statements.filters.create_modal.v2')}
                                </Button>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        title={t('statements.test_query_modal.title')}
                        open={modalShowTestQuery}
                        onCancel={onCloseModalTestQuery}
                        footer={null}
                        width={1000}
                    >
                        <br />
                        <Table
                            size="small"
                            bordered
                            loading={viewModel.loadingTestData}
                            dataSource={viewModel.testResult}
                            columns={testDataColumns}
                            rowClassName="row"
                            rowKey={'id'}
                            pagination={false}
                            style={testDataColumns.length && testDataColumns[0].title === 'Запрос содержит ошибку:' ? { whiteSpace: 'pre' } : {}}
                        />
                    </Modal>
                </div>
            </Spin>
        </div>
    );
});

export default StatementsEditView;
