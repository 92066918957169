import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Space, Table, Tooltip, Typography } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined, EyeOutlined, SaveOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { DigitalFootprintFileData } from '@models/digital-footprint/files';

interface TableProps {
    currentPage: number;
    pageSize: number;
    rows: DigitalFootprintFileData[];
    total: number;
    loading: boolean;
    onChangePagination: (page: number, pageSize: number) => void;
    onDelete: (file: DigitalFootprintFileData) => void;
    onDownload: (file: DigitalFootprintFileData) => void;
    onShowEvent: (file: DigitalFootprintFileData) => void;
    onShowMember: (file: DigitalFootprintFileData) => void;
}

export const FilesTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [modal, contextHolder] = Modal.useModal();
    const [data, setData] = useState<DigitalFootprintFileData[]>([]);

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const onChangePage = (page: number, pageSize: number) => {
        props.onChangePagination(page, pageSize);
    };

    const confirmDelete = (file: DigitalFootprintFileData) => {
        modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: t('common.confirmation_required'),
            content: t('digital_footprint.files.delete.confirm'),
            okText: t('common.delete'),
            cancelText: t('common.cancel'),
            onOk: () => props.onDelete(file)
        });
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: t('digital_footprint.files.content.type.title'),
            width: '15%',
            render: (_: any, record: DigitalFootprintFileData) => {
                return <Typography.Text>{record.file.type_name}</Typography.Text>;
            }
        },
        {
            title: (
                <div>
                    <UnorderedListOutlined style={{ fontSize: 16, color: 'gray', marginRight: 5 }} />
                    {t('digital_footprint.files.content.event.title')}
                </div>
            ),
            render: (_: any, record: DigitalFootprintFileData) => {
                return (
                    <Space direction="horizontal" size="small">
                        <Typography.Text>{record.event.name}</Typography.Text>
                        <Tooltip title={t('digital_footprint.files.content.event.show')}>
                            <Button icon={<EyeOutlined />} onClick={() => props.onShowEvent(record)} />
                        </Tooltip>
                    </Space>
                );
            }
        },
        {
            title: (
                <div>
                    <UnorderedListOutlined style={{ fontSize: 16, color: 'gray', marginRight: 5 }} />
                    {t('digital_footprint.files.content.member.title')}
                </div>
            ),
            render: (_: any, record: DigitalFootprintFileData) => {
                return (
                    <Space direction="horizontal" size="small">
                        <Typography.Text>
                            {record.member.last_name} {record.member.first_name} {record.member.middle_name}
                        </Typography.Text>
                        <Tooltip title={t('digital_footprint.files.content.member.show')}>
                            <Button icon={<EyeOutlined />} onClick={() => props.onShowMember(record)} />
                        </Tooltip>
                    </Space>
                );
            }
        },
        {
            title: '',
            width: '8%',
            dataIndex: 'operations',
            render: (_: any, record: DigitalFootprintFileData) => {
                return (
                    <Space size="small">
                        <Tooltip title={t('digital_footprint.files.download')}>
                            <Button onClick={() => props.onDownload(record)}>
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                        <Tooltip title={t('common.delete')}>
                            <Button onClick={() => confirmDelete(record)}>
                                <DeleteOutlined />
                            </Button>
                        </Tooltip>
                    </Space>
                );
            }
        }
    ];

    return (
        <div>
            <Table
                size="small"
                loading={props.loading}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="row"
                rowKey={'id'}
                pagination={{
                    current: props.currentPage,
                    pageSize: props.pageSize,
                    total: props.total,
                    position: ['bottomCenter'],
                    onChange: onChangePage
                }}
            />
            {contextHolder}
        </div>
    );
};

export default FilesTable;
