import { useState } from 'react';
import { Table, Form, Tooltip, Button, Space, Badge, Typography } from 'antd';
import { useEffect } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { StatementData } from '@models/statements-model';

interface TableProps {
    rows: StatementData[];
    loading: boolean;
    onEdit: (statement: StatementData) => void;
}

export const ContentTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [data, setData] = useState<StatementData[]>([]);

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: t('statements.content.name.title'),
            dataIndex: 'name'
        },
        {
            title: (
                <Tooltip title={t('statements.content.query.hint')}>
                    <Typography.Text>{t('statements.content.query.title')}</Typography.Text>
                </Tooltip>
            ),
            render: (_: any, record: StatementData) => {
                return (
                    <div>
                        {record.query.split('\n').map((item, index) => (
                            <div key={`query-part-${index}`} style={item.startsWith('\t') ? { paddingLeft: '20px' } : {}}>
                                {item}
                            </div>
                        ))}
                    </div>
                );
            }
        },
        {
            title: (
                <Tooltip title={t('statements.content.is_visible.hint')}>
                    <Typography.Text>{t('statements.content.is_visible.title')}</Typography.Text>
                </Tooltip>
            ),
            width: '8%',
            render: (_: any, record: StatementData) => {
                return (
                    <span>
                        {record.is_visible ? (
                            <Badge status="processing" color="primary" text={t('common.yes')} />
                        ) : (
                            <Badge status="error" text={t('common.no')} />
                        )}
                    </span>
                );
            }
        },
        {
            title: '',
            width: '10%',
            dataIndex: 'operations',
            render: (_: any, record: StatementData) => {
                return (
                    <Space direction="horizontal" size="small" align="center">
                        <Tooltip title={t('statements.show.description')}>
                            <Button onClick={() => props.onEdit(record)}>
                                {t('statements.show.title')}
                                <ArrowRightOutlined />
                            </Button>
                        </Tooltip>
                    </Space>
                );
            }
        }
    ];

    return (
        <Form form={form} component={false}>
            <Table
                size="small"
                loading={props.loading}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="row"
                rowKey={'id'}
                pagination={false}
            />
        </Form>
    );
};
