import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Divider, Skeleton, Tag, Typography, notification } from 'antd';
import { EditableTable } from './table';
import { useViewModel } from '@models/model';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate, createSearchParams } from 'react-router-dom';
import { FinancesViewModel } from './finances.view.model';
import EditFinace from './finance.edit.view';
import { FinanceData } from '@models/finance-model';
import { FilterOutlined } from '@ant-design/icons';

const FinancesView: React.FC = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [search, setSearch] = useSearchParams();
    const setSearchParams = (params: any) => setSearch(params);

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const viewModel = useViewModel(() => new FinancesViewModel({ t, searchParams: search, setSearchParams, showError: showErrorNotification }));

    useEffect(() => {
        updateIfNeeded();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const updateIfNeeded = async () => {
        await viewModel.updateSearchParamsIfNeeded(search);
    };

    const onShowStartup = (finance: FinanceData) => {
        if (!finance.id) return;

        navigate({
            pathname: '/startups-manage',
            search: createSearchParams({
                finance_id: `${finance.id}`
            }).toString()
        });
    };

    const filters = () => {
        const infoCount = viewModel.filterInfoCount(viewModel.currentFilter);
        const infoFake = Array(infoCount).fill(0);
        if (infoCount > 0) {
            return (
                <Space style={{ width: '100%', marginTop: '10px' }} direction="horizontal" size="small">
                    {viewModel.isCurrentFilterLoading === true
                        ? infoFake.map((_, index) => <Skeleton.Input key={`skeleton-${index}`} active={true} size="small" />)
                        : viewModel.currentFilterInfo.map((infoItem, index) => (
                              <Tag
                                  key={`tag-${index}`}
                                  closable
                                  onClose={() => {
                                      viewModel.onRemoveFilterInfo(infoItem);
                                  }}
                                  color="purple"
                              >
                                  <Space>
                                      <FilterOutlined />
                                      <Typography.Text strong>
                                          {infoItem.name}: {infoItem.value}
                                      </Typography.Text>
                                  </Space>
                              </Tag>
                          ))}
                </Space>
            );
        } else {
            return <div />;
        }
    };

    return (
        <div>
            {contextHolder}
            <Space>
                <Typography.Title>{t('finances.title')}</Typography.Title>
                <Typography.Title style={{ marginTop: 25 }} level={2} type="secondary">
                    {viewModel.financesTotal}
                </Typography.Title>
            </Space>

            <Divider style={{ marginTop: 0, marginBottom: 0 }} />
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                {filters()}

                <EditableTable
                    currentPage={viewModel.currentPage}
                    loading={viewModel.isLoading}
                    rows={viewModel.finances}
                    total={viewModel.financesTotal}
                    pageSize={viewModel.pageSize}
                    onChangePagination={viewModel.onChangePagination}
                    onEdit={(id: number) => viewModel.onEdit(id)}
                    onShowStartup={onShowStartup}
                />
            </Space>
            {viewModel.financeOnEdit && (
                <EditFinace
                    visible={!!viewModel.financeOnEdit}
                    finance={viewModel.financeOnEdit}
                    transaction_type_dict={viewModel.transactionTypes}
                    onClose={() => viewModel.onCancelEdit()}
                    onSave={(finance: FinanceData) => viewModel.update(finance)}
                />
            )}
        </div>
    );
});

export default FinancesView;
