import { observer } from 'mobx-react-lite';
import React from 'react';
import { Button, Col, Row, Typography, notification } from 'antd';
import { useViewModel } from '@models/model';
import { useTranslation } from 'react-i18next';
import { IndicatorViewModel } from './indicator.view.model';
import { DotChartOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { DataTable } from './table';
import { Indicator } from '@models/indicator-model';
import { useNavigate } from 'react-router-dom';

const IndicatorView: React.FC = observer(() => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const viewModel = useViewModel(
        () =>
            new IndicatorViewModel({
                t,
                showError: showErrorNotification
            })
    );

    const onCreate = () => {
        navigate('create');
    };

    const onEdit = (indicatorId?: number | undefined) => {
        if (!indicatorId) return;
        window.open(`/indicator-manage/edit/${indicatorId || ''}`, '_blank', 'noreferrer');
    };

    return (
        <div>
            {contextHolder}
            <Row gutter={12} align="middle" justify="space-between">
                <Col>
                    <Typography.Title>
                        {<DotChartOutlined />} {t('indicator.title')}
                    </Typography.Title>
                </Col>
                <Col>
                    <Button type="primary" icon={<PlusCircleOutlined />} onClick={onCreate}>
                        {t('common.create')}
                    </Button>
                </Col>
            </Row>
            <br />
            <DataTable rows={viewModel.indicators} onEdit={(indicator: Indicator) => onEdit(indicator.id)} loading={viewModel.loading} />
        </div>
    );
});

export default IndicatorView;
