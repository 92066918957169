import { Alert, Button, Card, Col, Divider, Drawer, Form, Row, Space, Typography } from 'antd';
import '@assets/css/App.css';
import { plainToInstance } from 'class-transformer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WebsiteMenuData } from '@models/website-menu-model';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/snippets/json';
import 'ace-builds/src-min-noconflict/ext-searchbox';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import { ObjectLiteral } from '@utils/object.utils';

interface Props {
    visible: boolean;
    menuData: WebsiteMenuData;
    onClose: () => void;
    onSave: (menuData: WebsiteMenuData) => void;
}

const EditMenuData: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [jsonValue, setJsonValue] = useState(JSON.stringify(props.menuData.items, null, '\t'));
    const [errorText, setErrorText] = useState<string | null>(null);

    useEffect(() => {
        form.resetFields();
    }, [props.menuData]);

    const onClose = async () => {
        props.onClose();
    };

    const onSave = async () => {
        try {
            const valFromFields: Record<string, any> = form.getFieldsValue();
            const json = JSON.parse(jsonValue) as ObjectLiteral;
            var converted: WebsiteMenuData = plainToInstance(WebsiteMenuData, { id: props.menuData?.id, items: json });
            await save(converted);
        } catch (error) {
            showError(`${t('common.save')}: ${error}`);
        }
    };

    const save = async (menuData: WebsiteMenuData) => {
        const result = await props.onSave(menuData);
        if (typeof result === 'string') {
            showError(`${t('common.error.save')}: ${result}`);
        } else {
            onClose();
        }
    };

    const showError = (errorText: string) => {
        setErrorText(errorText);
        setTimeout(() => {
            setErrorText(null);
        }, 4000);
    };

    return (
        <Drawer
            forceRender
            title={t('common.edit')}
            width={800}
            onClose={onClose}
            open={props.visible}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
                <Space>
                    <Button onClick={onSave} type="primary">
                        {t('common.save')}
                    </Button>
                </Space>
            }
        >
            {errorText && (
                <Alert
                    style={{
                        marginBottom: 10
                    }}
                    message={errorText}
                    type="error"
                    showIcon
                />
            )}

            <Card title="id" size="small">
                <Typography.Text copyable>{props.menuData.id}</Typography.Text>
            </Card>

            <Divider />
            <Form layout="vertical" form={form} initialValues={props.menuData} requiredMark={true}>
                <Form.Item label={t('website_menu.content.structure.title')}>
                    <AceEditor
                        style={{ borderRadius: '10px', height: '800px' }}
                        placeholder="JSON"
                        value={jsonValue}
                        onChange={(value) => setJsonValue(value)}
                        setOptions={{
                            showPrintMargin: false,
                            useWorker: false,
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: true,
                            tabSize: 2
                        }}
                        width="100%"
                        name="json_editor"
                        mode="json"
                        theme="monokai"
                        editorProps={{ $blockScrolling: true }}
                    />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default EditMenuData;
