import { StatementsModel, StatementData } from '@models/statements-model';
import { makeAutoObservable, runInAction } from 'mobx';
import { APIUtilError } from '@utils/api';

export class StatementsViewModel {
    private statementModel = new StatementsModel();
    public isLoading: boolean = false;
    public statements: StatementData[] = [];

    constructor(private showError: (description: string) => void) {
        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        await this.fetchStatements();
    }

    private async fetchStatements() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.statementModel.getStatements();
            runInAction(() => {
                this.statements = result.data || [];
            });
        } catch (error) {
            this.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }
}
