import { Logger } from '@utils/logger';
import { isAfter, parseISO, add } from 'date-fns';

interface StorageItem {
    item?: any | undefined;
    expireAt?: string | undefined;
}

export default class LocalStorageUtil {
    public async get<T>(key: string): Promise<T | undefined> {
        try {
            const objectString = await localStorage.getItem(key);

            if (!objectString) {
                return undefined;
            }
            const parsedStorageItem: StorageItem = JSON.parse(objectString) as StorageItem;
            if (!parsedStorageItem.item) {
                return undefined;
            }
            if (parsedStorageItem.expireAt) {
                const expireData = parseISO(parsedStorageItem.expireAt);
                if (isAfter(new Date(), expireData)) {
                    await this.delete(key);
                    return undefined;
                }
            }
            return parsedStorageItem.item as Promise<T>;
        } catch (error) {
            Logger.error(`AsyncStorageService get error: ${error}`);
        }
    }

    public async set<T>(item: T, key: string, expirationDelay?: number | undefined): Promise<T> {
        var storageItem: StorageItem = {
            item: item
        };
        if (expirationDelay) {
            const expDate = add(new Date(), { seconds: expirationDelay });
            storageItem.expireAt = expDate.toISOString();
        }
        const objectString = JSON.stringify(storageItem);
        await localStorage.setItem(key, objectString);
        return item;
    }

    public async delete(key: string) {
        return await localStorage.removeItem(key);
    }
}
