import { v4 as uuidv4 } from 'uuid';

export class Indicator {
    id?: number | undefined;
    title: string = ''; //[56 символов; trim пробелов; минимум 5 символов]
    alt_title: string = ''; //[60 символов; trim пробелов; минимум 5 символов] - generator
    description: string = ''; //[TEXT]
    short_description: string = ''; //[TEXT]
    indicator_value: number = 0; //[INT, 10 символов] - no edit
    indicator_controller: string = ''; //[STRING, 255 символов]
    indicator_formula: IndicatorFormulaPart[] = []; //[STRING, 255 символов] - constructor ?
    created_at: string;
    updated_at: string;
}

export class IndicatorFormulaPart {
    internal_dnd_id: string | undefined;
    tag: string = '';
    type: string = indicatorFormulaPartValues[0];
    value: string = '';
    renderValue: string = '0';

    constructor() {
        this.internal_dnd_id = uuidv4();
    }

    public prepareForExport() {
        this.internal_dnd_id = undefined;
    }
}

export const indicatorFormulaPartValues = ['value', 'symbol'];

export class GetIndicatorsResponse {
    data: Indicator[];
}

export class IndicatorResponse {
    data: Indicator;
}
