import { useState } from 'react';
import { Table, Form, Tooltip, Button, Typography } from 'antd';
import { useEffect } from 'react';
import { EditOutlined, UsergroupAddOutlined, CalculatorOutlined, ExperimentOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { StartupData } from '@models/startup-model';

interface TableProps {
    currentPage: number;
    pageSize: number;
    rows: StartupData[];
    total: number;
    loading: boolean;
    onEdit: (id: number) => void;
    onChangePagination: (page: number, pageSize: number) => void;
    onShowMembers: (startup: StartupData) => void;
    onShowFinance: (startup: StartupData) => void;
    onShowRid: (startup: StartupData) => void;
}

export const EditableTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [data, setData] = useState<StartupData[]>([]);

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const onChangePage = (page: number, pageSize: number) => {
        props.onChangePagination(page, pageSize);
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: t('startups.content.name.title'),
            render: (_: any, record: StartupData) => {
                return <div>{record.name || t('startups.content.name.empty')}</div>;
            }
        },
        {
            title: t('startups.content.inn.title'),
            dataIndex: 'inn'
        },
        {
            title: t('startups.content.type.title'),
            dataIndex: 'startup_type',
            render: (_: any, record: StartupData) => {
                return <div>{record.startup_type?.name || t('startups.content.type.empty')}</div>;
            }
        },
        {
            title: t('startups.content.member_count.title'),
            render: (_: any, record: StartupData) => {
                return (
                    <Tooltip title={t('startups.content.member_count.show')}>
                        <Button
                            style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                            type="text"
                            onClick={() => props.onShowMembers(record)}
                            disabled={record.members_count ? false : true}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', opacity: record.members_count ? 1 : 0.3 }}>
                                <UsergroupAddOutlined style={{ fontSize: 16, color: 'gray' }} />
                                <Typography.Text style={{ fontSize: 18, paddingTop: 4, paddingLeft: 4 }}>
                                    {new Intl.NumberFormat('ru-ru', {}).format(record.members_count || 0)}
                                </Typography.Text>
                            </div>
                        </Button>
                    </Tooltip>
                );
            }
        },
        {
            title: t('startups.content.finance_count.title'),
            render: (_: any, record: StartupData) => {
                return (
                    <Tooltip title={t('startups.content.finance_count.show')}>
                        <Button
                            style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                            type="text"
                            onClick={() => props.onShowFinance(record)}
                            disabled={record.finance_count ? false : true}
                        >
                            <div
                                style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', opacity: record.finance_count ? 1 : 0.3}}
                            >
                                <CalculatorOutlined style={{ fontSize: 16, color: 'gray' }} />
                                <Typography.Text style={{ fontSize: 18, paddingTop: 4, paddingLeft: 4 }}>
                                    {new Intl.NumberFormat('ru-ru', {}).format(record.finance_count || 0)}
                                </Typography.Text>
                            </div>
                        </Button>
                    </Tooltip>
                );
            }
        },
        {
            title: t('startups.content.rid_count.title'),
            render: (_: any, record: StartupData) => {
                return (
                    <Tooltip title={t('startups.content.rid_count.show')}>
                        <Button
                            style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                            type="text"
                            onClick={() => props.onShowRid(record)}
                            disabled={record.rid_count ? false : true}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', opacity: record.rid_count ? 1 : 0.3 }}>
                                <ExperimentOutlined style={{ fontSize: 16, color: 'gray' }} />
                                <Typography.Text style={{ fontSize: 18, paddingTop: 4, paddingLeft: 4 }}>
                                    {new Intl.NumberFormat('ru-ru', {}).format(record.rid_count || 0)}
                                </Typography.Text>
                            </div>
                        </Button>
                    </Tooltip>
                );
            }
        },
        {
            title: '',
            width: '10%',
            dataIndex: 'operations',
            render: (_: any, record: StartupData) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Tooltip title={t('common.edit')}>
                            <Button onClick={() => props.onEdit(record.id)}>
                                <EditOutlined />
                            </Button>
                        </Tooltip>
                    </div>
                );
            }
        }
    ];

    return (
        <Form form={form} component={false}>
            <Table
                size="small"
                loading={props.loading}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="row"
                rowKey={'id'}
                pagination={{
                    current: props.currentPage,
                    pageSize: props.pageSize,
                    total: props.total,
                    position: ['bottomCenter'],
                    onChange: onChangePage
                }}
            />
        </Form>
    );
};
