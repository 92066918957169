import { observer } from 'mobx-react-lite';
import React from 'react';
import { Divider, Typography, notification } from 'antd';
import { EditableTable } from './table';
import { useViewModel } from '@models/model';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import EditMenuData from './website-menu.edit.view';
import { WebsiteMenuViewModel } from './website-menu.view.model';
import { WebsiteMenuData } from '@models/website-menu-model';

const WebsiteMenuView: React.FC = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id: menuId } = useParams();

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const viewModel = useViewModel(
        () =>
            new WebsiteMenuViewModel({
                t,
                menuId,
                showError: showErrorNotification
            })
    );

    const onEdit = (menuId?: number | undefined) => {
        if (!menuId) return;
        navigate(`${menuId || ''}`);
        viewModel.onEdit(menuId);
    };

    const onCloseConstructor = () => {
        navigate('/website-menu-manage');
        viewModel.onCancelEdit();
    };

    return (
        <div>
            {contextHolder}
            <Typography.Title>{t('website_menu.title')}</Typography.Title>
            <Divider />
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <EditableTable loading={viewModel.isLoading} rows={viewModel.menus} total={viewModel.menusTotal} onEdit={onEdit} />
            </Space>
            {viewModel.menuOnEdit && (
                <EditMenuData
                    visible={!!viewModel.menuOnEdit}
                    menuData={viewModel.menuOnEdit}
                    onClose={onCloseConstructor}
                    onSave={(menuData: WebsiteMenuData) => viewModel.update(menuData)}
                />
            )}
        </div>
    );
});

export default WebsiteMenuView;
