import { Dayjs } from 'dayjs';

export interface EventsGetFilter {
    search?: string | undefined;
    range_start?: string | Dayjs | Date | undefined;
    range_end?: string | Dayjs | Date | undefined;
    result_type_id?: number[] | undefined;
    result_id?: number[] | undefined;
    region_id?: number[] | undefined;
    operator_id?: number[] | undefined;
    member_id?: number[] | undefined;
    event_type_id?: number[] | undefined;
    top_50?: boolean;
    page?: number | undefined;
    page_size?: number | undefined;

    date_ranges?: Dayjs[] | undefined;
}

export const EventsFilterParamConfig = {
    dayjs_date_format: 'YYYY-MM-DDTHH:mm'
};

export type EventsFilterParamName =
    | 'search'
    | 'range_start'
    | 'range_end'
    | 'result_type_id'
    | 'result_id'
    | 'region_id'
    | 'operator_id'
    | 'member_id'
    | 'event_type_id'
    | 'top_50'
    | 'page'
    | 'page_size';
