import { useViewModel } from '@models/model';
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    Popconfirm,
    Row,
    Segmented,
    Space,
    Spin,
    Switch,
    Typography,
    notification,
    Tabs,
    Select,
    Tag,
    Table,
    Tooltip,
    Badge,
    Modal,
    Checkbox,
    List,
    Collapse,
    Alert
} from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { UserEditViewModel } from './user.edit.view.model';
import { useEffect, useState } from 'react';
import { stringToDate } from '@utils/date.utils';
import { ObjectLiteral } from '@utils/object.utils';
import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    UnlockOutlined,
    WarningOutlined,
    ArrowRightOutlined,
    ReadOutlined,
    EditOutlined,
    CrownOutlined,
    UserOutlined,
    HomeOutlined,
    QuestionCircleOutlined,
    AlertTwoTone,
    RedoOutlined,
    KeyOutlined
} from '@ant-design/icons';
import { UserActivity, UserMember, UserInstPermissions, UserInstPermissionsRequest, UserMemberRaw } from '@models/users-model';
import FilesCatalogView from '@components/UI/files-catalog/catalog.view';
import { HistoryView } from './users.history.view';
import InfiniteScroll from 'react-infinite-scroll-component';

const UserEditView: React.FC = observer(() => {
    const { t } = useTranslation();
    const { id: userId } = useParams();
    const [form] = Form.useForm();
    const [formSecure] = Form.useForm();
    const [formOrg] = Form.useForm();

    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('data');
    const [changedFields, setChangedFields] = useState<ObjectLiteral>({});
    const [changedOrgFields, setChangedOrgFields] = useState<ObjectLiteral>({});
    const [changedSecureFields, setChangedSecureFields] = useState<ObjectLiteral>({});
    const [changedPermissionsFields, setChangedPermissionsFields] = useState<number[]>([]);

    const [modalEditInstPermissions, setModalEditInstPermissions] = useState(false);
    const [modalEditUserPermissions, setModalEditUserPermissions] = useState(false);

    const [notifyer, contextHolder] = notification.useNotification();
    const showSuccessNotification = (title: string, description: string) => {
        notifyer.success({
            message: title,
            description: description
        });
    };
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const onClickCancel = () => {
        navigate('/users-manage');
    };

    const viewModel = useViewModel(
        () =>
            new UserEditViewModel({
                t: t,
                userId: userId,
                showSuccess: showSuccessNotification,
                showError: showErrorNotification,
                onClose: onClickCancel
            })
    );

    useEffect(() => {
        form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModel.user]);

    const onErrorFormValidation = (errorsInfo: any) => {
        showErrorNotification(t('common.error.save'), t('common.check_fields'));
    };

    const onTabChange = (key: string) => {
        setActiveTab(key);
    };

    const onClickSave = () => {
        switch (activeTab) {
            case 'data':
                form.submit();
                break;
            case 'security':
                formSecure.submit();
                break;
            case 'org':
                formOrg.submit();
                break;
        }
    };

    const onFormValueChange = (changed: any) => {
        setChangedFields({ ...changedFields, ...changed });
    };
    const onOrgValueChange = (changed: any) => {
        setChangedOrgFields({ ...changedOrgFields, ...changed });
    };
    const onSecureValueChange = (changed: any) => {
        setChangedSecureFields({ ...changedSecureFields, ...changed });
    };

    const onSuccessFormValidation = async () => {
        let data: any = {};
        switch (activeTab) {
            case 'data':
                data = { id: viewModel.user.id, ...changedFields };
                await viewModel.onSave(data);
                break;
            case 'security':
                data = { id: viewModel.user.id, ...changedSecureFields };
                await viewModel.onSaveSecure(data);
                break;
            case 'org':
                data = { id: viewModel.user.id, ...changedOrgFields };
                await viewModel.onSaveOrg(data);
                break;
        }
    };

    const getGenderSelectorContent = () =>
        [
            { id: 'Male', label: t('common.gender.male') },
            { id: 'Female', label: t('common.gender.female') }
        ].map((item) => {
            return {
                value: item.id,
                label: item.label
            };
        });

    const onSearchOrg = async (value: string) => {
        if (value) await viewModel.fetchOrg(value);
    };

    const onSearchInst = async (value: string) => {
        if (value) await viewModel.fetchInst(value);
    };

    const goToActivityItem = (item: UserActivity) => {
        // todo: для остальных типов ??
        switch (item.type_id) {
            case 5:
            case 6:
                navigate(`/events-manage/edit/${item.event_id}`);
                break;
        }
    };

    const deleteActivityItem = async (item: UserActivity) => {
        if (userId) await viewModel.deleteActivityItem(userId, item.id, !item.is_deleted);
    };

    const beautifyDate = (dateString: string) => {
        var dt = new Date(dateString);
        var month: number = dt.getMonth() + 1;
        var month_str: string = month > 9 ? `${month}` : `0${month}`;
        const res = `${dt.getMinutes()}:${dt.getHours()} ${dt.getDate()}.${month_str}.${dt.getFullYear()}`;
        return res;
    };

    const onEditPermissions = async (record: UserInstPermissions) => {
        viewModel.editedInstPermission = { ...record };
        setModalEditInstPermissions(true);
    };

    const editUserInstPermissions = async () => {
        await viewModel.editUserInstPermissions();
        setModalEditInstPermissions(false);
    };

    const acceptRejectInstPermissionsRequest = async (userInstId: number, status: boolean) => {
        await viewModel.acceptRejectInstPermissionsRequest(userInstId, status);
    };

    const handleChangeUserPermissions = (value: any) => {
        setChangedPermissionsFields(value);
    };

    const editUserPermissions = async () => {
        if (userId) await viewModel.editUserPermissions(userId, changedPermissionsFields);
        setModalEditUserPermissions(false);
    };

    const onChangeActivePage = (page: number, pageSize: number) => {
        viewModel.onChangeActivityPagination(page, pageSize);
    };

    const activityColumns = [
        {
            title: t('users.content.activity.type_name'),
            render: (_: any, record: UserActivity) => {
                return <Tag color={record.primary_color}>{record.type_name}</Tag>;
            }
        },
        {
            title: t('users.content.activity.event_name'),
            dataIndex: 'event_name'
        },
        {
            title: t('users.content.activity.event_member_date'),
            dataIndex: 'event_member_date'
        },
        {
            title: t('users.content.activity.region_name'),
            dataIndex: 'region_name'
        },
        {
            title: (
                <Tooltip title={t('users.content.activity.is_error.hint')}>
                    <span style={{ marginRight: '4px' }}>{t('users.content.activity.is_error.title')}</span>
                    <QuestionCircleOutlined />
                </Tooltip>
            ),
            width: '7%',
            render: (_: any, record: UserActivity) => {
                if (record.is_error) {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <AlertTwoTone twoToneColor="#ffa940" />
                        </div>
                    );
                }
            }
        },
        {
            title: t('users.content.activity.is_deleted'),
            render: (_: any, record: UserActivity) => {
                return (
                    <span>
                        {!record.is_deleted ? (
                            <Badge status="processing" color="primary" text={t('common.yes')} />
                        ) : (
                            <Badge status="error" text={t('common.no')} />
                        )}
                    </span>
                );
            }
        },
        {
            title: '',
            width: '10%',
            dataIndex: 'operations',
            render: (_: any, record: UserActivity) => {
                return (
                    <Space direction="horizontal" size="small" align="center">
                        <Button onClick={() => goToActivityItem(record)}>
                            {t('users.content.activity.show.title')}
                            <ArrowRightOutlined />
                        </Button>
                        <Tooltip title={record.is_deleted ? t('users.content.activity.recover.hint') : t('users.content.activity.delete.hint')}>
                            <Button
                                onClick={() => deleteActivityItem(record)}
                                danger={record.is_error}
                                icon={record.is_deleted ? <RedoOutlined /> : <DeleteOutlined />}
                                type="text"
                            />
                        </Tooltip>
                    </Space>
                );
            }
        }
    ];

    const userMembersRawColumnsCards = [
        {
            title: t('users.content.members.last_name'),
            dataIndex: 'last_name'
        },
        {
            title: t('users.content.members.first_name'),
            dataIndex: 'first_name'
        },
        {
            title: t('users.content.members.middle_name'),
            dataIndex: 'middle_name'
        },
        {
            title: t('users.content.members.birth_date'),
            dataIndex: 'birth_date'
        },
        {
            title: t('users.content.members.email'),
            dataIndex: 'email'
        },
        {
            title: t('users.content.members.phone'),
            dataIndex: 'phone'
        },
        {
            title: t('users.content.members.snils'),
            dataIndex: 'snils'
        },
        {
            title: t('common.inn'),
            dataIndex: 'inn'
        },
        {
            title: t('users.content.members.is_email_verify'),
            dataIndex: 'is_email_verify',
            type: 'boolean'
        },
        {
            title: t('users.content.members.is_phone_verify'),
            dataIndex: 'is_phone_verify',
            type: 'boolean'
        },
        {
            title: t('users.content.members.is_snils_verify'),
            dataIndex: 'is_snils_verify',
            type: 'boolean'
        }
    ];

    const instPermissionsColumns = [
        {
            title: t('users.content.instPermissions.institution_name'),
            render: (_: any, record: UserInstPermissions) => {
                return (
                    <div>
                        {record.institution_short_name && (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography.Text>{record.institution_short_name}</Typography.Text>
                                <Typography.Text type="secondary">{record.institution_name}</Typography.Text>
                            </div>
                        )}
                        {!record.institution_short_name && <Typography.Text>{record.institution_name}</Typography.Text>}
                    </div>
                );
            }
        },
        {
            title: t('users.content.instPermissions.title'),
            render: (_: any, record: UserInstPermissions) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {record.can_read && (
                            <span>
                                <Tooltip title={t('users.content.instPermissions.can_read.hint')}>
                                    <Tag icon={<ReadOutlined />} color="#52c41a">
                                        {t('users.content.instPermissions.can_read.title')}
                                    </Tag>
                                </Tooltip>
                            </span>
                        )}
                        {record.can_edit && (
                            <span>
                                <Tooltip title={t('users.content.instPermissions.can_edit.hint')}>
                                    <Tag icon={<EditOutlined />} color="#13c2c2">
                                        {t('users.content.instPermissions.can_edit.title')}
                                    </Tag>
                                </Tooltip>
                            </span>
                        )}
                        {record.can_approve && (
                            <span>
                                <Tooltip title={t('users.content.instPermissions.can_approve.hint')}>
                                    <Tag icon={<CrownOutlined />} color="#1677ff">
                                        {t('users.content.instPermissions.can_approve.title')}
                                    </Tag>
                                </Tooltip>
                            </span>
                        )}
                        {!record.can_read && !record.can_edit && !record.can_approve && (
                            <span>
                                <Tooltip title={t('users.content.instPermissions.no_permissions')}>
                                    <Tag>Нет</Tag>
                                </Tooltip>
                            </span>
                        )}
                    </div>
                );
            }
        },
        {
            title: t('users.content.instPermissions.institution_is_deleted'),
            render: (_: any, record: UserInstPermissions) => {
                return (
                    <span>
                        {!record.institution_is_deleted ? (
                            <Badge status="processing" color="primary" text={t('common.yes')} />
                        ) : (
                            <Badge status="error" text={t('common.no')} />
                        )}
                    </span>
                );
            }
        },
        {
            title: t('users.content.instPermissions.permission_is_deleted'),
            render: (_: any, record: UserInstPermissions) => {
                return (
                    <span>
                        {!record.permission_is_deleted ? (
                            <Badge status="processing" color="primary" text={t('common.yes')} />
                        ) : (
                            <Badge status="error" text={t('common.no')} />
                        )}
                    </span>
                );
            }
        },
        {
            width: '4%',
            dataIndex: 'operations',
            render: (_: any, record: UserInstPermissions) => {
                return (
                    <Tooltip title={t('common.edit')}>
                        <Button onClick={() => onEditPermissions(record)}>
                            <EditOutlined />
                        </Button>
                    </Tooltip>
                );
            }
        }
    ];

    const instPermissionsRequestColumns = [
        {
            title: t('users.content.instPermissions.institution_name'),
            render: (_: any, record: UserInstPermissionsRequest) => {
                return (
                    <div>
                        {record.institution_short_name && (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography.Text>{record.institution_short_name}</Typography.Text>
                                <Typography.Text type="secondary">{record.institution_name}</Typography.Text>
                            </div>
                        )}
                        {!record.institution_short_name && <Typography.Text>{record.institution_name}</Typography.Text>}
                    </div>
                );
            }
        },
        {
            title: t('users.content.instPermissionsRequest.status'),
            render: (_: any, record: UserInstPermissionsRequest) => {
                const statusColors: any = {
                    1: '#faad14',
                    2: '#52c41a',
                    3: '#f5222d'
                };
                return <Tag color={statusColors[record.status]}>{record.status_desc}</Tag>;
            }
        },
        {
            title: t('users.content.instPermissionsRequest.request_type'),
            render: (_: any, record: UserInstPermissionsRequest) => {
                const requestTypes: any = {
                    1: { icon: <ReadOutlined />, color: '#52c41a', alt_name: 'can_read' },
                    2: { icon: <EditOutlined />, color: '#13c2c2', alt_name: 'can_edit' },
                    3: { icon: <CrownOutlined />, color: '#1677ff', alt_name: 'can_approve' }
                };
                return (
                    <span>
                        <Tooltip title={t(`users.content.instPermissions.${requestTypes[record.request_type].alt_name}.hint`)}>
                            <Tag icon={requestTypes[record.request_type].icon} color={requestTypes[record.request_type].color}>
                                {t(`users.content.instPermissions.${requestTypes[record.request_type].alt_name}.title`)}
                            </Tag>
                        </Tooltip>
                    </span>
                );
            }
        },
        {
            title: t('users.content.instPermissionsRequest.request_created_at'),
            render: (_: any, record: UserInstPermissionsRequest) => {
                return <span>{beautifyDate(record.request_created_at)}</span>;
            }
        },
        {
            title: t('users.content.instPermissions.institution_is_deleted'),
            render: (_: any, record: UserInstPermissionsRequest) => {
                return (
                    <span>
                        {!record.institution_is_deleted ? (
                            <Badge status="processing" color="primary" text={t('common.yes')} />
                        ) : (
                            <Badge status="error" text={t('common.no')} />
                        )}
                    </span>
                );
            }
        },
        {
            title: t('users.content.instPermissionsRequest.request_is_deleted'),
            render: (_: any, record: UserInstPermissionsRequest) => {
                return (
                    <span>
                        {!record.request_is_deleted ? (
                            <Badge status="processing" color="primary" text={t('common.yes')} />
                        ) : (
                            <Badge status="error" text={t('common.no')} />
                        )}
                    </span>
                );
            }
        },
        {
            title: '',
            width: '7%',
            dataIndex: 'operations',
            render: (_: any, record: UserInstPermissionsRequest) => {
                if (!record.institution_is_deleted && !record.request_is_deleted) {
                    return (
                        <Space direction="horizontal" size="small" align="center">
                            {(record.status === 1 || record.status === 3) && (
                                <Tooltip title={t('users.content.instPermissionsRequest.accept')}>
                                    <Button
                                        style={{ backgroundColor: '#52c41a', color: 'white' }}
                                        onClick={() => acceptRejectInstPermissionsRequest(record.user_inst_id, true)}
                                    >
                                        <CheckOutlined />
                                    </Button>
                                </Tooltip>
                            )}
                            {(record.status === 1 || record.status === 2) && (
                                <Tooltip title={t('users.content.instPermissionsRequest.reject')}>
                                    <Button
                                        style={{ backgroundColor: '#820014', color: 'white' }}
                                        onClick={() => acceptRejectInstPermissionsRequest(record.user_inst_id, false)}
                                    >
                                        <CloseOutlined />
                                    </Button>
                                </Tooltip>
                            )}
                        </Space>
                    );
                }
            }
        }
    ];

    const userPermissionsColumns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: t('users.content.permissions.name'),
            dataIndex: 'name'
        }
    ];

    const editUser = () => {
        return (
            <div>
                {contextHolder}
                <br />
                <Spin spinning={viewModel.isLoading}>
                    {viewModel.user && viewModel.user.is_active === false && (
                        <div>
                            <Alert
                                message={t('users.content.active.locked')}
                                description={t('users.content.active.locked_description')}
                                type="warning"
                                showIcon
                            />
                            <br />
                        </div>
                    )}

                    <Row gutter={16}>
                        <Col span={12}>
                            <div className="edit-content-view">
                                <Form
                                    form={form}
                                    initialValues={viewModel.user}
                                    layout="horizontal"
                                    onValuesChange={onFormValueChange}
                                    onFinish={onSuccessFormValidation}
                                    onFinishFailed={onErrorFormValidation}
                                >
                                    <Form.Item>
                                        <Typography.Text>ID: </Typography.Text>
                                        <Typography.Text strong copyable>
                                            {viewModel.user?.id || ''}
                                        </Typography.Text>
                                    </Form.Item>

                                    <Row gutter={12}>
                                        <Col span={10}>
                                            <Form.Item name="email" label={t('members.content.email')}>
                                                <Input disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item name="is_email_verify" label={t('members.content.verified')} valuePropName="checked">
                                                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Form.Item
                                        name="last_name"
                                        label={t('common.last_name')}
                                        rules={[{ required: true, message: t('users.content.last_name.required_error_text') || '' }]}
                                    >
                                        <Input placeholder={t('users.content.last_name.placeholder') || ''} />
                                    </Form.Item>

                                    <Form.Item
                                        name="first_name"
                                        label={t('common.first_name')}
                                        rules={[{ required: true, message: t('users.content.first_name.required_error_text') || '' }]}
                                    >
                                        <Input placeholder={t('users.content.first_name.placeholder') || ''} />
                                    </Form.Item>

                                    <Form.Item
                                        name="middle_name"
                                        label={t('common.middle_name')}
                                        rules={[{ required: false, message: t('users.content.middle_name.required_error_text') || '' }]}
                                    >
                                        <Input placeholder={t('users.content.middle_name.placeholder') || ''} />
                                    </Form.Item>

                                    <Form.Item
                                        name={'gender'}
                                        label={t('users.content.gender.title')}
                                        rules={[{ required: true, message: t('users.content.gender.required') || '' }]}
                                    >
                                        <Segmented options={getGenderSelectorContent()} />
                                    </Form.Item>

                                    <Form.Item name={'role_id'} label={t('users.content.role.title')}>
                                        <Select
                                            options={viewModel.rolesDict}
                                            defaultValue={viewModel.user?.role_id}
                                            fieldNames={{ label: 'name', value: 'id' }}
                                            style={{ width: '400px' }}
                                        />
                                    </Form.Item>

                                    <Row gutter={12}>
                                        <Col span={8}>
                                            <Form.Item name="phone" label={t('members.content.phone')}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item name="is_phone_verify" label={t('members.content.verified')} valuePropName="checked">
                                                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Divider orientation="left">{t('users.edit.security.visibilitySettings')}</Divider>

                                    <Form.Item name={'show_member'} label={t('users.edit.security.security_inst_member')}>
                                        <Switch
                                            checkedChildren={t('common.yes')}
                                            unCheckedChildren={t('common.no')}
                                            checked={viewModel.user?.security_inst_member}
                                            onChange={() => (viewModel.user.security_inst_member = !viewModel.user.security_inst_member)}
                                        />
                                    </Form.Item>

                                    <Form.Item name={'show_activity'} label={t('users.edit.security.security_activity')}>
                                        <Switch
                                            checkedChildren={t('common.yes')}
                                            unCheckedChildren={t('common.no')}
                                            checked={viewModel.user?.security_activity}
                                            onChange={() => (viewModel.user.security_activity = !viewModel.user.security_activity)}
                                        />
                                    </Form.Item>
                                </Form>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="edit-content-view">
                                {viewModel.userMember && (
                                    <div>
                                        <Row gutter={12} justify="space-between" align="middle">
                                            <Col>
                                                <Typography.Title style={{ padding: 10, margin: 0 }} level={4}>
                                                    {t('users.content.members.title')}
                                                </Typography.Title>
                                            </Col>
                                            <Col>
                                                <Tooltip title={t('users.content.members.navigate.hint')}>
                                                    <Button
                                                        onClick={() =>
                                                            navigate({
                                                                pathname: '/members-manage',
                                                                search: createSearchParams({
                                                                    edit: `${viewModel.userMember.id}`
                                                                }).toString()
                                                            })
                                                        }
                                                    >
                                                        {t('users.content.members.navigate.title')}
                                                        <ArrowRightOutlined />
                                                    </Button>
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                        <br />

                                        <InfiniteScroll
                                            dataLength={userMembersRawColumnsCards.length}
                                            next={() => ''}
                                            hasMore={userMembersRawColumnsCards.length < 50}
                                            loader={''}
                                            scrollableTarget="scrollableDiv"
                                            className="custom_scroll"
                                        >
                                            <List
                                                style={{ padding: 10, height: '547px' }}
                                                dataSource={userMembersRawColumnsCards}
                                                grid={{ gutter: 16, xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}
                                                renderItem={(memberCol) => (
                                                    <List.Item>
                                                        <Collapse
                                                            items={[
                                                                {
                                                                    key: memberCol.dataIndex,
                                                                    label: memberCol.title,
                                                                    children: (
                                                                        <Row align="middle">
                                                                            <Col
                                                                                span={12}
                                                                                style={{ display: 'flex', justifyContent: 'space-around' }}
                                                                            >
                                                                                <span>
                                                                                    {viewModel.userMembersRaw.map((raw_item, i) => (
                                                                                        <div
                                                                                            className="userMemberCard"
                                                                                            key={`raw_item_${memberCol.dataIndex}_${i}`}
                                                                                        >
                                                                                            <span className="userMemberCardTitle">
                                                                                                {t('users.content.members.operator_name')}:
                                                                                                <a
                                                                                                    href={raw_item.operator_site}
                                                                                                    target="_blank"
                                                                                                    rel="noreferrer"
                                                                                                >
                                                                                                    {` ${raw_item.operator_name}`}
                                                                                                </a>
                                                                                            </span>
                                                                                            <span className="userMemberCardTitle">
                                                                                                API: {raw_item.operator_api_name}
                                                                                            </span>
                                                                                            <span className="userMemberCardValue">
                                                                                                {memberCol.type === 'boolean' ? (
                                                                                                    <span>
                                                                                                        {raw_item[
                                                                                                            memberCol.dataIndex as keyof UserMemberRaw
                                                                                                        ] ? (
                                                                                                            <Badge
                                                                                                                status="processing"
                                                                                                                color="primary"
                                                                                                                text={t('common.yes')}
                                                                                                            />
                                                                                                        ) : (
                                                                                                            <Badge
                                                                                                                status="error"
                                                                                                                text={t('common.no')}
                                                                                                            />
                                                                                                        )}
                                                                                                    </span>
                                                                                                ) : (
                                                                                                    <span>
                                                                                                        {
                                                                                                            raw_item[
                                                                                                                memberCol.dataIndex as keyof UserMemberRaw
                                                                                                            ]
                                                                                                        }
                                                                                                    </span>
                                                                                                )}
                                                                                            </span>
                                                                                        </div>
                                                                                    ))}
                                                                                </span>
                                                                            </Col>
                                                                            <Col
                                                                                span={12}
                                                                                style={{ display: 'flex', justifyContent: 'space-around' }}
                                                                            >
                                                                                <div className="userMemberCard">
                                                                                    <span className="userMemberCardTitle">Результат</span>
                                                                                    <span className="userMemberCardValue">
                                                                                        {memberCol.type === 'boolean' ? (
                                                                                            <span>
                                                                                                {viewModel.userMember[
                                                                                                    memberCol.dataIndex as keyof UserMember
                                                                                                ] ? (
                                                                                                    <Badge
                                                                                                        status="processing"
                                                                                                        color="primary"
                                                                                                        text={t('common.yes')}
                                                                                                    />
                                                                                                ) : (
                                                                                                    <Badge status="error" text={t('common.no')} />
                                                                                                )}
                                                                                            </span>
                                                                                        ) : (
                                                                                            <span>
                                                                                                {
                                                                                                    viewModel.userMember[
                                                                                                        memberCol.dataIndex as keyof UserMember
                                                                                                    ]
                                                                                                }
                                                                                            </span>
                                                                                        )}
                                                                                    </span>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                }
                                                            ]}
                                                        />
                                                    </List.Item>
                                                )}
                                            />
                                        </InfiniteScroll>
                                    </div>
                                )}
                                {!viewModel.userMember && (
                                    <Typography.Title level={4} style={{ margin: 0 }}>
                                        {t('users.content.members.no_member')}
                                    </Typography.Title>
                                )}
                            </div>
                        </Col>
                    </Row>

                    <Divider>
                        <WarningOutlined className="users-edit-danger-divider-icon" />
                        <Typography.Text className="users-edit-danger-divider-text"> {t('common.danger')}</Typography.Text>
                    </Divider>
                    <div className="edit-content-view">
                        <Space direction="horizontal" size="large" align="center">
                            <Popconfirm
                                title={t('users.content.reset_pwd.confirm')}
                                okText={t('users.content.reset_pwd.reset')}
                                cancelText={t('common.cancel')}
                                onConfirm={() => viewModel.resetUserPassword()}
                            >
                                <Button icon={<KeyOutlined />}>{t('users.content.reset_pwd.title')}</Button>
                            </Popconfirm>
                            <Popconfirm
                                title={t('users.content.active.confirm')}
                                okText={viewModel.user.is_active === false ? t('users.content.active.unlock') : t('users.content.active.lock')}
                                cancelText={t('common.cancel')}
                                onConfirm={() => viewModel.toggleUserActiveStatus()}
                            >
                                <Button className="users-edit-delete-account-button" icon={<UnlockOutlined />}>
                                    {viewModel.user.is_active === false ? t('users.content.active.unlock') : t('users.content.active.lock')}
                                </Button>
                            </Popconfirm>
                        </Space>
                    </div>
                </Spin>
                <br />
            </div>
        );
    };

    const userOrg = () => {
        return (
            <div>
                <br />
                <Spin spinning={viewModel.isLoading}>
                    <div className="edit-content-view">
                        <Form
                            form={formOrg}
                            initialValues={viewModel.user}
                            layout="horizontal"
                            onValuesChange={onOrgValueChange}
                            onFinish={onSuccessFormValidation}
                            onFinishFailed={onErrorFormValidation}
                        >
                            <Form.Item name="inst_id" label={t('users.content.inst.title')}>
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder={t('users.content.inst.placeholder')}
                                    onSearch={onSearchInst}
                                    loading={viewModel.loadingOrg}
                                    filterOption={false}
                                    defaultValue={viewModel.user?.inst_id}
                                >
                                    {viewModel.inst.map((item, i) => (
                                        <Select.Option value={item.id} key={`inst_${i}`}>
                                            <Space direction="vertical">
                                                <Typography.Text>{item.short_name ? item.short_name : item.name}</Typography.Text>
                                                <Typography.Text type="secondary">{item.post}</Typography.Text>
                                            </Space>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item name="org_dadata_id" label={t('users.content.orgs.title')}>
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder={t('users.content.orgs.placeholder')}
                                    onSearch={onSearchOrg}
                                    loading={viewModel.loadingOrg}
                                    filterOption={false}
                                    defaultValue={viewModel.user?.org_dadata_id}
                                >
                                    {viewModel.orgs.map((org, i) => (
                                        <Select.Option value={org.dadata_id} key={`org_${i}`}>
                                            <Space direction="vertical">
                                                <Typography.Text>{org.short_name ? org.short_name : org.full_name}</Typography.Text>
                                                <Typography.Text type="secondary">{org.address}</Typography.Text>
                                            </Space>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item name="org_file" label={t('users.content.orgs.file')}>
                                <FilesCatalogView
                                    name="file"
                                    uploadURL={viewModel.getUploaderURL()}
                                    getHeaders={viewModel.getUploaderHeaders()}
                                    downloadLink={(uid: string) => viewModel.getDownloadUrl(uid)}
                                    onRemove={(uid: string) => viewModel.deleteFile(uid)}
                                    onEditTitle={(uid: string, title: string) => viewModel.onEditFileTitle(uid, title)}
                                    defaultFileList={(viewModel.userFiles || []).map((item) => {
                                        return {
                                            uid: `${item.uuid}`,
                                            name: item.name,
                                            title: item.title,
                                            size: item.size,
                                            lastModifiedDate: stringToDate(item.created_at),
                                            downloads: item.downloads
                                        };
                                    })}
                                />
                            </Form.Item>
                        </Form>
                    </div>

                    <Divider orientation="left">
                        <Typography.Text style={{ marginRight: '6px' }}>{t('users.content.instPermissions.title')}</Typography.Text>
                        <Tooltip title={t('users.content.instPermissions.hint')}>
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </Divider>

                    <div className="edit-content-view">
                        <Table
                            size="small"
                            loading={viewModel.isLoading}
                            bordered
                            dataSource={viewModel.userInstPermissions}
                            columns={instPermissionsColumns}
                            rowClassName="row"
                            rowKey={'id'}
                            pagination={false}
                        />
                    </div>

                    <Divider orientation="left">{t('users.content.instPermissionsRequest.title')}</Divider>

                    <div className="edit-content-view">
                        <Table
                            size="small"
                            loading={viewModel.isLoading}
                            bordered
                            dataSource={viewModel.userInstPermissionsRequest}
                            columns={instPermissionsRequestColumns}
                            rowClassName="row"
                            rowKey={'id'}
                            pagination={false}
                        />
                    </div>
                    <br />
                </Spin>
            </div>
        );
    };

    const userSecure = () => {
        return (
            <div>
                <br />
                <Spin spinning={viewModel.isLoading}>
                    <div className="edit-content-view">
                        <Form
                            form={formSecure}
                            initialValues={viewModel.user}
                            layout="horizontal"
                            onValuesChange={onSecureValueChange}
                            onFinish={onSuccessFormValidation}
                            onFinishFailed={onErrorFormValidation}
                        >
                            <Form.Item name="snils" label={t('members.content.snils')}>
                                <Input placeholder={t('members.content.snils') || ''} />
                            </Form.Item>
                            <Form.Item name="inn" label={t('common.inn')}>
                                <Input placeholder={t('common.inn') || ''} />
                            </Form.Item>
                        </Form>
                    </div>
                </Spin>
            </div>
        );
    };

    const userActivity = () => {
        return (
            <div>
                <br />
                <Spin spinning={viewModel.isLoading}>
                    <div className="edit-content-view">
                        {/* TODO: фильтр по году, изменение limit */}
                        <Table
                            size="small"
                            loading={viewModel.isLoading}
                            bordered
                            dataSource={viewModel.userActivity}
                            columns={activityColumns}
                            rowClassName="row"
                            rowKey={'id'}
                            pagination={{
                                current: viewModel.userActivityCurrentPage,
                                pageSize: viewModel.userActivityPageSize,
                                total: viewModel.userActivityTotal,
                                position: ['bottomCenter'],
                                onChange: onChangeActivePage
                            }}
                        />
                    </div>
                </Spin>
            </div>
        );
    };

    const userPermissions = () => {
        return (
            <div>
                <br />
                <Spin spinning={viewModel.isLoading}>
                    <div className="edit-content-view">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography.Title level={4} style={{ margin: 0 }}>
                                {viewModel.userPermissions.length
                                    ? t('users.content.permissions.title_true')
                                    : t('users.content.permissions.title_false')}
                            </Typography.Title>

                            <Button type="primary" icon={<EditOutlined />} onClick={() => setModalEditUserPermissions(true)}>
                                {t('common.edit')}
                            </Button>
                        </div>
                        <br />
                        <Table
                            size="small"
                            loading={viewModel.isLoading}
                            bordered
                            dataSource={viewModel.userPermissions}
                            columns={userPermissionsColumns}
                            rowClassName="row"
                            rowKey={'id'}
                            pagination={false}
                        />
                    </div>
                </Spin>
            </div>
        );
    };

    return (
        <div>
            {contextHolder}
            <br />
            <Tabs
                tabBarExtraContent={{
                    right: (
                        <Row gutter={12} justify="end" align="middle">
                            <Col>
                                <Button onClick={onClickCancel}>{t('common.cancel')}</Button>
                            </Col>
                            {['data', 'security', 'org'].includes(activeTab) && (
                                <Col>
                                    <Button type="primary" onClick={onClickSave} loading={viewModel.isLoading} disabled={viewModel.isLoading}>
                                        {t('common.save')}
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    )
                }}
                onChange={onTabChange}
            >
                <TabPane tab={t('users.edit.data.title')} key={'data'}>
                    {editUser()}
                </TabPane>

                <TabPane tab={t('users.edit.security.title')} key={'security'}>
                    {userSecure()}
                </TabPane>

                <TabPane tab={t('users.edit.org.title')} key={'org'}>
                    {userOrg()}
                </TabPane>

                <TabPane tab={t('users.edit.activity.title')} key={'activity'}>
                    {userActivity()}
                </TabPane>

                <TabPane tab={t('users.edit.permissions.title')} key={'permissions'}>
                    {userPermissions()}
                </TabPane>

                <TabPane tab={t('users.edit.history.title')} key={'history'}>
                    {
                        <HistoryView
                            isLoading={viewModel.isHistoryLoading}
                            items={viewModel.historyItems}
                            itemsTotal={viewModel.historyTotal}
                            currentItem={viewModel.historyCurrentItem}
                            loadNextItems={() => viewModel.fetchNextHistoryItemsPage()}
                            loadHistoryItem={(historyId: number) => viewModel.onSelectHistoryItem(historyId)}
                        />
                    }
                </TabPane>
            </Tabs>

            {/* INST PERMISSIONS EDIT */}
            <Modal
                title={t('users.content.instPermissions.edit.title')}
                open={modalEditInstPermissions}
                onCancel={() => setModalEditInstPermissions(false)}
                width={800}
                footer={[
                    <Button key="back" onClick={() => setModalEditInstPermissions(false)}>
                        {t('common.cancel')}
                    </Button>,
                    <Button key="submit" type="primary" onClick={editUserInstPermissions}>
                        {t('common.save')}
                    </Button>
                ]}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ marginBottom: '10px' }}>
                        <UserOutlined style={{ marginRight: '16px' }} />
                        <Typography.Text>
                            {viewModel.user?.last_name} {viewModel.user?.first_name} {viewModel.user?.middle_name}
                        </Typography.Text>
                    </span>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                        <HomeOutlined style={{ marginRight: '16px' }} />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography.Text>{viewModel.editedInstPermission.institution_short_name}</Typography.Text>
                            <Typography.Text type="secondary">{viewModel.editedInstPermission.institution_name}</Typography.Text>
                        </div>
                    </div>

                    <Checkbox
                        checked={viewModel.editedInstPermission.can_read}
                        onChange={() => (viewModel.editedInstPermission.can_read = !viewModel.editedInstPermission.can_read)}
                        style={{ marginBottom: '10px' }}
                    >
                        <Tooltip title={t('users.content.instPermissions.can_read.hint')}>
                            <Tag icon={<ReadOutlined />} color="#52c41a">
                                {t('users.content.instPermissions.can_read.title')}
                            </Tag>
                        </Tooltip>
                    </Checkbox>

                    <Checkbox
                        checked={viewModel.editedInstPermission.can_edit}
                        onChange={() => (viewModel.editedInstPermission.can_edit = !viewModel.editedInstPermission.can_edit)}
                        style={{ marginBottom: '10px' }}
                    >
                        <Tooltip title={t('users.content.instPermissions.can_edit.hint')}>
                            <Tag icon={<EditOutlined />} color="#13c2c2">
                                {t('users.content.instPermissions.can_edit.title')}
                            </Tag>
                        </Tooltip>
                    </Checkbox>

                    <Checkbox
                        checked={viewModel.editedInstPermission.can_approve}
                        onChange={() => (viewModel.editedInstPermission.can_approve = !viewModel.editedInstPermission.can_approve)}
                        style={{ marginBottom: '10px' }}
                    >
                        <Tooltip title={t('users.content.instPermissions.can_approve.hint')}>
                            <Tag icon={<CrownOutlined />} color="#1677ff">
                                {t('users.content.instPermissions.can_approve.title')}
                            </Tag>
                        </Tooltip>
                    </Checkbox>

                    <Divider />

                    <span>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Switch
                                checkedChildren={t('common.yes')}
                                unCheckedChildren={t('common.no')}
                                checked={!viewModel.editedInstPermission.permission_is_deleted}
                                onChange={() =>
                                    (viewModel.editedInstPermission.permission_is_deleted = !viewModel.editedInstPermission.permission_is_deleted)
                                }
                                style={{ marginRight: '8px' }}
                            />
                            <span>{t('users.content.instPermissions.permission_is_deleted')}</span>
                        </div>
                    </span>
                </div>
            </Modal>

            {/* PERMISSIONS EDIT */}
            <Modal
                title={t('users.content.instPermissions.edit.title')}
                open={modalEditUserPermissions}
                onCancel={() => setModalEditUserPermissions(false)}
                width={800}
                footer={[
                    <Button key="back" onClick={() => setModalEditUserPermissions(false)}>
                        {t('common.cancel')}
                    </Button>,
                    <Button key="submit" type="primary" onClick={editUserPermissions}>
                        {t('common.save')}
                    </Button>
                ]}
            >
                <br />
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Select
                        options={viewModel.userPermissionsDict}
                        mode="multiple"
                        defaultValue={viewModel.userPermissions.map((i) => i.id)}
                        fieldNames={{ label: 'name', value: 'id' }}
                        style={{ width: '400px' }}
                        onChange={handleChangeUserPermissions}
                    />
                </div>
                <br />
            </Modal>
        </div>
    );
});

export default UserEditView;
