import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Divider, Form, Input, Skeleton, Tag, Typography, notification } from 'antd';
import { EditableTable } from './table';
import { useViewModel } from '@models/model';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { RidsViewModel } from './rids.view.model';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { RidData } from '@models/rid-model';
import { ObjectLiteral } from '@utils/object.utils';

const RidsView: React.FC = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [search, setSearch] = useSearchParams();
    const setSearchParams = (params: any) => setSearch(params);

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };
    const viewModel = useViewModel(() => new RidsViewModel({ t, searchParams: search, setSearchParams, showError: showErrorNotification }));
    const [filterForm] = Form.useForm();

    useEffect(() => {
        updateIfNeeded();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const updateIfNeeded = async () => {
        const isResetNeeded = await viewModel.updateSearchParamsIfNeeded(search);
        if (isResetNeeded) {
            filterForm.resetFields();
        }
    };

    const onEdit = (ridId?: number | undefined) => {
        if (!ridId) return;
        window.open(`/rids-manage/edit/${ridId || ''}`, '_blank', 'noreferrer');
    };

    const onShowMembers = (rid: RidData) => {
        if (!rid.id) return;
        navigate({
            pathname: '/members-manage',
            search: createSearchParams({
                rid_id: `${rid.id}`
            }).toString()
        });
    };

    const onShowStartup = (rid: RidData) => {
        if (!rid.id) return;

        navigate({
            pathname: '/startups-manage',
            search: createSearchParams({
                rid_id: `${rid.id}`
            }).toString()
        });
    };

    const onFilterValueChange = () => {
        const valFromFields: ObjectLiteral = filterForm.getFieldsValue();
        viewModel.onFilterValueChange(valFromFields);
    };

    const filters = () => {
        const infoCount = viewModel.filterInfoCount(viewModel.currentFilter);
        const infoFake = Array(infoCount).fill(0);
        if (infoCount > 0) {
            return (
                <Space style={{ width: '100%', marginTop: '10px' }} direction="horizontal" size="small">
                    {viewModel.isCurrentFilterLoading === true
                        ? infoFake.map((_, index) => <Skeleton.Input key={`skeleton-${index}`} active={true} size="small" />)
                        : viewModel.currentFilterInfo.map((infoItem, index) => (
                              <Tag
                                  key={`tag-${index}`}
                                  closable
                                  onClose={() => {
                                      viewModel.onRemoveFilterInfo(infoItem);
                                  }}
                                  color="purple"
                              >
                                  <Space>
                                      <FilterOutlined />
                                      <Typography.Text strong>
                                          {infoItem.name}: {infoItem.value}
                                      </Typography.Text>
                                  </Space>
                              </Tag>
                          ))}
                </Space>
            );
        } else {
            return <div />;
        }
    };

    return (
        <div>
            {contextHolder}
            <Space>
                <Typography.Title>{t('rids.title')}</Typography.Title>
                <Typography.Title style={{ marginTop: 25 }} level={2} type="secondary">
                    {viewModel.ridsTotal}
                </Typography.Title>
            </Space>

            <Divider style={{ marginTop: 0, marginBottom: 0 }} />
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                {filters()}

                <Form form={filterForm} initialValues={viewModel.currentFilter} layout="vertical" onValuesChange={onFilterValueChange}>
                    <Form.Item style={{ margin: 0 }} name={'search'}>
                        <Input prefix={<SearchOutlined />} size="large" allowClear placeholder={t('rids.filter.search.placeholder') || ''} />
                    </Form.Item>
                </Form>

                <EditableTable
                    currentPage={viewModel.currentPage}
                    loading={viewModel.isLoading}
                    rows={viewModel.rids}
                    total={viewModel.ridsTotal}
                    pageSize={viewModel.pageSize}
                    onChangePagination={viewModel.onChangePagination}
                    onEdit={onEdit}
                    onShowMembers={onShowMembers}
                    onShowStartup={onShowStartup}
                />
            </Space>
        </div>
    );
});

export default RidsView;
