import { DictionaryModel, DictionarySchemeColumn } from '@models/dictionary-model';
import { APIUtilError } from '@utils/api';
import { ObjectLiteral } from '@utils/object.utils';
import { makeAutoObservable, runInAction } from 'mobx';

export interface Props {
    altname: string | undefined;
    primaryKey: string;
    data: ObjectLiteral;
    schemeColumns: DictionarySchemeColumn[];
    onClose: () => void;
    onCreate: (data: ObjectLiteral) => void;
    onUpdate: (id: string, data: ObjectLiteral) => void;
    t: any;
    showError: (title: string, description: string) => void;
}

export class DictionaryEditViewModel {
    private dictionaryModel = new DictionaryModel();
    public loading: boolean = false;
    public data: ObjectLiteral = {};
    public isNew = true;

    public dictionaries: ObjectLiteral[] = [];
    public isLoadingDictionary = false;

    constructor(private props: Props) {
        this.isNew = !!!props.primaryKey;
        this.data = props.data;

        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        this.fillDictionaries();
    }

    // Заполняем дефолтными значениями
    private fillDictionaries() {
        const columnsWithDict = this.props.schemeColumns.filter((column) => column.is_dictionary && column.dictionary_altname);
        columnsWithDict.map((column) => {
            if (this.data[`${column.key}_name`] && column.dictionary_altname !== undefined) {
                runInAction(() => {
                    this.dictionaries.push({
                        [column.key]: this.data[column.key],
                        name: this.data[`${column.key}_name`],
                        altname: column.dictionary_altname
                    });
                });
            }
        });
    }

    public close = () => {
        runInAction(() => {
            this.data = {};
            this.isNew = true;
            this.dictionaries = [];
        });

        this.props.onClose();
    };

    public save = async (data: ObjectLiteral) => {
        if (this.isNew) {
            await this.create({ ...data });
        } else {
            await this.update({ ...data });
        }
    };

    private create = async (data: ObjectLiteral) => {
        if (!this.props.altname) return;
        runInAction(() => {
            this.loading = true;
        });
        try {
            await this.dictionaryModel.createDictionaryItem(this.props.altname, data);
            this.props.onCreate(data);
            this.close();
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    private update = async (data: ObjectLiteral) => {
        if (!this.props.altname) return;
        runInAction(() => {
            this.loading = true;
        });

        try {
            const updated = await this.dictionaryModel.updateDictionaryItem(this.props.altname, this.data[this.props.primaryKey], data);
            this.props.onUpdate(this.data[this.props.primaryKey], { ...this.data[this.props.primaryKey], ...updated.data });
            this.close();
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    public async searchDictionary(key: string, altname: string, query: string) {
        runInAction(() => {
            this.isLoadingDictionary = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: altname, search: query, page: 1, page_size: 50 });
            const data = [
                ...this.dictionaries.slice().filter((i) => i.altname !== altname),
                ...result.data.map((i) => {
                    return { ...i, [key]: i.id, altname: altname };
                })
            ];
            runInAction(() => {
                this.dictionaries = data;
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoadingDictionary = false;
            });
        }
    }
}
