export class NoticeTemplateData {
    id: number;
    message: string;
    alt_name: string;
    name: string;
    variables?: NoticeTemplateVariable[] | undefined;
}

export class NoticeTemplateVariable {
    id: number;
    is_required: boolean;
    template_alt_name: string;
    key: string;
    description?: string | null;
}

export class GetNoticeTemplatesResponse {
    data: NoticeTemplateData[];
    total: number;
}

export class NoticeTemplateResponse {
    data: NoticeTemplateData;
}
