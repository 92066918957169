import { useViewModel } from '@models/model';
import { Col, Divider, Empty, Form, Row, Select, Space, Typography, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { FootprintUsersViewModel } from './digital-footprint-users.view.model';
import { useEffect } from 'react';
import UsersTable from './table';
import { DigitalFootprintUserData } from '@models/digital-footprint/users';
import { observer } from 'mobx-react-lite';
import { FilterOutlined } from '@ant-design/icons';
import { ObjectLiteral } from '@utils/object.utils';

const DigitalFootprintUsersView: React.FC = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [search, setSearch] = useSearchParams();
    const setSearchParams = (params: any) => setSearch(params);

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    let viewModel = useViewModel(() => new FootprintUsersViewModel({ t, searchParams: search, setSearchParams, showError: showErrorNotification }));
    const [filterForm] = Form.useForm();

    useEffect(() => {
        updateIfNeeded();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const updateIfNeeded = async () => {
        const isResetNeeded = await viewModel.updateSearchParamsIfNeeded(search);
        if (isResetNeeded) {
            filterForm.resetFields();
        }
    };

    const showEvent = (id: number) => {
        navigate({
            pathname: `/events-manage/edit/${id}`
        });
    };
    const showMember = (id: number) => {
        navigate({
            pathname: '/members-manage',
            search: createSearchParams({
                edit: `${id}`
            }).toString()
        });
    };
    const showUser = (id: string) => {
        navigate({
            pathname: `/users-manage/edit/${id}`
        });
    };

    const onFilterValueChange = () => {
        const valFromFields: ObjectLiteral = filterForm.getFieldsValue();
        viewModel.onFilterValueChange(valFromFields);
    };

    const filters = () => {
        return (
            <div>
                <Form form={filterForm} initialValues={viewModel.currentFilter} layout="vertical" onValuesChange={onFilterValueChange}>
                    <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <Form.Item
                                name="event_id"
                                label={
                                    <div>
                                        <FilterOutlined style={{ fontSize: 16, color: 'gray', marginRight: 5 }} />
                                        <Typography.Text strong>{t('digital_footprint.filter.event.title')}</Typography.Text>
                                    </div>
                                }
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    notFoundContent={<Empty description={''} />}
                                    placeholder={t('digital_footprint.filter.event.placeholder')}
                                    onSearch={(value: string) => viewModel.searchEvents(value)}
                                    loading={viewModel.isEventsLoading}
                                    filterOption={false}
                                >
                                    {viewModel.events.map((item, i) => (
                                        <Select.Option value={item.id} key={`org_${i}`}>
                                            <Typography.Text>{item.name}</Typography.Text>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="member_id"
                                label={
                                    <div>
                                        <FilterOutlined style={{ fontSize: 16, color: 'gray', marginRight: 5 }} />
                                        <Typography.Text strong>{t('digital_footprint.filter.member.title')}</Typography.Text>
                                    </div>
                                }
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    notFoundContent={<Empty description={''} />}
                                    placeholder={t('digital_footprint.filter.member.placeholder')}
                                    onSearch={(value: string) => viewModel.searchMembers(value)}
                                    loading={viewModel.isMembersLoading}
                                    filterOption={false}
                                >
                                    {viewModel.members.map((item, i) => (
                                        <Select.Option value={item.id} key={`org_${i}`}>
                                            <Typography.Text>
                                                {item.last_name} {item.first_name} {item.middle_name}
                                            </Typography.Text>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="user_id"
                                label={
                                    <div>
                                        <FilterOutlined style={{ fontSize: 16, color: 'gray', marginRight: 5 }} />
                                        <Typography.Text strong>{t('digital_footprint.filter.user.title')}</Typography.Text>
                                    </div>
                                }
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    notFoundContent={<Empty description={''} />}
                                    placeholder={t('digital_footprint.filter.user.placeholder')}
                                    onSearch={(value: string) => viewModel.searchUsers(value)}
                                    loading={viewModel.isUsersLoading}
                                    filterOption={false}
                                >
                                    {viewModel.users.map((item, i) => (
                                        <Select.Option value={item.id} key={`org_${i}`}>
                                            <Typography.Text>
                                                {item.last_name} {item.first_name} {item.middle_name}
                                            </Typography.Text>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    };

    return (
        <div>
            {contextHolder}
            <Row gutter={12} align="middle" justify="space-between">
                <Col>
                    <Space>
                        <Typography.Title>{t('digital_footprint.users.title')}</Typography.Title>
                        <Typography.Title style={{ marginTop: 25 }} level={2} type="secondary">
                            {viewModel.userFootprintsTotal}
                        </Typography.Title>
                    </Space>
                </Col>
            </Row>
            <Divider style={{ marginTop: 0 }} />

            <Row gutter={[8, 8]} justify="center">
                <Col span={24}>{filters()}</Col>
                <Col span={24}>
                    <UsersTable
                        currentPage={viewModel.currentPage}
                        loading={viewModel.isLoading}
                        rows={viewModel.userFootprints}
                        total={viewModel.userFootprintsTotal}
                        pageSize={viewModel.pageSize}
                        onChangePagination={viewModel.onChangePagination}
                        onDelete={(footprint: DigitalFootprintUserData) => viewModel.delete(footprint.id)}
                        onShowEvent={(footprint: DigitalFootprintUserData) => showEvent(footprint.event.id!)}
                        onShowMember={(footprint: DigitalFootprintUserData) => showMember(footprint.member.id)}
                        onShowUser={(footprint: DigitalFootprintUserData) => showUser(footprint.user.id!)}
                    />
                </Col>
            </Row>
        </div>
    );
});

export default DigitalFootprintUsersView;
