import { StartupTypeDictionaryData } from '@models/dictionary-model';

export class StartupData {
    id: number;
    name: string;
    startup_type_id: number;
    startup_direction_id: number;
    startup_direction_ids: number[] = [];

    startup_date: string;
    has_mvp: boolean;

    startup_address: string;
    startup_plan: string;
    idea_description: string;
    target_description: string;

    members_count?: number | undefined;
    finance_count?: number | undefined;
    rid_count?: number | undefined;

    inn: string;
    kpp: string;
    ogrn: string;

    startup_type: StartupTypeDictionaryData;
    region_id?: number;
    trl_id?: number;

    created_at: string;
    updated_at: string;
}

export class GetStartupsResponse {
    data: StartupData[];
    total: number;
    count: number;
}

export class StartupResponse {
    data: StartupData;
}
