import { UsersFilterParamName } from '@interfaces/users.interface';
import { User, UserModel, UsersGetFilter } from '@models/users-model';
import { APIUtilError } from '@utils/api';
import { ObjectLiteral, deleteUndefined, isObjectsHasDifferences } from '@utils/object.utils';
import { debounce } from 'lodash';
import { makeAutoObservable, runInAction, toJS } from 'mobx';

export interface UsersManageViewModelStartParams {
    t: any;
    searchParams: URLSearchParams;
    setSearchParams: (params: any) => void;
    showSuccess: (title: string, description: string) => void;
    showError: (title: string, description: string) => void;
    showUserEdit: (userId: string, onNewTab: boolean) => void;
}

export class UsersManageViewModel {
    private userModel = new UserModel();
    public isLoading: boolean = false;

    public isCreateLoading: boolean = false;

    public availablePageSizes = [10, 20, 50, 100];
    public users: User[] = [];
    public usersTotal: number = 0;

    public currentPage: number = 1;
    public pageSize: number = this.availablePageSizes[1];
    public currentFilter: UsersGetFilter;

    constructor(private startParams: UsersManageViewModelStartParams) {
        const incomingFilter = this.getFilterFromSearchParams(startParams.searchParams);
        this.currentFilter = incomingFilter;
        this.currentPage = incomingFilter.page || 0;
        this.pageSize = incomingFilter.page_size || this.availablePageSizes[1];
        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        await this.fetchUsers(this.currentFilter);
    }

    public async updateSearchParamsIfNeeded(searchParams: URLSearchParams): Promise<Boolean> {
        const newFilter = this.getFilterFromSearchParams(searchParams);
        if (isObjectsHasDifferences(newFilter, toJS(this.currentFilter))) {
            runInAction(() => {
                this.currentPage = newFilter.page || 1;
                this.pageSize = newFilter.page_size || this.availablePageSizes[1];
                this.currentFilter = newFilter;
            });
            await this.fetchUsers(newFilter);
            return true;
        }
        return false;
    }

    private getFilterFromSearchParams = (searchParams: URLSearchParams) => {
        let filter: UsersGetFilter = { page: 1, page_size: this.pageSize };
        const keys: UsersFilterParamName[] = ['search', 'page', 'page_size'];

        for (const key of keys) {
            const value = searchParams.get(key);
            if (!value) continue;
            switch (key) {
                case 'search':
                    filter[key] = value;
                    break;
                case 'page':
                    try {
                        const parsedPage = parseInt(value);
                        filter[key] = parsedPage;
                    } catch {
                        filter[key] = 1;
                    }
                    break;
                case 'page_size':
                    try {
                        const parsedSize = parseInt(value);
                        if (!this.availablePageSizes.includes(parsedSize)) throw new Error();
                        filter.page_size = parsedSize;
                    } catch {
                        filter.page_size = undefined;
                    }
                    break;

                default:
                    break;
            }
        }
        return filter;
    };

    private extractSearchParamsFromFilter = (filter: ObjectLiteral) => {
        let searchParams: ObjectLiteral = {};
        for (const key in filter) {
            switch (key) {
                default:
                    searchParams[key] = filter[key] ? filter[key] : undefined;
            }
        }
        return deleteUndefined(searchParams);
    };

    public onFilterValueChange = (values: ObjectLiteral) => {
        const formsValues = deleteUndefined(values);
        let filter: UsersGetFilter = { page: 1, page_size: this.pageSize };
        for (const key in formsValues) {
            switch (key) {
                case 'search':
                    filter.search = formsValues[key];
                    break;
                default:
                    break;
            }
        }
        const searchParams = this.extractSearchParamsFromFilter(deleteUndefined(filter));
        this.startParams.setSearchParams(searchParams as any);

        runInAction(() => {
            this.currentPage = 1;
            this.currentFilter = deleteUndefined(filter);
        });
        this.debouncedFetch(deleteUndefined(filter));
    };

    private debouncedFetch = debounce(async (params: UsersGetFilter) => {
        this.fetchUsers(params);
    }, 400);

    private async fetchUsers(params: UsersGetFilter) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.userModel.getUsers(params);
            runInAction(() => {
                this.users = result.data;
                this.usersTotal = result.total;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public onChangePagination = async (page: number, pageSize: number) => {
        if (pageSize !== this.pageSize) {
            runInAction(() => {
                this.pageSize = pageSize;
            });
        }
        const filter: UsersGetFilter = { ...this.currentFilter, page: page, page_size: pageSize };
        runInAction(() => {
            this.currentPage = page;
            this.currentFilter = filter;
        });

        const currentFiltersSearchParams = this.extractSearchParamsFromFilter(filter);
        this.startParams.setSearchParams(currentFiltersSearchParams);

        this.fetchUsers(filter);
    };

    public async createUser(email: string) {
        runInAction(() => {
            this.isCreateLoading = true;
        });
        try {
            const result = await this.userModel.createUser(email);

            this.startParams.showSuccess(this.startParams.t('common.saved'), '');
            setTimeout(() => {
                this.startParams.showUserEdit(result.data.id, false);
            }, 1000);
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.empty'), (error as APIUtilError).localizedDescription);
            runInAction(() => {
                this.isCreateLoading = false;
            });
        }
    }
}
