import React, { useState } from 'react';
import { Table, Form, Typography, Tooltip, Button } from 'antd';
import { useEffect } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { NoticeTemplateData } from '@models/notice-template-model';

interface TableProps {
    pageSize: number;
    rows: NoticeTemplateData[];
    total: number;
    loading: boolean;
    onChangePagination: (page: number, pageSize: number) => void;
    onEdit: (item: NoticeTemplateData) => void;
}

export const TemplateTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [data, setData] = useState<NoticeTemplateData[]>([]);

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const onChangePage = (page: number, pageSize: number) => {
        props.onChangePagination(page, pageSize);
    };

    const columns = [
        {
            title: 'ID',
            width: '1%',
            dataIndex: 'id'
        },
        {
            title: t('noticeTemplate.content.name.title'),
            dataIndex: 'name'
        },
        {
            title: t('noticeTemplate.content.message.title'),

            dataIndex: 'operations',
            render: (_: any, record: NoticeTemplateData) => {
                const substringed = record.message.substring(0, 200);
                return (
                    <Typography.Text>
                        {substringed}
                        {record.message.length > 200 ? '...' : ''}
                    </Typography.Text>
                );
            }
        },
        {
            title: 'Alt-name',
            dataIndex: 'alt_name'
        },
        {
            title: '',
            width: '5%',
            dataIndex: 'operations',
            render: (_: any, record: NoticeTemplateData) => {
                return (
                    <Tooltip title={t('common.edit')}>
                        <Button onClick={() => props.onEdit(record)}>
                            <EditOutlined />
                        </Button>
                    </Tooltip>
                );
            }
        }
    ];

    return (
        <Form form={form} component={false}>
            <Table
                size="small"
                loading={props.loading}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="row"
                rowKey={'id'}
                pagination={{
                    pageSize: props.pageSize,
                    total: props.total,
                    position: ['bottomCenter'],
                    onChange: onChangePage
                }}
            />
        </Form>
    );
};
