import { useState } from 'react';
import { Table, Form, Tooltip, Button, Badge, Typography } from 'antd';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { User } from '@models/users-model';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface TableProps {
    currentPage: number;
    pageSize: number;
    rows: User[];
    total: number;
    loading: boolean;
    onChangePagination: (page: number, pageSize: number) => void;
    onEdit: (item: User) => void;
}

export const UsersTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [data, setData] = useState<User[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const onChangePage = (page: number, pageSize: number) => {
        props.onChangePagination(page, pageSize);
    };

    const onShowMember = (member_id?: number) => {
        if (!member_id) return;
        navigate({
            pathname: '/members-manage',
            search: createSearchParams({
                edit: `${member_id}`
            }).toString()
        });
    };

    const makeFIO = (last_name: string, first_name: string, middle_name: string) => {
        const fio = `${last_name || ''} ${first_name || ''} ${middle_name || ''}`;
        if (fio.length === 2) {
            return '-';
        } else {
            return fio;
        }
    };

    const columns = [
        {
            title: t('common.fio'),
            render: (_: any, record: User) => {
                return <Typography.Text>{makeFIO(record.last_name, record.first_name, record.middle_name)}</Typography.Text>;
            }
        },
        {
            title: 'E-mail',
            dataIndex: 'email'
        },
        {
            title: t('users.content.permissions.name'),
            render: (_: any, record: User) => {
                return <Typography.Text>{(record.permissions || []).map((permission) => permission.name).join(', ')}</Typography.Text>;
            }
        },
        {
            title: t('users.columns.member_id'),
            width: '5%',
            render: (_: any, record: User) => {
                return (
                    <Tooltip title={record.member_id ? t('users.content.members.navigate.hint') : undefined}>
                        <Button
                            style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                            type="text"
                            onClick={() => onShowMember(record.member_id)}
                        >
                            {record.member_id ? (
                                <Badge status="processing" color="primary" text={t('common.yes')} />
                            ) : (
                                <Badge status="error" text={t('common.no')} />
                            )}
                        </Button>
                    </Tooltip>
                );
            }
        },
        {
            title: '',
            width: '10%',
            dataIndex: 'operations',
            render: (_: any, record: User) => {
                return (
                    <Tooltip title={t('common.edit')}>
                        <Button onClick={() => props.onEdit(record)}>
                            <EditOutlined />
                        </Button>
                    </Tooltip>
                );
            }
        }
    ];

    return (
        <Form form={form} component={false}>
            <Table
                size="small"
                loading={props.loading}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="row"
                rowKey={'id'}
                pagination={{
                    current: props.currentPage,
                    pageSize: props.pageSize,
                    total: props.total,
                    position: ['bottomCenter'],
                    onChange: onChangePage
                }}
            />
        </Form>
    );
};
