import { EventData } from '@models/event-model';
import { MemberData } from '@models/member-model';

export class DigitalFootprintFileDataFile {
    id?: number | null;
    type_id: number;
    type_name: string;
    url?: string | null;
    file_name?: string | null;
}

export class DigitalFootprintFileData {
    id: number;
    event: EventData;
    member: MemberData;
    file: DigitalFootprintFileDataFile;
}

export class GetDigitalFootprintFilesResponse {
    data: DigitalFootprintFileData[] = [];
    count: number = 0;
    total: number = 0;
}

export class DigitalFootprintFilesResponse {
    data: DigitalFootprintFileData;
}
