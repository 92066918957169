import { Indicator, IndicatorModel } from '@models/indicator-model';
import { makeAutoObservable, runInAction } from 'mobx';
import cyrillicToTranslit from 'cyrillic-to-translit-js';
import { APIUtilError } from '@utils/api';
import { DictionaryModel, ResultTypeDictionaryData } from '@models/dictionary-model';

export class IndicatorEditViewModel {
    private indicatorModel = new IndicatorModel();
    private dictionaryModel = new DictionaryModel();

    public isLoading: boolean = false;
    public indicator: Indicator = new Indicator();
    public resultTypes: ResultTypeDictionaryData[] = [];

    constructor(public indicatorId: string | undefined, private showError: (description: string) => void, private onFinish: () => void) {
        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        await this.fetchResultTypeDictionary();
        if (this.indicatorId && this.indicatorId.length > 0) this.fetchIndicator(this.indicatorId);
    }

    private async fetchIndicator(id: string) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.indicatorModel.getIndicatorById(id);
            runInAction(() => {
                this.indicator = result.data;
            });
        } catch (error) {
            this.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public onSave = async (indicator: Indicator) => {
        if (!this.indicator?.id) {
            await this.create(indicator);
        } else {
            await this.save(indicator);
        }
    };

    private create = async (indicator: Indicator) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.indicatorModel.create(indicator);
            this.onFinish();
        } catch (error) {
            this.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    private save = async (indicator: Indicator) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.indicatorModel.update(indicator);
            this.onFinish();
        } catch (error) {
            this.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public generateAltName = (name: string) => {
        return cyrillicToTranslit().transform(name, '_').toLocaleLowerCase();
    };

    private async fetchResultTypeDictionary() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'result_type' });
            runInAction(() => {
                this.resultTypes = result.data as ResultTypeDictionaryData[];
            });
        } catch (error) {
            this.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }
}
