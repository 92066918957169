import { RidFilterInfo, RidFilterParamName, RidGetFilter } from '@interfaces/rid.interface';
import { DictionaryModel, MonthDictionaryData } from '@models/dictionary-model';
import { ResultModel } from '@models/result-model';
import { ResultTypeModel } from '@models/result-type-model';
import { RidData, RidModel } from '@models/rid-model';
import { APIUtilError, isAPIError } from '@utils/api';
import { ObjectLiteral, deleteUndefined, isObjectsHasDifferences } from '@utils/object.utils';
import { debounce } from 'lodash';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { StartupModel } from '@models/startup-model';

export interface RidsViewModelStartParams {
    t: any;
    searchParams: URLSearchParams;
    setSearchParams: (params: any) => void;
    showError: (title: string, description: string) => void;
}

export class RidsViewModel {
    private ridModel = new RidModel();
    private dictionaryModel = new DictionaryModel();
    private resultTypeModel = new ResultTypeModel();
    private resultModel = new ResultModel();
    private startupModel = new StartupModel();

    public isLoading: boolean = false;

    public currentPage: number = 1;
    public pageSize: number = 20;
    public availablePageSizes = [10, 20, 50, 100];
    public currentFilter: RidGetFilter = {};

    public currentFilterInfo: RidFilterInfo[] = [];
    public isCurrentFilterLoading = false;

    public rids: RidData[] = [];
    public ridsTotal: number = 0;

    public monthDictionary: MonthDictionaryData[] = [];

    constructor(private props: RidsViewModelStartParams) {
        const incomingFilter = this.getFilterFromSearchParams(props.searchParams);
        this.currentFilter = incomingFilter;
        this.currentPage = incomingFilter.page || 0;
        this.pageSize = incomingFilter.page_size || this.availablePageSizes[1];

        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        await this.fetchRids(this.currentFilter);
        await this.prepareFilterInfo(this.currentFilter);
        await this.fetchMonthsDictionary();
    }

    public async updateSearchParamsIfNeeded(searchParams: URLSearchParams): Promise<Boolean> {
        const newFilter = this.getFilterFromSearchParams(searchParams);
        if (isObjectsHasDifferences(newFilter, toJS(this.currentFilter))) {
            runInAction(() => {
                this.currentPage = newFilter.page || 1;
                this.pageSize = newFilter.page_size || this.availablePageSizes[1];
                this.currentFilter = newFilter;
            });
            await this.fetchRids(newFilter);
            return true;
        }
        return false;
    }

    private getFilterFromSearchParams = (searchParams: URLSearchParams) => {
        let filter: RidGetFilter = { page: 1, page_size: this.pageSize };
        const keys: RidFilterParamName[] = ['search', 'result_type_id', 'result_id', 'startup_id', 'page', 'page_size'];

        for (const key of keys) {
            const value = searchParams.get(key);
            if (!value) continue;
            switch (key) {
                case 'search':
                    filter[key] = value;
                    break;
                case 'result_type_id':
                    try {
                        filter[key] = parseInt(value);
                    } catch {
                        filter[key] = undefined;
                    }
                    break;
                case 'result_id':
                    try {
                        filter[key] = parseInt(value);
                    } catch {
                        filter[key] = undefined;
                    }
                    break;
                case 'startup_id':
                    try {
                        filter[key] = parseInt(value);
                    } catch {
                        filter[key] = undefined;
                    }
                    break;
                case 'page':
                    try {
                        const parsedPage = parseInt(value);
                        filter[key] = parsedPage;
                    } catch {
                        filter[key] = 1;
                    }
                    break;
                case 'page_size':
                    try {
                        const parsedSize = parseInt(value);
                        if (!this.availablePageSizes.includes(parsedSize)) throw new Error();
                        filter.page_size = parsedSize;
                    } catch {
                        filter.page_size = this.availablePageSizes[0];
                    }
                    break;

                default:
                    break;
            }
        }
        return filter;
    };

    public filterInfoCount(filter: RidGetFilter) {
        let count = 0;
        const keys: RidFilterParamName[] = ['result_type_id', 'result_id', 'startup_id'];
        for (const key of keys) {
            const value = filter[key];
            if (!value || value === undefined) continue;
            count++;
        }
        return count;
    }

    public async prepareFilterInfo(filter: RidGetFilter) {
        runInAction(() => {
            this.isCurrentFilterLoading = true;
        });

        let info: RidFilterInfo[] = [];
        const keys: RidFilterParamName[] = ['result_type_id', 'result_id', 'startup_id'];
        for (const key of keys) {
            const value = filter[key];
            if (!value || value === undefined) continue;
            switch (key) {
                case 'result_type_id':
                    try {
                        const result = await this.resultTypeModel.getResultTypeById(filter[key]!);
                        info.push({
                            type: key,
                            name: this.props.t('rids.filter.result_type_id'),
                            value: result.data.title
                        });
                    } catch {}
                    break;
                case 'result_id':
                    try {
                        const result = await this.resultModel.getById(filter[key]!);
                        info.push({
                            type: key,
                            name: this.props.t('rids.filter.result_id'),
                            value: result.data.title
                        });
                    } catch {}
                    break;
                case 'startup_id':
                    try {
                        const result = await this.startupModel.getStartupById(filter[key]!);
                        info.push({
                            type: key,
                            name: this.props.t('finances.filter.startup_id'),
                            value: result.data.name ? result.data.name : `#${result.data.id} ${this.props.t('startups.content.name.empty')}`
                        });
                    } catch {}
                    break;
                default:
                    break;
            }
        }

        runInAction(() => {
            this.currentFilterInfo = info;
            this.isCurrentFilterLoading = false;
        });
    }

    private extractSearchParamsFromFilter = (filter: ObjectLiteral) => {
        let searchParams: ObjectLiteral = {};
        for (const key in filter) {
            switch (key) {
                default:
                    searchParams[key] = filter[key] ? filter[key] : undefined;
            }
        }
        return deleteUndefined(searchParams);
    };

    private async fetchRids(params: RidGetFilter) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.ridModel.getRids(params);
            runInAction(() => {
                this.rids = result.data;
                this.ridsTotal = result.total || 0;
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private async fetchMonthsDictionary() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'month' });
            runInAction(() => {
                this.monthDictionary = result.data as MonthDictionaryData[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public onRemoveFilterInfo = (info: RidFilterInfo) => {
        let filterCopy = this.currentFilter;
        filterCopy[info.type] = undefined;
        this.onFilterValueChange(filterCopy);
    };

    public onFilterValueChange = (values: ObjectLiteral) => {
        const formsValues = deleteUndefined(values);
        let filter: RidGetFilter = { ...this.currentFilter, page: 1, page_size: this.pageSize };
        for (const key in formsValues) {
            switch (key) {
                case 'search':
                    filter.search = formsValues[key];
                    break;
                case 'result_type_id':
                    filter[key] = formsValues[key];
                    break;
                case 'result_id':
                    filter[key] = formsValues[key];
                    break;
                default:
                    break;
            }
        }

        const searchParams = this.extractSearchParamsFromFilter(deleteUndefined(filter));
        this.props.setSearchParams(searchParams as any);

        runInAction(() => {
            this.currentPage = 1;
            this.currentFilter = deleteUndefined(filter);
        });
        this.debouncedFetch(deleteUndefined(filter));
    };

    private debouncedFetch = debounce(async (params: RidGetFilter) => {
        this.fetchRids(params);
    }, 400);

    public async update(rid: RidData): Promise<string | undefined> {
        runInAction(() => {
            this.isLoading = true;
        });

        try {
            await this.ridModel.update(rid);
            let copy = [...this.rids];
            const index = copy.map((item) => item.id).indexOf(rid.id);
            copy[index] = rid;
            this.rids = copy;
        } catch (error) {
            return isAPIError(error) ? error.localizedDescription : 'Unknown Error';
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public onChangePagination = async (page: number, pageSize: number) => {
        if (pageSize !== this.pageSize) {
            runInAction(() => {
                this.pageSize = pageSize;
            });
        }
        const filter: RidGetFilter = { ...this.currentFilter, page: page, page_size: pageSize };
        runInAction(() => {
            this.currentPage = page;
            this.currentFilter = filter;
        });

        const currentFiltersSearchParams = this.extractSearchParamsFromFilter(filter);
        this.props.setSearchParams(currentFiltersSearchParams);

        this.fetchRids(filter);
    };
}
