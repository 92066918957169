import { deleteUndefined } from '@utils/object.utils';
import { APIInstance, BaseURL } from '@utils/api';
import { DigitalFootprintFileData, DigitalFootprintFilesResponse, GetDigitalFootprintFilesResponse } from './digital-footprint-files.contract';
import { DigitalFootprintFilesGetFilter } from '@interfaces/digital-footprint.interface';
import { saveAs } from 'file-saver';
import { RootModel } from '@models/root-model';

export class DigitalFootprintFilesModel {
    public async get(params: DigitalFootprintFilesGetFilter): Promise<GetDigitalFootprintFilesResponse> {
        try {
            const result = await APIInstance.get<GetDigitalFootprintFilesResponse>('/cp-manager/digital-footprint/material', {
                params: deleteUndefined({
                    event_id: params.event_id,
                    member_id: params.member_id,
                    file_type_id: params.type_id,
                    limit: params.page_size,
                    offset: params.page_size && params.page ? params.page_size * (params.page - 1) : 0
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async deleteById(id: number) {
        try {
            const result = await APIInstance.delete(`/cp-manager/digital-footprint/material/${id}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public uploadURL(footprintFileId: number) {
        return `${BaseURL}/cp-manager/digital-footprint/material/${footprintFileId}/file`;
    }

    public uploadHeaders = async () => {
        let accessToken = RootModel?.authModel?.getAccessToken();
        if (!accessToken) {
            await RootModel?.authModel?.updateToken();
            accessToken = RootModel?.authModel?.getAccessToken();
        }
        return { Authorization: RootModel?.authModel ? `Bearer ${accessToken}` : '' };
    };

    public async downloadFile(url: string, filename: string | undefined) {
        await APIInstance({
            url: url,
            method: 'GET',
            responseType: 'blob'
        }).then((response) => {
            saveAs(response.data, filename);
        });
    }

    public async create(footprintFile: DigitalFootprintFileData): Promise<DigitalFootprintFilesResponse> {
        try {
            const result = await APIInstance.post<DigitalFootprintFilesResponse>(`/cp-manager/digital-footprint/material`, { ...footprintFile });
            return result.data;
        } catch (error) {
            throw error;
        }
    }
}
