import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Space, Table, Tooltip, Typography } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined, EyeOutlined, UnorderedListOutlined, UserOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { DigitalFootprintUserData } from '@models/digital-footprint/users';

interface TableProps {
    currentPage: number;
    pageSize: number;
    rows: DigitalFootprintUserData[];
    total: number;
    loading: boolean;
    onChangePagination: (page: number, pageSize: number) => void;
    onDelete: (user: DigitalFootprintUserData) => void;
    onShowEvent: (user: DigitalFootprintUserData) => void;
    onShowMember: (user: DigitalFootprintUserData) => void;
    onShowUser: (user: DigitalFootprintUserData) => void;
}

export const UsersTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [modal, contextHolder] = Modal.useModal();
    const [data, setData] = useState<DigitalFootprintUserData[]>([]);

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const onChangePage = (page: number, pageSize: number) => {
        props.onChangePagination(page, pageSize);
    };

    const confirmDelete = (user: DigitalFootprintUserData) => {
        modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: t('common.confirmation_required'),
            content: t('digital_footprint.users.delete.confirm'),
            okText: t('common.delete'),
            cancelText: t('common.cancel'),
            onOk: () => props.onDelete(user)
        });
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: (
                <div>
                    <UnorderedListOutlined style={{ fontSize: 16, color: 'gray', marginRight: 5 }} />
                    {t('digital_footprint.users.content.event.title')}
                </div>
            ),
            render: (_: any, record: DigitalFootprintUserData) => {
                return (
                    <Space direction="horizontal" size="small">
                        <Typography.Text>{record.event.name}</Typography.Text>
                        <Tooltip title={t('digital_footprint.users.content.event.show')}>
                            <Button icon={<EyeOutlined />} onClick={() => props.onShowEvent(record)} />
                        </Tooltip>
                    </Space>
                );
            }
        },
        {
            title: (
                <div>
                    <UsergroupAddOutlined style={{ fontSize: 16, color: 'gray', marginRight: 5 }} />
                    {t('digital_footprint.users.content.member.title')}
                </div>
            ),
            render: (_: any, record: DigitalFootprintUserData) => {
                return (
                    <Space direction="horizontal" size="small">
                        <Typography.Text>
                            {record.member.last_name} {record.member.first_name} {record.member.middle_name}
                        </Typography.Text>
                        <Tooltip title={t('digital_footprint.users.content.member.show')}>
                            <Button icon={<EyeOutlined />} onClick={() => props.onShowMember(record)} />
                        </Tooltip>
                    </Space>
                );
            }
        },
        {
            title: (
                <div>
                    <UserOutlined style={{ fontSize: 16, color: 'gray', marginRight: 5 }} />
                    {t('digital_footprint.users.content.user.title')}
                </div>
            ),
            render: (_: any, record: DigitalFootprintUserData) => {
                return (
                    <Space direction="horizontal" size="small">
                        <Typography.Text>
                            {record.user.last_name} {record.user.first_name} {record.user.middle_name}
                        </Typography.Text>
                        <Tooltip title={t('digital_footprint.users.content.user.show')}>
                            <Button icon={<EyeOutlined />} onClick={() => props.onShowUser(record)} />
                        </Tooltip>
                    </Space>
                );
            }
        },
        {
            title: '',
            width: '5%',
            dataIndex: 'operations',
            render: (_: any, record: DigitalFootprintUserData) => {
                return (
                    <Tooltip title={t('common.delete')}>
                        <Button onClick={() => confirmDelete(record)}>
                            <DeleteOutlined />
                        </Button>
                    </Tooltip>
                );
            }
        }
    ];

    return (
        <div>
            <Table
                size="small"
                loading={props.loading}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="row"
                rowKey={'id'}
                pagination={{
                    current: props.currentPage,
                    pageSize: props.pageSize,
                    total: props.total,
                    position: ['bottomCenter'],
                    onChange: onChangePage
                }}
            />
            {contextHolder}
        </div>
    );
};

export default UsersTable;
