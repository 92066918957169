import { APIUtilError } from '@utils/api';
import { makeAutoObservable, runInAction } from 'mobx';
import { StatementsModel, StatementData, StatementFilterData } from '@models/statements-model';

export interface StatementsEditViewModelStartParams {
    t: any;
    statementId: string | undefined;
    showError: (description: string) => void;
    onFinish: () => void;
}

export class StatementsEditViewModel {
    private statementModel = new StatementsModel();
    public loading: boolean = false;
    public statement: StatementData = new StatementData();

    public loadingFilters: boolean = false;
    public filters: StatementFilterData[] = [];

    public loadingData: boolean = false;
    public statementData: any[] = [];
    public statementDataTotal: number = 0;

    public testResult: any[] = [];
    public loadingTestData: boolean = false;

    constructor(private startParams: StatementsEditViewModelStartParams) {
        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        if (this.startParams.statementId && this.startParams.statementId.length > 0) {
            this.fetchStatement(this.startParams.statementId);
            this.fetchFilters(this.startParams.statementId);
            this.fetchStatementData(this.startParams.statementId, []);
        }
    }

    private async fetchStatement(statementId: string) {
        runInAction(() => {
            this.loading = true;
        });
        try {
            const statement = await this.statementModel.getStatementById(statementId);
            runInAction(() => {
                this.statement = statement.data;
            });
        } catch (error) {
            this.startParams.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    private async fetchFilters(statementId: string) {
        runInAction(() => {
            this.loadingFilters = true;
        });
        try {
            const filters = await this.statementModel.getFilters(statementId);
            runInAction(() => {
                this.filters = filters.data;
            });
        } catch (error) {
            this.startParams.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loadingFilters = false;
            });
        }
    }

    public async createDefaultFilter(value: string) {
        try {
            if (this.startParams.statementId) {
                await this.statementModel.createDefaultFilter(this.startParams.statementId, value);
                await this.fetchFilters(this.startParams.statementId);
            }
        } catch (error) {
            this.startParams.showError((error as APIUtilError).localizedDescription);
        }
    }

    public onSave = async (statement: StatementData) => {
        if (!this.startParams.statementId) {
            await this.create(statement);
        } else {
            await this.save(statement);
        }
    };

    private create = async (statement: StatementData) => {
        runInAction(() => {
            this.loading = true;
        });
        try {
            await this.statementModel.createStatement(statement);
            this.startParams.onFinish();
        } catch (error) {
            this.startParams.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    private save = async (statement: StatementData) => {
        runInAction(() => {
            this.loading = true;
        });
        try {
            await this.statementModel.updateStatement(statement);
            this.startParams.onFinish();
        } catch (error) {
            this.startParams.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    public async fetchStatementData(statementId: string, selectedFilters: any[]) {
        runInAction(() => {
            this.loadingData = true;
        });
        try {
            const res = await this.statementModel.getData(statementId, selectedFilters);
            runInAction(() => {
                this.statementData = res.data;
                this.statementDataTotal = res.total;
            });
        } catch (error: any) {
            if (error?.response?.status === 422) {
                this.startParams.showError(error.response.data.detail.msg);
            } else this.startParams.showError('Ошибка загрузки данных отчета. Проверьте запрос.');
        } finally {
            runInAction(() => {
                this.loadingData = false;
            });
        }
    }

    public async exportData(statementId: string, selectedFilters: any[], ext: string) {
        runInAction(() => {
            this.loadingData = true;
        });
        try {
            await this.statementModel.exportData(statementId, selectedFilters, ext);
        } catch (error: any) {
            this.startParams.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loadingData = false;
            });
        }
    }

    public async testQuery(query: string) {
        runInAction(() => {
            this.loadingTestData = true;
        });
        try {
            const res = await this.statementModel.testQuery(query);
            if (res.status_code === 400) {
                runInAction(() => {
                    this.testResult = [{ msg: this.startParams.t('statements.test_query_modal.error'), error: res.msg }];
                });
            } else {
                runInAction(() => {
                    this.testResult = res.data;
                });
            }
        } catch (error: any) {
            this.startParams.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.loadingTestData = false;
            });
        }
    }
}
