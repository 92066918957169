import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import '@assets/css/App.css';
import { Button, Col, Divider, Form, Input, List, Mentions, notification, Row, Spin, Typography } from 'antd';
import { useViewModel } from '@models/model';
import { useTranslation } from 'react-i18next';
import { plainToInstance } from 'class-transformer';
import { useNavigate, useParams } from 'react-router-dom';
import { NoticeTemplateData } from '@models/notice-template-model';
import { NoticeTemplateEditViewModel } from './notice-template.edit.view.model';

const NoticeTemplateEditView: React.FC = observer(() => {
    const { t } = useTranslation();
    const { id: templateId } = useParams();
    const navigate = useNavigate();

    const [notifyer, contextHolder] = notification.useNotification();
    const showSuccessNotification = (title: string, description: string) => {
        notifyer.success({
            message: title,
            description: description
        });
    };
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const onClickCancel = () => {
        navigate('/notice-templates');
    };

    const viewModel = useViewModel(
        () =>
            new NoticeTemplateEditViewModel({
                t,
                templateId,
                showSuccess: showSuccessNotification,
                showError: showErrorNotification,
                close: onClickCancel
            })
    );

    const [form] = Form.useForm<NoticeTemplateData>();

    useEffect(() => {
        form.resetFields();
    }, [viewModel.template]);

    const onClickSave = () => form.submit();

    const getFormData = () => {
        const valFromFields: Record<string, any> = form.getFieldsValue();
        var converted: NoticeTemplateData = plainToInstance(NoticeTemplateData, { ...viewModel.template, ...valFromFields });
        return converted;
    };

    const onSuccessFormValidation = async () => {
        const data = getFormData();
        await viewModel.onSave(data);
    };

    return (
        <div>
            <br />
            <Spin spinning={viewModel.isLoading}>
                <div>
                    {contextHolder}
                    <Row gutter={12} justify="end" align="middle">
                        <Col>
                            <Button onClick={onClickCancel}>
                                {t('common.cancel')}
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" onClick={onClickSave} loading={viewModel.isLoading} disabled={viewModel.isLoading}>
                                {t('common.save')}
                            </Button>
                        </Col>
                    </Row>
                    <br />

                    <Form form={form} initialValues={viewModel.template} layout="horizontal" onFinish={onSuccessFormValidation}>
                        <Divider orientation="left">{t('noticeTemplate.content.common')}</Divider>
                        <div className="edit-content-view">
                            <Form.Item>
                                <Typography.Text>ID: </Typography.Text>
                                <Typography.Text strong copyable>
                                    {viewModel.template.id || ''}
                                </Typography.Text>
                            </Form.Item>
                            <Form.Item
                                name="name"
                                label={t('noticeTemplate.content.name.title')}
                                rules={[{ required: true, message: t('noticeTemplate.content.required') || '' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="alt_name"
                                label={t('noticeTemplate.content.alt_name.title')}
                                rules={[{ required: true, message: t('noticeTemplate.content.required') || '' }]}
                            >
                                <Input />
                            </Form.Item>
                        </div>

                        <br />
                        <Divider orientation="left">{t('noticeTemplate.content.message.title')}</Divider>

                        <div className="edit-content-view">
                            <Row gutter={12}>
                                <Col span={18}>
                                    <Form.Item name="message">
                                        <Mentions
                                            rows={14}
                                            prefix={['%']}
                                            options={(viewModel.template.variables || []).map((item) => ({
                                                value: item.key.substring(1, item.key.length),
                                                label: `${item.key} - ${item.description}`
                                            }))}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <div
                                        id="scrollableDiv"
                                        style={{
                                            height: 320,
                                            overflow: 'auto',
                                            padding: '0 16px'
                                        }}
                                    >
                                        <List
                                            dataSource={viewModel.template?.variables || []}
                                            renderItem={(item) => (
                                                <List.Item key={item.id}>
                                                    <List.Item.Meta title={item.key} description={item.description || '-'} />
                                                </List.Item>
                                            )}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </div>
            </Spin>
        </div>
    );
});

export default NoticeTemplateEditView;
