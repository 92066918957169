import { APIInstance, BaseURL } from '@utils/api';
import { deleteUndefined } from '@utils/object.utils';
import { saveAs } from 'file-saver';
import {
    GetReportAttachedFilesResponse,
    GetReportsResponse,
    ReportGetFilesFilter,
    ReportGetUsersFilter,
    ReportResponse,
    ReportUsersReponse,
    ReportsGetFilter,
    GetReportTypes,
    UpdateReportData
} from './report.contract';

export class ReportModel {
    public async getReports(params: ReportsGetFilter): Promise<GetReportsResponse> {
        try {
            const result = await APIInstance.get<GetReportsResponse>('/cp-manager/report', {
                params: deleteUndefined({
                    search: params.search,
                    limit: params.page_size,
                    offset: params.page_size && params.page ? params.page_size * (params.page - 1) : 0
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getReportById(id: number): Promise<ReportResponse> {
        try {
            const result = await APIInstance.get<ReportResponse>(`/cp-manager/report/${id}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async fetchReportTypes(): Promise<GetReportTypes> {
        try {
            const result = await APIInstance.get<GetReportTypes>(`/cp-manager/report/types`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async deleteReportById(id: number): Promise<ReportResponse> {
        try {
            const result = await APIInstance.delete<ReportResponse>(`/cp-manager/report/${id}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getReportUsers(params: ReportGetUsersFilter): Promise<ReportUsersReponse> {
        try {
            const result = await APIInstance.get<ReportUsersReponse>(`/cp-manager/report/${params.reportId}/user`, {
                params: deleteUndefined({
                    search: params.search,
                    limit: params.page_size,
                    offset: params.page_size && params.page ? params.page_size * (params.page - 1) : 0
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async addUserToReport(reportId: number, userId: string) {
        try {
            const result = await APIInstance.post(`/cp-manager/report/${reportId}/user/${userId}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async deleteUserFromReport(reportId: number, userId: string) {
        try {
            const result = await APIInstance.delete(`/cp-manager/report/${reportId}/user/${userId}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getReportFiles(params: ReportGetFilesFilter) {
        try {
            const result = await APIInstance.get<GetReportAttachedFilesResponse>(`/cp-manager/report/${params.reportId}/excel`, {
                params: deleteUndefined({
                    limit: params.page_size,
                    offset: params.page_size && params.page ? params.page_size * (params.page - 1) : 0
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async downloadFileFromReport(urt: string, filename: string) {
        await APIInstance({
            url: '/cp-manager/event/export/excel',
            method: 'GET',
            responseType: 'blob'
        }).then((response) => {
            saveAs(response.data, filename);
        });
    }

    public async deleteFileFromReport(reportId: number, fileId: number) {
        try {
            const result = await APIInstance.delete(`/cp-manager/report/${reportId}/excel/${fileId}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public uploadURL(reportId: number) {
        return `${BaseURL}/cp-manager/report/${reportId}/file`;
    }

    public async updateReport(reportId: string, data: UpdateReportData) {
        try {
            const result = await APIInstance.put(`/cp-manager/report/${reportId}`, data);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async createReport(data: UpdateReportData) {
        try {
            const result = await APIInstance.post(`/cp-manager/report`, data);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public downloadURL(reportId: number, fileUUID: string) {
        return `${BaseURL}/cp-manager/report/${reportId}/file/${fileUUID}`; 
    }
}   
