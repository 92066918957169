import {
    Button,
    Col,
    DatePicker,
    Divider,
    Empty,
    Form,
    Input,
    Result,
    Row,
    Segmented,
    Select,
    Space,
    Spin,
    Steps,
    Switch,
    Tabs,
    Tag,
    Timeline,
    Tooltip,
    Typography,
    notification
} from 'antd';
import '@assets/css/App.css';
import { instanceToPlain } from 'class-transformer';
import { useTranslation } from 'react-i18next';
import { EventDataContact } from '@models/event-model';
import { observer } from 'mobx-react-lite';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { useViewModel } from '@models/model';
import { EventEditViewModel } from './event.edit.view.model';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { stringToDate } from '@utils/date.utils';
import {
    CheckCircleOutlined,
    CheckOutlined,
    ClockCircleOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    FileTextOutlined,
    PaperClipOutlined,
    UsergroupAddOutlined
} from '@ant-design/icons';
import ContactsTable from './contacts.table';
import FilesCatalogView from '@components/UI/files-catalog/catalog.view';
import TabPane from 'antd/es/tabs/TabPane';
import WYSIWYGEditor from '@components/UI/WYSIWYG/WYSIWYG.editor';
import { ObjectLiteral, deleteUndefined } from '@utils/object.utils';
import { HistoryView } from './event.history.view';
import ResultSelector from './result.drawer';
import { ResultData } from '@models/result-model';

const EventEditView: React.FC = observer(() => {
    dayjs.extend(utc);
    const { t, i18n } = useTranslation();
    const { id: eventId } = useParams();
    const navigate = useNavigate();
    const [creatorCurrentStep, setCreatorCurrentStep] = useState(0);
    const [form] = Form.useForm();

    const timezone_id = Form.useWatch('timezone_id', form);
    const announcing_date = Form.useWatch('announcing_date', form);
    const registration_begin_date = Form.useWatch('registration_begin_date', form);
    const registration_end_date = Form.useWatch('registration_end_date', form);
    const begin_date = Form.useWatch('begin_date', form);
    const end_date = Form.useWatch('end_date', form);

    const [notifyer, contextHolder] = notification.useNotification();
    const showSuccessNotification = (title: string, description: string) => {
        notifyer.success({
            message: title,
            description: description
        });
    };
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const [isResultSelectorVisible, setIsResultSelectorVisible] = useState(false);
    const [changedFields, setChangedFields] = useState<ObjectLiteral>({});

    const onClickCancel = () => {
        navigate('/events-manage');
    };

    const onClickShowMembers = () => {
        if (!viewModel.isPublished) return;

        const searchparams = createSearchParams({
            event_id: `${viewModel.event.id}`
        }).toString();

        window.open('/members-manage?' + searchparams, '_blank', 'noreferrer');
    };

    const viewModel = useViewModel(
        () =>
            new EventEditViewModel({
                t,
                eventId,
                showSuccess: showSuccessNotification,
                showError: showErrorNotification
            })
    );

    useEffect(() => {
        form.resetFields();
        if (!viewModel.isPublished) {
            setChangedFields({ ...viewModel.event });
        }
        setTimeout(() => {
            form.resetFields(['announcing_date', 'registration_begin_date', 'registration_end_date', 'begin_date', 'end_date']);
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModel.event]);

    const onClickSave = () => form.submit();

    const getFormData = () => {
        return deleteUndefined({
            id: viewModel.event?.id,
            ...changedFields,
            result_id: changedFields.result_id ? [changedFields.result_id] : undefined
        });
    };

    const onSuccessFormValidation = async () => {
        let data: any = getFormData();
        for (const key in data) {
            if (typeof data[key] == 'undefined') {
                data[key] = null;
            }
        }

        await viewModel.onSave(data);
        if (viewModel.isPublished === false && viewModel.event.id !== undefined) {
            setCreatorCurrentStep(creatorCurrentStep + 1);
        }
    };

    const prepareDataForForm = () => {
        let event = instanceToPlain(viewModel.event);

        const selectedTimezoneOffset = timezone_id ? viewModel.getTimezoneById(timezone_id)?.offset : undefined;
        const timezoneOffset =
            selectedTimezoneOffset !== undefined ? selectedTimezoneOffset : viewModel.getTimezoneById(viewModel.event.timezone_id || 0)?.offset || 0;

        if (changedFields.announcing_date || typeof viewModel.event.announcing_date === 'string') {
            event.announcing_date = dayjs(changedFields.announcing_date || viewModel.event.announcing_date).utcOffset(timezoneOffset);
        }
        if (changedFields.registration_begin_date || typeof viewModel.event.registration_begin_date === 'string') {
            event.registration_begin_date = dayjs(changedFields.registration_begin_date || viewModel.event.registration_begin_date).utcOffset(
                timezoneOffset
            );
        }
        if (changedFields.registration_end_date || typeof viewModel.event.registration_end_date === 'string') {
            event.registration_end_date = dayjs(changedFields.registration_end_date || viewModel.event.registration_end_date).utcOffset(
                timezoneOffset
            );
        }
        if (changedFields.begin_date || typeof viewModel.event.begin_date === 'string') {
            event.begin_date = dayjs(changedFields.begin_date || viewModel.event.begin_date).utcOffset(timezoneOffset); //dayjs('2023-05-11T12:20:42.620000+00:00').utcOffset(timezoneOffset);
        }
        if (changedFields.end_date || typeof viewModel.event.end_date === 'string') {
            event.end_date = dayjs(changedFields.end_date || viewModel.event.end_date).utcOffset(timezoneOffset);
        }
        return event;
    };

    const onFormValueChange = (changed: any) => {
        if (changed.begin_date && !end_date) {
            /// Автоматически проставляем дату окончания мероприятия
            form.setFieldValue('end_date', changed.begin_date);
            setChangedFields({ ...changedFields, ...changed, end_date: changed.begin_date });
        } else {
            setChangedFields({ ...changedFields, ...changed });
        }
        if (changed.timezone_id) {
            setTimeout(() => {
                form.resetFields(['announcing_date', 'registration_begin_date', 'registration_end_date', 'begin_date', 'end_date']);
            }, 500);
        }
    };

    const getEventFormatSelectorContent = () =>
        viewModel.eventFormatDictionary.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        });
    const getRegionSelectorContent = () =>
        viewModel.regionDictionary.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        });

    const getTimezoneSelectorContent = () =>
        viewModel.timezoneDictionary.map((item) => {
            return {
                value: item.id,
                label: `${item.name} (UTC+${item.offset})`
            };
        });

    const getEventTypeSelectorItems = () =>
        viewModel.eventTypes.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        });

    const getTimelineItemsForListCard = () => {
        let items: any = [];
        const timezoneOffset = timezone_id ? viewModel.getTimezoneById(timezone_id)?.offset || 0 : 0;

        const converterFormat = 'YYYY.MM.DD HH:mm';
        let displayFormat = '(dd) D MMM YYYY в HH:mm';
        displayFormat = timezoneOffset ? displayFormat + ` (UTC+${timezoneOffset})` : displayFormat;

        interface DateDataType {
            label: string;
            date: string | Dayjs | undefined;
            dot?: JSX.Element | undefined;
        }
        const currentDateFormatted = timezoneOffset
            ? dayjs(new Date().toISOString()).utcOffset(timezoneOffset).format(converterFormat)
            : dayjs(new Date()).format(converterFormat);

        /// Конвертируем даты в строку по формату 'format', для отвязывания от таймзоны
        let dates: DateDataType[] = [
            {
                label: t('events_dashboard.list.announcing_date'),
                date: announcing_date ? dayjs(announcing_date).utcOffset(timezoneOffset).format(converterFormat) : undefined
            },
            {
                label: t('events_dashboard.list.registration_begin_date'),
                date: registration_begin_date ? dayjs(registration_begin_date).utcOffset(timezoneOffset).format(converterFormat) : undefined
            },
            {
                label: t('events_dashboard.list.registration_end_date'),
                date: registration_end_date ? dayjs(registration_end_date).utcOffset(timezoneOffset).format(converterFormat) : undefined
            },
            {
                label: t('events_dashboard.list.begin_date'),
                date: begin_date ? dayjs(begin_date).utcOffset(timezoneOffset).format(converterFormat) : undefined
            },
            {
                label: t('events_dashboard.list.end_date'),
                date: end_date ? dayjs(end_date).utcOffset(timezoneOffset).format(converterFormat) : undefined
            },
            {
                label: t('events_dashboard.list.today'),
                date: currentDateFormatted,
                dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />
            }
        ];

        /// Фильтруем от пустых объектов
        dates = dates.filter((item) => item.date && dayjs(item.date).isValid());
        ///Конвертируемся снова в дату dayjs формата, для правильной дальнейшей сортировки дат
        dates = dates.map((item: DateDataType) => {
            return { ...item, date: dayjs(item.date, converterFormat) };
        });

        if (dates.length <= 1) return [];

        dates = dates.sort((a, b) => {
            return dayjs(a.date).toDate().valueOf() - dayjs(b.date).toDate().valueOf();
        });

        for (const date of dates) {
            items.push({
                label: date.label,
                children: dayjs(date.date).format(displayFormat),
                color: date.dot ? 'blue' : dayjs(date.date).isBefore(dayjs(currentDateFormatted, converterFormat)) ? 'green' : 'gray',
                dot: date.dot
            });
        }
        return items;
    };

    const onUpdateContacts = (data: EventDataContact[]) => {
        form.setFieldValue('contact', data);
        onFormValueChange({ contact: data });
    };

    const onErrorFormValidation = (errorsInfo: any) => {
        showErrorNotification(t('common.error.empty'), t('common.check_fields'));
    };

    const editForm = () => {
        return (
            <Spin spinning={viewModel.isLoading}>
                <Form
                    form={form}
                    initialValues={prepareDataForForm()}
                    layout="horizontal"
                    onValuesChange={onFormValueChange}
                    onFinish={onSuccessFormValidation}
                    onFinishFailed={onErrorFormValidation}
                >
                    <Divider orientation="left">{t('events.content.main_info')}</Divider>
                    <div className="edit-content-view">
                        {viewModel.isPublished && (
                            <Form.Item>
                                <Typography.Text>ID: </Typography.Text>
                                <Typography.Text strong copyable>
                                    {viewModel.event.id}
                                </Typography.Text>
                            </Form.Item>
                        )}
                        <Row gutter={20} align="middle">
                            <Col>
                                <Form.Item
                                    name={'event_format_id'}
                                    label={t('events.content.event_format')}
                                    rules={[{ required: true, message: t('events.content.required') || '' }]}
                                >
                                    <Segmented options={getEventFormatSelectorContent()} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="is_visible"
                                    label={t('events.content.is_visible.title')}
                                    tooltip={t('events.content.is_visible.hint')}
                                    valuePropName="checked"
                                >
                                    <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="top_50"
                                    label={t('events.content.top_50.title')}
                                    tooltip={t('events.content.top_50.hint')}
                                    valuePropName="checked"
                                >
                                    <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            name={'event_type_id'}
                            label={t('events.content.event_type')}
                            rules={[{ required: true, message: t('events.content.required') || '' }]}
                        >
                            <Select
                                options={getEventTypeSelectorItems()}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            />
                        </Form.Item>
                        <Form.Item
                            name="name"
                            label={t('events.content.name.title')}
                            rules={[{ required: true, message: t('events.content.required') || '' }]}
                        >
                            <Input placeholder={t('events.content.name.placeholder') || ''} />
                        </Form.Item>

                        <Form.Item
                            name="result_id"
                            label={t('events.content.result.title')}
                            validateStatus={viewModel.event.results && viewModel.event.results.length > 0 ? 'success' : 'warning'}
                            help={viewModel.event.results && viewModel.event.results.length > 0 ? undefined : t('events.save.result_warning')}
                            hasFeedback={viewModel.event.results && viewModel.event.results.length > 0 ? false : true}
                        >
                            <div>
                                <Space>
                                    <Typography.Text type={viewModel.event.results && viewModel.event.results.length > 0 ? undefined : 'secondary'}>
                                        {viewModel.event.results && viewModel.event.results.length > 0
                                            ? viewModel.event.results[0].title
                                            : t('events.content.result.empty')}
                                    </Typography.Text>
                                    <Button
                                        icon={<EditOutlined />}
                                        onClick={() => {
                                            setIsResultSelectorVisible(true);
                                        }}
                                    />
                                    {viewModel.event.results && viewModel.event.results.length > 0 && (
                                        <Button
                                            icon={<DeleteOutlined />}
                                            onClick={() => {
                                                form.setFieldValue('result_id', null);
                                                onFormValueChange({ result_id: null });
                                                viewModel.onSetEventResults(undefined);
                                            }}
                                        />
                                    )}
                                </Space>
                            </div>
                        </Form.Item>

                        <Form.Item
                            name={'organizer_id'}
                            initialValue={viewModel.event.organizer?.short_name || viewModel.event.organizer?.name || ''}
                            label={t('events.content.organizer.title')}
                            rules={[{ required: false }]}
                        >
                            <Select
                                showSearch
                                allowClear
                                notFoundContent={<Empty description={''} />}
                                placeholder={t('events.content.organizer.placeholder')}
                                onSearch={(value: string) => viewModel.searchOrganizations(value)}
                                loading={viewModel.isOrganizationsLoading}
                                filterOption={false}
                            >
                                {viewModel.organizations.map((item, i) => (
                                    <Select.Option value={item.id} key={`org_${i}`}>
                                        <Space direction="vertical">
                                            <Typography.Text>{item.short_name ? item.short_name : item.name}</Typography.Text>
                                            {item.short_name && <Typography.Text type="secondary">{item.name}</Typography.Text>}
                                            <div>
                                                {item.gos && (
                                                    <Tag>
                                                        {item.gos === true
                                                            ? t('events.content.organizer.gov')
                                                            : t('events.content.organizer.private')}
                                                    </Tag>
                                                )}
                                                {item.golfil && (
                                                    <Tag>
                                                        {item.golfil === 'гол'
                                                            ? t('events.content.organizer.head')
                                                            : t('events.content.organizer.branch')}
                                                    </Tag>
                                                )}
                                            </div>
                                            <Typography.Text type="secondary">{item.post}</Typography.Text>
                                        </Space>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name={'region_id'}
                            label={t('events.content.region.title')}
                            rules={[{ required: true, message: t('events.content.required') || '' }]}
                        >
                            <Select
                                options={getRegionSelectorContent()}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            />
                        </Form.Item>
                        <Form.Item name="address" label={t('events.content.address.title')} tooltip={t('events.content.address.hint')} rules={[]}>
                            <TextArea rows={2} placeholder={t('events.content.address.placeholder') || ''} />
                        </Form.Item>
                        <Row gutter={12} align="middle">
                            <Col span={viewModel.isPublished ? 18 : 24}>
                                <Form.Item name="url" label={t('events.content.url.title')} rules={[]}>
                                    <Input placeholder={t('events.content.url.placeholder') || ''} />
                                </Form.Item>
                            </Col>
                            {viewModel.isPublished && (
                                <Col span={6}>
                                    <Form.Item name="url_click_count" label={t('events.content.url.click_count')} rules={[]}>
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>

                        {viewModel.isPublished && (
                            <Form.Item label={t('events.content.member_count.title')}>
                                <Tooltip title={t('events.content.member_count.show')}>
                                    <Button
                                        style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                                        type="text"
                                        onClick={onClickShowMembers}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                                            <UsergroupAddOutlined style={{ fontSize: 18, color: 'gray' }} />
                                            <Typography.Text style={{ fontSize: 18, paddingTop: 2, paddingLeft: 4 }}>
                                                {new Intl.NumberFormat('ru-ru', {}).format(viewModel.event?.members_count || 0)}
                                            </Typography.Text>
                                        </div>
                                    </Button>
                                </Tooltip>
                            </Form.Item>
                        )}
                    </div>

                    <br />
                    <Divider orientation="left">{t('events.content.description.title')}</Divider>
                    <div className="edit-content-view">
                        <Form.Item name="description" rules={[]}>
                            <WYSIWYGEditor
                                editorDefaultValue={viewModel.event.description || ''}
                                placeholder={t('events.content.description.placeholder') || ''}
                                languageCode={i18n.language}
                                onChange={(content: string) => form.setFieldValue('description', content)}
                            />
                        </Form.Item>
                    </div>

                    <br />
                    <Divider orientation="left">{t('events.content.date_ranges')}</Divider>
                    <div className="edit-content-view">
                        <Form.Item
                            name={'timezone_id'}
                            label={t('events.content.timezone.title')}
                            tooltip={t('events.content.timezone.hint')}
                            rules={[{ required: true, message: t('events.content.required') || '' }]}
                        >
                            <Select options={getTimezoneSelectorContent()} />
                        </Form.Item>
                        <Divider />
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    name="announcing_date"
                                    label={t('events.content.announcing_date.title')}
                                    tooltip={t('events.content.announcing_date.hint')}
                                    rules={[]}
                                >
                                    <DatePicker style={{ width: '100%' }} showNow={false} showTime format={'DD.MM.YYYY HH:mm (Z)'} />
                                </Form.Item>
                                <Form.Item
                                    name="registration_begin_date"
                                    label={t('events.content.registration_begin_date.title')}
                                    tooltip={t('events.content.registration_begin_date.hint')}
                                    rules={[]}
                                >
                                    <DatePicker style={{ width: '100%' }} showNow={false} showTime format={'DD.MM.YYYY HH:mm (Z)'} />
                                </Form.Item>
                                <Form.Item
                                    name="registration_end_date"
                                    label={t('events.content.registration_end_date.title')}
                                    tooltip={t('events.content.registration_end_date.hint')}
                                    rules={[]}
                                >
                                    <DatePicker style={{ width: '100%' }} showNow={false} showTime format={'DD.MM.YYYY HH:mm (Z)'} />
                                </Form.Item>
                                <Divider />
                                <Form.Item
                                    name="begin_date"
                                    label={t('events.content.begin_date.title')}
                                    tooltip={t('events.content.begin_date.hint')}
                                    rules={[{ required: true, message: t('events.content.required') || '' }]}
                                >
                                    <DatePicker style={{ width: '100%' }} showNow={false} showTime format={'DD.MM.YYYY HH:mm (Z)'} />
                                </Form.Item>
                                <Form.Item
                                    name="end_date"
                                    label={t('events.content.end_date.title')}
                                    tooltip={t('events.content.end_date.hint')}
                                    rules={[{ required: true, message: t('events.content.required') || '' }]}
                                >
                                    <DatePicker style={{ width: '100%' }} showNow={false} showTime format={'DD.MM.YYYY HH:mm (Z)'} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <br />
                                <Timeline mode="left" items={getTimelineItemsForListCard()} />
                            </Col>
                        </Row>
                    </div>

                    <Divider orientation="left">{t('events.content.terms.title')}</Divider>
                    <div className="edit-content-view">
                        <Form.Item name="terms" rules={[]}>
                            <WYSIWYGEditor
                                editorDefaultValue={viewModel.event.terms || ''}
                                placeholder={t('events.content.terms.placeholder') || ''}
                                languageCode={i18n.language}
                                onChange={(content: string) => form.setFieldValue('terms', content)}
                            />
                        </Form.Item>
                    </div>

                    <Divider orientation="left">{t('events.content.comment.title')}</Divider>
                    <div className="edit-content-view">
                        <Form.Item name="comment" rules={[]}>
                            <WYSIWYGEditor
                                editorDefaultValue={viewModel.event.comment || ''}
                                placeholder={t('events.content.comment.placeholder') || ''}
                                languageCode={i18n.language}
                                onChange={(content: string) => form.setFieldValue('comment', content)}
                            />
                        </Form.Item>
                    </div>
                </Form>
                <br />
                <Divider orientation="left">{t('events.content.contacts.title')}</Divider>
                <ContactsTable data={form.getFieldValue('contact') || []} maxContactsCount={10} onUpdate={onUpdateContacts} />
                <br />

                <ResultSelector
                    visible={isResultSelectorVisible}
                    onClose={() => {
                        setIsResultSelectorVisible(false);
                    }}
                    onSaveResult={(result: ResultData) => {
                        form.setFieldValue('result_id', result.id);
                        onFormValueChange({ result_id: result.id });
                        viewModel.onSetEventResults([result]);
                    }}
                    onSaveResultType={(resultType) => {
                        viewModel.onSetEventResultTypeId(resultType.id);
                    }}
                    resultTypes={viewModel.resultTypeDictionary}
                    result={viewModel.event.results && viewModel.event.results.length > 0 ? viewModel.event.results[0] : undefined}
                    resultTypeId={viewModel.event.result_type_id}
                />
            </Spin>
        );
    };

    const editFiles = () => {
        return (
            <Spin spinning={viewModel.isLoading}>
                <FilesCatalogView
                    name="file"
                    uploadURL={viewModel.getUploaderURL()}
                    getHeaders={viewModel.getUploaderHeaders()}
                    downloadLink={(uid: string) => viewModel.getDownloadUrl(uid)}
                    onRemove={(uid: string) => viewModel.deleteFile(uid)}
                    onEditTitle={(uid: string, title: string) => viewModel.onEditFileTitle(uid, title)}
                    defaultFileList={(viewModel.files || []).map((item) => {
                        return {
                            uid: `${item.uuid}`,
                            name: item.name,
                            title: item.title,
                            size: item.size,
                            lastModifiedDate: stringToDate(item.created_at),
                            downloads: item.downloads
                        };
                    })}
                />
            </Spin>
        );
    };

    if (!viewModel.isPublished) {
        return (
            <div>
                <br />
                {contextHolder}
                <Row gutter={12} justify="space-between" align="middle">
                    <Col span={14} style={{ backgroundColor: 'white', padding: '10px 30px 10px 30px', borderRadius: 10 }}>
                        <Steps
                            items={[
                                {
                                    title: t('events.edit.title'),
                                    icon: <FileTextOutlined />
                                },
                                {
                                    title: t('events.content.files.title'),
                                    icon: <PaperClipOutlined />
                                },
                                {
                                    title: t('common.done'),
                                    icon: <CheckCircleOutlined />
                                }
                            ]}
                            current={creatorCurrentStep}
                        />
                    </Col>
                    <Col>
                        <Row gutter={12}>
                            {creatorCurrentStep === 0 && (
                                <Col>
                                    <Button
                                        onClick={() => {
                                            onClickCancel();
                                        }}
                                    >
                                        {creatorCurrentStep === 0 ? t('common.cancel') : t('common.back')}
                                    </Button>
                                </Col>
                            )}

                            <Col>
                                <Button
                                    type="primary"
                                    disabled={creatorCurrentStep === 2 || viewModel.isLoading}
                                    loading={viewModel.isLoading}
                                    onClick={() => {
                                        switch (creatorCurrentStep) {
                                            case 0:
                                                onClickSave();
                                                break;
                                            case 1:
                                                setCreatorCurrentStep(creatorCurrentStep + 1);
                                                break;
                                            default:
                                                break;
                                        }
                                    }}
                                >
                                    {t('common.next')}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div style={{ paddingTop: 30 }}></div>
                {creatorCurrentStep === 0 && editForm()}
                {creatorCurrentStep === 1 && editFiles()}
                {creatorCurrentStep === 2 && (
                    <Result
                        status="success"
                        title={t('common.done')}
                        extra={[
                            <Button type="primary" onClick={onClickCancel}>
                                Список мероприятий
                            </Button>
                        ]}
                    />
                )}
                <br />
            </div>
        );
    } else {
        return (
            <div>
                <br />
                {contextHolder}
                <Tabs
                    tabBarExtraContent={{
                        right: (
                            <Row gutter={12} justify="end" align="middle">
                                <Col>
                                    <Button onClick={onClickCancel}>{t('common.cancel')}</Button>
                                </Col>
                                <Col>
                                    <Button type="primary" onClick={onClickSave} loading={viewModel.isLoading} disabled={viewModel.isLoading}>
                                        {viewModel.isPublished ? t('common.save') : t('static_page.publish')}
                                    </Button>
                                </Col>
                            </Row>
                        )
                    }}
                >
                    <TabPane tab={t('events.edit.title')} key={'content'}>
                        {editForm()}
                    </TabPane>

                    <TabPane tab={t('events.content.files.title')} key={'files'}>
                        {editFiles()}
                    </TabPane>

                    <TabPane tab={t('components.history-logger.title')} key={'test'}>
                        {
                            <HistoryView
                                isLoading={viewModel.isHistoryLoading}
                                items={viewModel.historyItems}
                                itemsTotal={viewModel.historyTotal}
                                currentItem={viewModel.historyCurrentItem}
                                loadNextItems={() => viewModel.fetchNextHistoryItemsPage()}
                                loadHistoryItem={(historyId: number) => viewModel.onSelectHistoryItem(historyId)}
                            />
                        }
                    </TabPane>
                </Tabs>
                <br />
            </div>
        );
    }
});

export default EventEditView;
