import { useViewModel } from '@models/model';
import { Button, Col, Divider, Empty, Form, Row, Select, Space, Typography, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

import { useEffect } from 'react';
import { FootprintFilesViewModel } from './digital-footprint-files.view.model';
import { observer } from 'mobx-react-lite';
import FilesTable from './table';
import { DigitalFootprintFileData } from '@models/digital-footprint/files';
import { ObjectLiteral } from '@utils/object.utils';
import { FilterOutlined, PlusCircleOutlined } from '@ant-design/icons';

const DigitalFootprintFilesView: React.FC = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [search, setSearch] = useSearchParams();
    const setSearchParams = (params: any) => setSearch(params);

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    let viewModel = useViewModel(() => new FootprintFilesViewModel({ t, searchParams: search, setSearchParams, showError: showErrorNotification }));
    const [filterForm] = Form.useForm();

    const onCreate = () => {
        navigate('create');
    };

    useEffect(() => {
        updateIfNeeded();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const updateIfNeeded = async () => {
        const isResetNeeded = await viewModel.updateSearchParamsIfNeeded(search);
        if (isResetNeeded) {
            filterForm.resetFields();
        }
    };

    const showEvent = (id: number) => {
        navigate({
            pathname: `/events-manage/edit/${id}`
        });
    };
    const showMember = (id: number) => {
        navigate({
            pathname: '/members-manage',
            search: createSearchParams({
                edit: `${id}`
            }).toString()
        });
    };

    const getFileTypeSelectorContent = () =>
        viewModel.fileTypesDictionary.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        });

    const onFilterValueChange = () => {
        const valFromFields: ObjectLiteral = filterForm.getFieldsValue();
        viewModel.onFilterValueChange(valFromFields);
    };

    const filters = () => {
        return (
            <div>
                <Form form={filterForm} initialValues={viewModel.currentFilter} layout="vertical" onValuesChange={onFilterValueChange}>
                    <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <Form.Item
                                name="event_id"
                                label={
                                    <div>
                                        <FilterOutlined style={{ fontSize: 16, color: 'gray', marginRight: 5 }} />
                                        <Typography.Text strong>{t('digital_footprint.filter.event.title')}</Typography.Text>
                                    </div>
                                }
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    notFoundContent={<Empty description={''} />}
                                    placeholder={t('digital_footprint.filter.event.placeholder')}
                                    onSearch={(value: string) => viewModel.searchEvents(value)}
                                    loading={viewModel.isEventsLoading}
                                    filterOption={false}
                                >
                                    {viewModel.events.map((item, i) => (
                                        <Select.Option value={item.id} key={`org_${i}`}>
                                            <Typography.Text>{item.name}</Typography.Text>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="member_id"
                                label={
                                    <div>
                                        <FilterOutlined style={{ fontSize: 16, color: 'gray', marginRight: 5 }} />
                                        <Typography.Text strong>{t('digital_footprint.filter.member.title')}</Typography.Text>
                                    </div>
                                }
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    notFoundContent={<Empty description={''} />}
                                    placeholder={t('digital_footprint.filter.member.placeholder')}
                                    onSearch={(value: string) => viewModel.searchMembers(value)}
                                    loading={viewModel.isMembersLoading}
                                    filterOption={false}
                                >
                                    {viewModel.members.map((item, i) => (
                                        <Select.Option value={item.id} key={`org_${i}`}>
                                            <Typography.Text>
                                                {item.last_name} {item.first_name} {item.middle_name}
                                            </Typography.Text>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="type_id"
                                label={
                                    <div>
                                        <FilterOutlined style={{ fontSize: 16, color: 'gray', marginRight: 5 }} />
                                        <Typography.Text strong>{t('digital_footprint.filter.type.title')}</Typography.Text>
                                    </div>
                                }
                            >
                                <Select
                                    options={getFileTypeSelectorContent()}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    allowClear
                                    placeholder={t('digital_footprint.filter.type.placeholder')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    };

    return (
        <div>
            {contextHolder}
            <Row gutter={12} align="middle" justify="space-between">
                <Col>
                    <Space>
                        <Typography.Title>{t('digital_footprint.files.title')}</Typography.Title>
                        <Typography.Title style={{ marginTop: 25 }} level={2} type="secondary">
                            {viewModel.filesTotal}
                        </Typography.Title>
                    </Space>
                </Col>
                <Col>
                    <Button type="primary" icon={<PlusCircleOutlined />} onClick={onCreate}>
                        {t('common.create')}
                    </Button>
                </Col>
            </Row>
            <Divider style={{ marginTop: 0 }} />
            <Row gutter={[8, 8]} justify="center">
                <Col span={24}>{filters()}</Col>
                <Col span={24}>
                    <FilesTable
                        currentPage={viewModel.currentPage}
                        loading={viewModel.isLoading}
                        rows={viewModel.files}
                        total={viewModel.filesTotal}
                        pageSize={viewModel.pageSize}
                        onChangePagination={viewModel.onChangePagination}
                        onDownload={(footprintFile: DigitalFootprintFileData) => viewModel.downloadFile(footprintFile)}
                        onDelete={(footprintFile: DigitalFootprintFileData) => viewModel.delete(footprintFile.id)}
                        onShowEvent={(footprintFile: DigitalFootprintFileData) => showEvent(footprintFile.event.id!)}
                        onShowMember={(footprintFile: DigitalFootprintFileData) => showMember(footprintFile.member.id)}
                    />
                </Col>
            </Row>
        </div>
    );
});

export default DigitalFootprintFilesView;
