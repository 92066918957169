import { DictionaryModel, EventMemberFileTypeDictionary } from '@models/dictionary-model';
import { DigitalFootprintFileData, DigitalFootprintFilesModel } from '@models/digital-footprint/files';
import { EventData, EventModel } from '@models/event-model';
import { MemberData, MemberModel } from '@models/member-model';
import { APIUtilError } from '@utils/api';
import { makeAutoObservable, runInAction } from 'mobx';

export interface FootprintFilesCreateViewModelStartParams {
    t: any;
    showError: (title: string, description: string) => void;
    showSuccess: (title: string, description: string) => void;
    close: () => void;
    clearEvent: () => void;
    clearMember: () => void;
}

export type CreateStates = 'create' | 'upload' | 'done';

export class FootprintFileCreateViewModel {
    private dictionaryModel = new DictionaryModel();
    private digitalFootprintFilesModel = new DigitalFootprintFilesModel();
    private eventModel = new EventModel();
    private memberModel = new MemberModel();

    public fileTypesDictionary: EventMemberFileTypeDictionary[] = [];

    public isLoading: boolean = false;

    public currentStep: CreateStates = 'create';
    public file: DigitalFootprintFileData = new DigitalFootprintFileData();

    public isEventsLoading: boolean = false;
    public events: EventData[] = [];
    public selectedEvent?: EventData;

    public isMembersLoading: boolean = false;
    public members: MemberData[] = [];
    public selectedMember?: MemberData;

    public segmentedOptions: string[] = [];
    public selectedSegment: string = '';

    public isFileUpdloaded: boolean = false;

    constructor(private props: FootprintFilesCreateViewModelStartParams) {
        this.segmentedOptions = [
            props.t('digital_footprint.files.create.segmented.event_member') || '',
            props.t('digital_footprint.files.create.segmented.member_event') || ''
        ];
        this.selectedSegment = this.segmentedOptions[0];

        makeAutoObservable(this);
        this.wakeUpSir();
    }

    public onChangeSegmented = (value: string) => {
        this.props.clearEvent();
        this.props.clearMember();
        runInAction(() => {
            this.selectedSegment = value;
            this.selectedEvent = undefined;
            this.events = [];
            this.selectedMember = undefined;
            this.members = [];
        });
    };

    public isEventFirst = () => {
        return this.selectedSegment === this.segmentedOptions[0];
    };
    public isMemberFirst = () => {
        return this.selectedSegment === this.segmentedOptions[1];
    };
    public onSelectMember = (value: any) => {
        const selectedMember = this.members.find((item) => item.id === value);
        runInAction(() => {
            this.selectedMember = selectedMember;
        });
        switch (this.isEventFirst()) {
            case true:
                break;
            case false:
                this.props.clearEvent();
                runInAction(() => {
                    this.selectedEvent = undefined;
                    this.events = [];
                });
                break;
        }
    };
    public onSelectEvent = (value: any) => {
        const selectedEvent = this.events.find((item) => item.id === value);
        runInAction(() => {
            this.selectedEvent = selectedEvent;
        });
        switch (this.isEventFirst()) {
            case true:
                this.props.clearMember();
                runInAction(() => {
                    this.selectedMember = undefined;
                    this.members = [];
                });
                break;
            case false:
                break;
        }
    };

    private async wakeUpSir() {
        await this.fetchFileTypesDictionary();
    }

    public onSave = async (footprintFileData: DigitalFootprintFileData) => {
        await this.save(footprintFileData);
    };

    private save = async (footprintFileData: DigitalFootprintFileData) => {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.digitalFootprintFilesModel.create(footprintFileData);
            runInAction(() => {
                this.file = result.data;
                this.currentStep = 'upload';
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.save'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    public getUploaderURL() {
        if (!this.file.id) return '';
        return this.digitalFootprintFilesModel.uploadURL(this.file.id);
    }

    public getUploaderHeaders() {
        return this.digitalFootprintFilesModel.uploadHeaders;
    }

    public onChangeFileList(list: any) {
        runInAction(() => {
            this.isFileUpdloaded = list && list.length > 0;
        });
    }

    public onFinishUpload = () => {
        runInAction(() => {
            this.currentStep = 'done';
        });
        this.props.showSuccess(this.props.t('common.saved'), '');
        setTimeout(() => {
            this.props.close();
        }, 1000);
    };

    private async fetchFileTypesDictionary() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'event-member-file-type' });
            runInAction(() => {
                this.fileTypesDictionary = result.data as EventMemberFileTypeDictionary[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public async searchEvents(query: string) {
        runInAction(() => {
            this.isEventsLoading = true;
        });
        try {
            const result = await this.eventModel.getEvents({
                search: query,
                page_size: 10,
                member_id: this.isEventFirst() ? undefined : this.selectedMember ? [this.selectedMember.id] : undefined
            });
            runInAction(() => {
                this.events = result.data as EventData[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isEventsLoading = false;
            });
        }
    }

    public async searchMembers(query: string) {
        runInAction(() => {
            this.isMembersLoading = true;
        });
        try {
            const result = await this.memberModel.getMembers({
                search: query,
                page_size: 10,
                event_id: this.isMemberFirst() ? undefined : this.selectedEvent?.id
            });
            runInAction(() => {
                this.members = result.data as MemberData[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isMembersLoading = false;
            });
        }
    }
}
