import * as React from 'react';
import { useState } from 'react';
import { Layout, Menu } from 'antd';
import type { MenuProps } from 'antd';
import {
    UserOutlined,
    DotChartOutlined,
    FileTextOutlined,
    CalendarOutlined,
    RadarChartOutlined,
    UnorderedListOutlined,
    ClusterOutlined,
    UsergroupAddOutlined,
    RocketOutlined,
    DatabaseOutlined,
    DeploymentUnitOutlined,
    CalculatorOutlined,
    ExperimentOutlined,
    FileDoneOutlined,
    NotificationOutlined,
    FolderOpenOutlined,
    MailOutlined,
    FilterOutlined,
    CloudOutlined,
    MenuOutlined,
    NodeIndexOutlined,
    SisternodeOutlined,
    TrophyOutlined,
    SoundOutlined,
    ReadOutlined,
    ApiOutlined,
    DeliveredProcedureOutlined,
    FileExcelOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { flattenDeep } from 'lodash';
import { ReactComponent as PutpLogo } from '@assets/images/logo.svg';
import { RootModel } from '@models/root-model';
import { observer } from 'mobx-react-lite';
interface Props {
    permissions: string[];
}

interface TreeItem {
    type?: string | undefined;
    title: string;
    icon?: any | undefined;
    key?: string | undefined;
    link?: string | undefined;
    subs?: TreeItem[] | undefined;
    permission?: string | undefined;
    disabled?: boolean | undefined;
}

const menuTree: TreeItem[] = [
    {
        title: 'sidebar.dashboards.title',
        type: 'group'
    },
    // {
    //     title: 'sidebar.dashboards.test',
    //     key: '/',
    //     link: '/',
    //     icon: IeOutlined
    // },
    {
        title: 'sidebar.dashboards.main',
        key: 'events-dashboard',
        link: 'events-dashboard',
        icon: CalendarOutlined
    },
    {
        title: 'sidebar.content_manage.title',
        type: 'group'
    },
    {
        title: 'sidebar.content_manage.indicator.manage',
        key: 'indicator-manage',
        link: 'indicator-manage',
        icon: DotChartOutlined
    },
    {
        title: 'sidebar.content_manage.static_page.manage',
        key: 'static-page-manage',
        link: 'static-page-manage',
        icon: FileTextOutlined
    },
    {
        title: 'sidebar.content_manage.users.manage',
        key: 'users-manage',
        link: 'users-manage',
        icon: UserOutlined
    },
    {
        title: 'sidebar.tech_manage.title',
        type: 'group'
    },
    {
        title: 'sidebar.tech_manage.results',
        key: 'result-types-manage',
        link: 'result-types-manage',
        icon: RadarChartOutlined
    },
    {
        title: 'sidebar.tech_manage.events',
        key: 'events-manage',
        link: 'events-manage',
        icon: UnorderedListOutlined
    },
    {
        title: 'sidebar.tech_manage.operators',
        key: 'operators-manage',
        link: 'operators-manage',
        icon: ClusterOutlined
    },
    {
        title: 'sidebar.tech_manage.members',
        key: 'members-manage',
        link: 'members-manage',
        icon: UsergroupAddOutlined
    },
    {
        title: 'sidebar.tech_manage.startups',
        key: 'startups-manage',
        link: 'startups-manage',
        icon: RocketOutlined
    },
    {
        title: 'sidebar.tech_manage.rids',
        key: 'rids-manage',
        link: 'rids-manage',
        icon: ExperimentOutlined
    },
    {
        title: 'sidebar.tech_manage.finances',
        key: 'finances-manage',
        link: 'finances-manage',
        icon: CalculatorOutlined
    },
    {
        title: 'sidebar.tech_manage.popularization.title',
        icon: NotificationOutlined,
        subs: [
            {
                title: 'sidebar.tech_manage.popularization.events',
                icon: UnorderedListOutlined,
                key: 'popularization-events-manage',
                link: 'events-manage'
            },
            {
                title: 'sidebar.tech_manage.popularization.events_top_50',
                icon: TrophyOutlined,
                key: 'popularization-events-manage-top-50',
                link: 'events-manage?top_50=true'
            },

            {
                title: 'sidebar.tech_manage.popularization.infopovods',
                icon: SoundOutlined,
                key: 'popularization-infopovod',
                link: 'popularization-infopovod'
            },
            {
                title: 'sidebar.tech_manage.popularization.resources',
                icon: ReadOutlined,
                key: 'popularization-resource',
                link: 'popularization-resource'
            }
        ]
    },
    {
        title: 'sidebar.tech_manage.digital_footprint.title',
        icon: NodeIndexOutlined,
        subs: [
            {
                title: 'sidebar.tech_manage.digital_footprint.users',
                icon: NodeIndexOutlined,
                link: 'digital-footprint-users',
                key: 'digital-footprint-users'
            },
            {
                title: 'sidebar.tech_manage.digital_footprint.files',
                icon: SisternodeOutlined,
                link: 'digital-footprint-files',
                key: 'digital-footprint-files'
            }
        ]
    },
    {
        title: 'sidebar.tech_manage.airflow',
        key: 'airflow-manage',
        link: 'airflow-manage',
        icon: DeploymentUnitOutlined
    },
    {
        title: 'sidebar.tech_manage.dictionaries',
        key: 'dictionaries-manage',
        link: 'dictionaries-manage',
        icon: DatabaseOutlined
    },
    {
        title: 'sidebar.tech_manage.dataTransfer.title',
        icon: DeliveredProcedureOutlined,
        subs: [
            { title: 'sidebar.tech_manage.dataTransfer.reports', icon: FileExcelOutlined, link: 'reports-manage', key: 'reports-manage' },
            { title: 'sidebar.tech_manage.dataTransfer.api', icon: ApiOutlined, link: 'reports-api-manage', key: 'reports-api-manage' }
        ]
    },
    {
        title: 'sidebar.tech_manage.statements',
        key: 'statements-manage',
        link: 'statements-manage',
        icon: FilterOutlined
    },
    {
        title: 'sidebar.tech_manage.oauthClients',
        key: 'oauth-clients-manage',
        link: 'oauth-clients-manage',
        icon: CloudOutlined
    },
    {
        title: 'sidebar.tech_manage.templates.title',
        icon: FolderOpenOutlined,
        subs: [
            { title: 'sidebar.tech_manage.templates.emails', icon: MailOutlined, link: 'email-templates', key: 'email-templates' },
            { title: 'sidebar.tech_manage.templates.notices', icon: NotificationOutlined, link: 'notice-templates', key: 'notice-templates' }
        ]
    },
    {
        title: 'sidebar.tech_manage.website_menu',
        key: 'website-menu-manage',
        link: 'website-menu-manage',
        icon: MenuOutlined
    }
];

const Sidebar: React.FC<Props> = observer(() => {
    const { t } = useTranslation();
    const location = useLocation();

    const getMenuItems: MenuProps['items'] = menuTree.map((item, index) => {
        const key = item.key || `menu_key_${String(index + 1)}`;

        //TODO: добавить проверку на permission
        return {
            key: key,
            icon: item.icon ? React.createElement(item.icon) : undefined,
            label: item.link && item.disabled !== true ? <Link to={item.link}>{t(item.title)}</Link> : t(item.title),
            type: item.type,
            disabled: item.disabled,
            children: item.subs
                ? item.subs.map((subItem, j) => {
                      //TODO: добавить проверку на permission
                      const subKey = subItem.key || `${key}_${j}`;
                      return {
                          key: subKey,
                          icon: subItem.icon ? React.createElement(subItem.icon) : undefined,
                          label: subItem.link ? <Link to={subItem.link}>{t(subItem.title)}</Link> : t(subItem.title),
                          disabled: subItem.disabled
                      };
                  })
                : undefined
        };
    });

    const [menuItems] = useState(getMenuItems);

    const extractSelectedKeysFromPath = (path: string) => {
        const allKeys = flattenDeep(
            menuTree.map((item) => {
                if (item.subs) {
                    return [...item.subs.map((subItem) => subItem.key)];
                } else {
                    return item.key;
                }
            })
        );

        let parts =
            path.length === 1
                ? ['/']
                : path.split('/').map((item) => {
                      return item === '' ? '/' : item;
                  });

        if (parts.length > 1) {
            parts = parts.filter((item) => item !== '/');
        }

        return parts.filter((item) => {
            return allKeys.includes(item);
        });
    };

    return (
        <Layout.Sider width={300} style={{ backgroundColor: '#A533FF' }} collapsed={RootModel.appModel.isSidebarCollapsed}>
            <div style={{ display: 'flex', justifyContent: 'space-between', height: '65px' }}>
                <PutpLogo style={{ height: '60px', marginLeft: RootModel.appModel.isSidebarCollapsed ? '-2px' : '-10px', marginTop: '3px' }} />
                {!RootModel.appModel.isSidebarCollapsed && (
                    <div
                        style={{
                            height: '35px',
                            marginTop: '15px',
                            paddingLeft: '12px',
                            paddingRight: '20px',
                            paddingBottom: '15px',
                            whiteSpace: 'pre-line',
                            fontWeight: 600,
                            fontSize: '14px',
                            lineHeight: '17px',
                            color: '#fff',
                            borderLeft: '2px solid #fff'
                        }}
                    >
                        {t('root.title')}
                    </div>
                )}
            </div>
            <Menu
                mode="inline"
                defaultSelectedKeys={['events-dashboard']}
                style={{ height: '100%', borderRight: 0 }}
                items={menuItems}
                selectedKeys={
                    extractSelectedKeysFromPath(location.pathname).length === 0
                        ? ['events-dashboard']
                        : extractSelectedKeysFromPath(location.pathname)
                }
            />
        </Layout.Sider>
    );
});

export default Sidebar;
