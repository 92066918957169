import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { UsersManageViewModel } from './users.view.model';
import { Button, Col, Divider, Form, Modal, Row, Spin, Typography, notification } from 'antd';
import { UsersTable } from './users.view.table';
import { User } from '@models/users-model';
import { useViewModel } from '@models/model';
import { Input, Space } from 'antd';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ObjectLiteral } from '@utils/object.utils';

const UsersManageView: React.FC = observer(() => {
    const { t } = useTranslation();
    const [search, setSearch] = useSearchParams();
    const navigate = useNavigate();
    const setSearchParams = (params: any) => setSearch(params);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [createForm] = Form.useForm();

    const [notifyer, contextHolder] = notification.useNotification();
    const showSuccessNotification = (title: string, description: string) => {
        notifyer.success({
            message: title,
            description: description
        });
    };
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const onEdit = (userId: string, onNewTab: boolean) => {
        if (onNewTab) {
            window.open(`/users-manage/edit/${userId || ''}`, '_blank', 'noreferrer');
        } else {
            navigate(`edit/${userId}`);
        }
    };

    const viewModel = useViewModel(
        () =>
            new UsersManageViewModel({
                t,
                searchParams: search,
                setSearchParams,
                showSuccess: showSuccessNotification,
                showError: showErrorNotification,
                showUserEdit: onEdit
            })
    );
    const [filterForm] = Form.useForm();

    useEffect(() => {
        updateIfNeeded();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const updateIfNeeded = async () => {
        const isResetNeeded = await viewModel.updateSearchParamsIfNeeded(search);
        if (isResetNeeded) {
            filterForm.resetFields();
        }
    };

    const onFilterValueChange = () => {
        const valFromFields: ObjectLiteral = filterForm.getFieldsValue();
        viewModel.onFilterValueChange(valFromFields);
    };

    const onClickOpenCreate = () => {
        setIsModalOpen(true);
    };

    const onClickCreateCancel = () => {
        setIsModalOpen(false);
    };

    const onClickCreate = () => {
        createForm.submit();
    };

    const onSuccessCreateFormValidation = async () => {
        let email: any = createForm.getFieldValue('email');
        viewModel.createUser(email);
    };

    return (
        <div>
            {contextHolder}

            <Row gutter={12} align="middle" justify="space-between">
                <Col>
                    <Space>
                        <Typography.Title>{t('users.title')}</Typography.Title>
                        <Typography.Title style={{ marginTop: 25 }} level={2} type="secondary">
                            {viewModel.usersTotal}
                        </Typography.Title>
                    </Space>
                </Col>
                <Col>
                    <Button type="primary" icon={<PlusCircleOutlined />} onClick={onClickOpenCreate}>
                        {t('common.create')}
                    </Button>
                </Col>
            </Row>

            <Modal
                title={t('users.create.title')}
                open={isModalOpen}
                onOk={onClickCreate}
                onCancel={onClickCreateCancel}
                confirmLoading={viewModel.isCreateLoading}
            >
                <Space direction="vertical">
                    <Typography.Text type="secondary">{t('users.create.description')}</Typography.Text>
                    <Spin spinning={viewModel.isCreateLoading}>
                        <Form form={createForm} onFinish={onSuccessCreateFormValidation}>
                            <Form.Item
                                name="email"
                                label="E-mail"
                                rules={[
                                    {
                                        type: 'email',
                                        message: t('users.create.incorrect') || ''
                                    },
                                    {
                                        required: true,
                                        message: t('users.create.required') || ''
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Form>
                    </Spin>
                </Space>
            </Modal>

            <Divider style={{ marginTop: 0 }} />
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <Form form={filterForm} initialValues={viewModel.currentFilter} layout="vertical" onValuesChange={onFilterValueChange}>
                    <Form.Item style={{ margin: 0 }} name={'search'}>
                        <Input prefix={<SearchOutlined />} size="large" allowClear placeholder={t('users.filter.search.placeholder') || ''} />
                    </Form.Item>
                </Form>
                <UsersTable
                    currentPage={viewModel.currentPage}
                    loading={viewModel.isLoading}
                    pageSize={viewModel.pageSize}
                    rows={viewModel.users}
                    total={viewModel.usersTotal}
                    onChangePagination={viewModel.onChangePagination}
                    onEdit={(user: User) => onEdit(user.id!, true)}
                />
            </Space>
        </div>
    );
});

export default UsersManageView;
