import { APIInstance, APIUtilError, BaseURL } from '@utils/api';
import { deleteUndefined } from '@utils/object.utils';
import {
    GetUsersResponse,
    UserFullResponse,
    UsersGetFilter,
    UserOrgResponse,
    UserInstResponse,
    UserActivityResponse,
    UserMemberResponse,
    UserMemberRawResponse,
    UserInstPermissionResponse,
    UserInstPermissionsResponse,
    UserInstPermissionsRequestResponse,
    UserInstPermissionsRequestsResponse,
    UserPermissionsResponse,
    UserOrgFilesResponse,
    UserRoleResponse,
    UserHistoryLoggerDataListResponse,
    UserHistoryLoggerDataItemResponse,
    UserCreateResponse
} from './user.contract';
import { RootModel } from '@models/root-model';

export interface UserActivityParams {
    userId: string;
    year?: number | null;
    search?: string;
    limit: number;
    offset: number;
}

export class UserModel {
    public async getUsers(params: UsersGetFilter): Promise<GetUsersResponse> {
        try {
            const result = await APIInstance.get<GetUsersResponse>('/cp-manager/user', {
                params: deleteUndefined({
                    search: params.search,
                    limit: params.page_size,
                    offset: params.page_size && params.page ? params.page_size * (params.page - 1) : 0
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async createUser(email: string): Promise<UserCreateResponse> {
        try {
            const reuslt = await APIInstance.post<UserCreateResponse>(`/cp-manager/user`, { email });
            return reuslt.data;
        } catch (error) {
            throw error;
        }
    }

    public async deleteUser(userId: string) {
        try {
            await APIInstance.delete(`/cp-manager/user/${userId}`);
        } catch (error) {
            throw error;
        }
    }

    public async getRolesDict(): Promise<UserRoleResponse> {
        try {
            const result = await APIInstance.get<UserRoleResponse>(`/user/role`);
            return result.data;
        } catch (error) {
            throw error as APIUtilError;
        }
    }

    public async getUserById(userId: string): Promise<UserFullResponse> {
        try {
            const result = await APIInstance.get<UserFullResponse>(`/cp-manager/user/${userId}/full_info`);
            return result.data;
        } catch (error) {
            throw error as APIUtilError;
        }
    }

    public async searhOrg(search: string = ''): Promise<UserOrgResponse> {
        try {
            const result = await APIInstance.get<UserOrgResponse>(`/user/organisation/search`, { params: { search } });
            return result.data;
        } catch (error) {
            throw error as APIUtilError;
        }
    }

    public async getUserActivity(params: UserActivityParams): Promise<UserActivityResponse> {
        try {
            const result = await APIInstance.get<UserActivityResponse>(`/cp-manager/user/${params.userId}/activity`, { params });
            return result.data;
        } catch (error) {
            throw error as APIUtilError;
        }
    }

    public async deleteActivityItem(user_id: string, activity_id: number, status: boolean = false) {
        try {
            await APIInstance.put(`/cp-manager/user/${user_id}/activity/${activity_id}`, { is_deleted: status });
        } catch (error) {
            throw error as APIUtilError;
        }
    }

    public async searhInst(search: string = ''): Promise<UserInstResponse> {
        try {
            const result = await APIInstance.get<UserInstResponse>(`/user/institution/search`, { params: { search } });
            return result.data;
        } catch (error) {
            throw error as APIUtilError;
        }
    }

    public async updateUser(userId: string, data: any): Promise<any> {
        try {
            await APIInstance.patch(`/cp-manager/user/${userId}`, { ...data });
        } catch (error) {
            throw error as APIUtilError;
        }
    }

    public async resetUserPassword(userId: string) {
        try {
            return await APIInstance.post(`/cp-manager/user/${userId}/reset-password`);
        } catch (error) {
            throw error as APIUtilError;
        }
    }

    public async bindOrg(userId: string, dadataId: string) {
        try {
            return await APIInstance.put(`/cp-manager/user/${userId}/organisation`, { dadata_id: dadataId });
        } catch (error) {
            throw error as APIUtilError;
        }
    }

    public async deleteUserOrg(userId: string) {
        try {
            return await APIInstance.delete(`/cp-manager/user/${userId}/organisation`);
        } catch (error) {
            throw error as APIUtilError;
        }
    }

    public async bindInst(userId: string, instId: string) {
        try {
            return await APIInstance.put(`/cp-manager/user/${userId}/institution`, { org_id: instId });
        } catch (error) {
            throw error as APIUtilError;
        }
    }

    public async deleteUserInst(userId: string) {
        try {
            return await APIInstance.delete(`/cp-manager/user/${userId}/institution`);
        } catch (error) {
            throw error as APIUtilError;
        }
    }

    public async saveSecure(data: any) {
        try {
            let params: any = {};
            if (data.inn) params = { ...params, inn: data.inn };
            if (data.snils) params = { ...params, snils: data.snils };
            return await APIInstance.patch(`/cp-manager/user/${data.id}/secure`, params);
        } catch (error: any) {
            throw error as APIUtilError;
        }
    }

    public async getUserMember(userId: string): Promise<UserMemberResponse> {
        try {
            const result = await APIInstance.get(`/cp-manager/user/${userId}/member`);
            return result.data;
        } catch (error: any) {
            throw error as APIUtilError;
        }
    }

    public async getUserMembersRaw(userId: string): Promise<UserMemberRawResponse> {
        try {
            const result = await APIInstance.get(`/cp-manager/user/${userId}/member-raw`);
            return result.data;
        } catch (error: any) {
            throw error as APIUtilError;
        }
    }

    public async getUserInstPermissions(userId: string): Promise<UserInstPermissionsResponse> {
        try {
            const result = await APIInstance.get(`/cp-manager/user/${userId}/inst-permission`);
            return result.data;
        } catch (error: any) {
            throw error as APIUtilError;
        }
    }

    public async editUserInstPermissions(userId: string, data: any): Promise<UserInstPermissionResponse> {
        try {
            const result = await APIInstance.put(`/cp-manager/user/${userId}/inst-permission`, { ...data });
            return result.data;
        } catch (error: any) {
            throw error as APIUtilError;
        }
    }

    public async getUserInstPermissionsRequest(userId: string): Promise<UserInstPermissionsRequestsResponse> {
        try {
            const result = await APIInstance.get(`/cp-manager/user/${userId}/inst-permission/request`);
            return result.data;
        } catch (error: any) {
            throw error as APIUtilError;
        }
    }

    public async acceptRejectInstPermissionsRequest(
        userId: string,
        userInstId: number,
        status: boolean
    ): Promise<UserInstPermissionsRequestResponse> {
        try {
            const result = await APIInstance.put(`/cp-manager/user/${userId}/inst-permission/request`, { user_inst_id: userInstId, status });
            return result.data;
        } catch (error: any) {
            throw error as APIUtilError;
        }
    }

    // USER PERMISSIONS

    public async getUserPermissionsDict(): Promise<UserPermissionsResponse> {
        try {
            const result = await APIInstance.get<UserPermissionsResponse>(`/cp-manager/user/permission/dict`);
            return result.data;
        } catch (error) {
            throw error as APIUtilError;
        }
    }

    public async getUserPermissions(userId: string): Promise<UserPermissionsResponse> {
        try {
            const result = await APIInstance.get<UserPermissionsResponse>(`/cp-manager/user/${userId}/permission`);
            return result.data;
        } catch (error) {
            throw error as APIUtilError;
        }
    }

    public async editUserPermissions(userId: string, permissions: number[]) {
        try {
            await APIInstance.put(`/cp-manager/user/${userId}/permission`, { permissions });
        } catch (error) {
            throw error as APIUtilError;
        }
    }

    // FILES

    public uploadURL(userId: string) {
        return `${BaseURL}/cp-manager/user/${userId}/organisation/file`;
    }

    public getDownloarFileUrl(userId: string, fileUUID: string) {
        return `${BaseURL}/cp-manager/user/${userId}/organisation/file/${fileUUID}`;
    }

    public uploadHeaders = async () => {
        let accessToken = RootModel?.authModel?.getAccessToken();
        if (!accessToken) {
            await RootModel?.authModel?.updateToken();
            accessToken = RootModel?.authModel?.getAccessToken();
        }
        return { Authorization: RootModel?.authModel ? `Bearer ${accessToken}` : '' };
    };

    public async deleteFile(userId: string, fileUUID: string) {
        try {
            const result = await APIInstance.delete(`/cp-manager/user/${userId}/organisation/file/${fileUUID}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getUserFiles(userId: string): Promise<UserOrgFilesResponse> {
        try {
            const result = await APIInstance.get<UserOrgFilesResponse>(`/cp-manager/user/${userId}/organisation/file`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async saveFileTitle(userId: string, fileUUID: string, title: string) {
        try {
            const result = await APIInstance.put(`/cp-manager/user/${userId}/organisation/file/${fileUUID}`, { file_title: title });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    // HISTORY

    public async getHistoryItems(userId: string, page: number | undefined, pageSize: number | undefined) {
        try {
            const result = await APIInstance.get<UserHistoryLoggerDataListResponse>(`/cp-manager/user/${userId}/history`, {
                params: deleteUndefined({
                    limit: pageSize,
                    offset: pageSize && page ? pageSize * (page - 1) : 0
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getHistoryItem(userId: string, historyId: number) {
        try {
            const result = await APIInstance.get<UserHistoryLoggerDataItemResponse>(`/cp-manager/user/${userId}/history/${historyId}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }
}
