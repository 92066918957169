import { DragDropContext, Droppable, Draggable, DropResult, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';
import { IndicatorFormulaPart, indicatorFormulaPartValues } from '@models/indicator-model';
import { Button, Col, Empty, Row, Typography } from 'antd';
import PartView from './formula.edit.part.view';
import { BlockMath } from 'react-katex';
import { extractFormulaString } from '../utils/formula.util';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ResultTypeDictionaryData } from '@models/dictionary-model';

export interface Props {
    formula: IndicatorFormulaPart[];
    resultTypes: ResultTypeDictionaryData[];
    onEdit: (formula: IndicatorFormulaPart[]) => void;
}

const FormulaEditView: React.FC<Props> = ({ formula: parts, resultTypes, onEdit }) => {
    const { t } = useTranslation();
    const grid = 10;

    const getItemStyle = (isColorful: boolean, isDragging: boolean, draggableStyle?: DraggingStyle | NotDraggingStyle | undefined) => ({
        margin: `0 ${grid}px 0 0`,
        background: isColorful ? (isDragging ? '#91caff' : '#bae0ff') : isDragging ? '#fff1b8' : '#fffbe6',
        ...draggableStyle,
        borderRadius: 10
    });

    const getListStyle = (isDraggingOver: boolean) => ({
        background: isDraggingOver ? '#f0f0f0' : '#f5f5f5',
        display: 'flex',
        padding: grid,
        overflow: 'auto',
        borderRadius: 10
    });

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }
        let reorderedItems = reorder(parts, result.source.index, result.destination.index);
        onEdit(reorderedItems);
    };

    const reorder = (list: IndicatorFormulaPart[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onClickCreate = () => {
        let partsCopy = [...parts];
        partsCopy.push(new IndicatorFormulaPart());
        onEdit(partsCopy);
    };

    const onEditPart = (editedPart: IndicatorFormulaPart) => {
        let partsCopy = [...parts];
        const index = partsCopy.map((part) => part.internal_dnd_id).indexOf(editedPart.internal_dnd_id);
        if (index === -1) return;
        partsCopy[index] = editedPart;

        onEdit(partsCopy);
    };

    const onDeletePart = (editedPart: IndicatorFormulaPart) => {
        let partsCopy = [...parts];
        const index = partsCopy.map((part) => part.internal_dnd_id).indexOf(editedPart.internal_dnd_id);
        if (index === -1) return;
        partsCopy.splice(index, 1);
        onEdit(partsCopy);
    };

    return (
        <div>
            <Row gutter={12} justify="center" align="middle">
                <Col>
                    <BlockMath math={extractFormulaString(parts) || '\\small{formula}'} />
                </Col>
                <Col>
                    <Button type="primary" icon={<PlusOutlined />} size="large" onClick={onClickCreate} />
                </Col>
            </Row>
            <br />
            {parts.length === 0 ? (
                <Empty
                    description={
                        <span>
                            <Typography.Text>{t('indicator.content.indicator_formula.empty')}</Typography.Text>
                        </span>
                    }
                />
            ) : (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
                                {parts.map((part, index) => (
                                    <Draggable key={part.internal_dnd_id} draggableId={part.internal_dnd_id || ''} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{
                                                    userSelect: 'none',
                                                    ...getItemStyle(
                                                        part.type === indicatorFormulaPartValues[0],
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )
                                                }}
                                            >
                                                <PartView part={part} resultTypes={resultTypes} onEdit={onEditPart} onDelete={onDeletePart} />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}
        </div>
    );
};

export default FormulaEditView;
