import { deleteUndefined } from '@utils/object.utils';
import { GetNoticeTemplatesResponse, NoticeTemplateData, NoticeTemplateResponse } from './notice-template.contract';
import { NoticeTemplateGetFilter } from '@interfaces/notice-template.interface';
import { APIInstance } from '@utils/api';

export class NoticeTemplateModel {
    public async getTemplates(params: NoticeTemplateGetFilter): Promise<GetNoticeTemplatesResponse> {
        try {
            const result = await APIInstance.get<GetNoticeTemplatesResponse>('/cp-manager/notice-template', {
                params: deleteUndefined({
                    limit: params.page_size,
                    offset: params.page_size && params.page ? params.page_size * (params.page - 1) : 0
                })
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getTemplateById(id: string | number): Promise<NoticeTemplateResponse> {
        try {
            const result = await APIInstance.get<NoticeTemplateResponse>(`/cp-manager/notice-template/${id}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async update(template: NoticeTemplateData): Promise<NoticeTemplateResponse> {
        try {
            const result = await APIInstance.put<NoticeTemplateResponse>(`/cp-manager/notice-template/${template.id}`, { ...template });
            return result.data;
        } catch (error) {
            throw error;
        }
    }
}
