import { observer } from 'mobx-react-lite';
import React from 'react';
import { Col, Row, Typography, notification } from 'antd';
import { useViewModel } from '@models/model';
import { useTranslation } from 'react-i18next';
import { DictionariesViewModel } from './dictionaries.view.model';
import { DictionaryInfo } from '@models/dictionary-model';
import { DataTable } from './table';

const DictionariesView: React.FC = observer(() => {
    const { t } = useTranslation();
    const [notifyer, contextHolder] = notification.useNotification();

    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const viewModel = useViewModel(
        () =>
            new DictionariesViewModel({
                t,
                showError: showErrorNotification
            })
    );

    const onShowDictionary = (altname?: string | undefined) => {
        if (!altname) return;
        window.open(`/dictionaries-manage/${altname || ''}`, '_blank', 'noreferrer');
    };

    return (
        <div>
            {contextHolder}
            <Row gutter={12} align="middle" justify="space-between">
                <Col>
                    <Typography.Title>{t('dictionaries.title')}</Typography.Title>
                </Col>
            </Row>
            <br />
            <DataTable
                rows={viewModel.dictionaries}
                onEdit={(dictionaryInfo: DictionaryInfo) => onShowDictionary(dictionaryInfo.altname)}
                loading={viewModel.isLoading}
            />
            <br />
        </div>
    );
});

export default DictionariesView;
