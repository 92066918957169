import { WebsiteMenuData, WebsiteMenuModel } from '@models/website-menu-model';
import { APIUtilError, isAPIError } from '@utils/api';
import { makeAutoObservable, runInAction } from 'mobx';

export interface WebsiteMenuViewModelStartParams {
    t: any;
    menuId?: string | undefined;
    showError: (title: string, description: string) => void;
}

export class WebsiteMenuViewModel {
    private websiteMenuModel = new WebsiteMenuModel();
    public isLoading: boolean = false;

    public menusTotal: number = 0;
    public menus: WebsiteMenuData[] = [];
    public menuOnEdit?: WebsiteMenuData;

    constructor(private startParams: WebsiteMenuViewModelStartParams) {
        makeAutoObservable(this);
        this.fetchMenus();
        if (startParams.menuId) this.setMenuEdit(startParams.menuId);
    }

    private async fetchMenus() {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.websiteMenuModel.get();
            runInAction(() => {
                this.menus = result.data;
                this.menusTotal = result.total;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public onEdit = (id: number) => {
        this.setMenuEdit(`${id}`);
    };

    public onCancelEdit = () => {
        runInAction(() => {
            this.menuOnEdit = undefined;
        });
    };

    private async setMenuEdit(id: string) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.websiteMenuModel.getById(id);
            runInAction(() => {
                this.menuOnEdit = result.data;
            });
        } catch (error) {
            this.startParams.showError(this.startParams.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public async update(menu: WebsiteMenuData): Promise<string | undefined> {
        runInAction(() => {
            this.isLoading = true;
        });

        try {
            await this.websiteMenuModel.updateMenuItems(menu.id, menu.items);
            let copy = [...this.menus];
            const index = copy.map((item) => item.id).indexOf(menu.id);
            copy[index].items = menu.items;
        } catch (error) {
            return isAPIError(error) ? error.localizedDescription : 'Unknown Error';
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }
}
