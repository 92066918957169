import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import '@assets/css/App.css';
import { Button, Col, Form, Input, notification, Row, Spin, Switch, Divider, Breadcrumb, Select, Space, Tag } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useViewModel } from '@models/model';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { FiltersEditViewModel } from './statement-filters.view.model';
import { plainToInstance } from 'class-transformer';
import { useNavigate, useParams } from 'react-router-dom';
import { StatementFilterData, StatementFilterItem } from '@models/statements-model';
import { EditableTable } from './table';
import EditStatementFilterItem from './statement-filter.edit.view';

const FiltersEditView: React.FC = observer(() => {
    const { t } = useTranslation();
    const { id: statementId, filter_id: filterId } = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm<StatementFilterData>();
    const [showDictInput, setShowDictInput] = useState<boolean>(false);

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (description: string) => {
        notifyer.error({
            message: t('common.error.empty'),
            description: description
        });
    };
    const showSuccessNotification = (description: string) => {
        notifyer.success({
            message: t('common.saved'),
            description: description
        });
    };

    const onFinish = () => {
        onClickCancel();
    };

    const onClickCancel = () => {
        navigate(`/statements-manage/edit/${statementId}`);
    };

    const viewModel = useViewModel(
        () =>
            new FiltersEditViewModel(
                statementId,
                filterId,
                (description: string) => showErrorNotification(description),
                (description: string) => showSuccessNotification(description),
                onFinish
            )
    );

    useEffect(() => {
        form.resetFields();
        setShowDictInput(viewModel.filter.is_dict);
    }, [viewModel.filter]);

    const onClickSave = () => form.submit();

    const getFormData = () => {
        const valFromFields: Record<string, any> = form.getFieldsValue();
        var converted: StatementFilterData = plainToInstance(StatementFilterData, { ...viewModel.filter, ...valFromFields });

        return converted;
    };

    const onSuccessFormValidation = async () => {
        const data = getFormData();
        if (data.id) {
            await viewModel.editFilter(data);
        } else {
            const new_filter = await viewModel.createFilter(data);
            navigate(`/statements-manage/edit/${statementId}/filters/${new_filter?.id}`);
        }
    };

    const onValuesChange = (changed: any) => {
        if (changed.is_dict === true) setShowDictInput(true);
        else if (changed.is_dict === false) setShowDictInput(false);
    };

    const statementTitle = `${viewModel.statement?.name || ''}`;
    const filterTitle = `${viewModel.filter?.desc || ''}`;

    const onCloseConstructor = () => {
        navigate(`/statements-manage/edit/${statementId}/filters/${filterId}`);
        viewModel.onCancelEdit();
    };

    const onEditItem = (StatementFilterItemId?: number) => {
        if (!StatementFilterItemId) return;
        navigate(`${StatementFilterItemId || ''}`);
        viewModel.onEditItem(StatementFilterItemId);
    };

    const onDeleteItem = (StatementFilterItemId?: number) => {
        if (!StatementFilterItemId) return;
        viewModel.onDeleteItem(StatementFilterItemId);
    };

    return (
        <div>
            <br />
            <Breadcrumb
                items={[
                    { title: <a href="/statements-manage">{t('statements.title')}</a> },
                    { title: <a href={`/statements-manage/edit/${statementId}`}>{statementTitle}</a> },
                    { title: filterTitle }
                ]}
            />

            <Spin spinning={viewModel.loading}>
                <div>
                    {contextHolder}
                    <Divider orientation="left">{t('statements.filters.edit')}</Divider>

                    <div className="edit-content-view">
                        <Row gutter={12} justify="end" align="middle">
                            <Col></Col>
                            <Col>
                                <Button type="primary" onClick={onClickSave} loading={viewModel.loadingFilters} disabled={viewModel.loadingFilters}>
                                    {t('common.save')}
                                </Button>
                            </Col>
                        </Row>
                        <br />

                        <Form
                            form={form}
                            initialValues={viewModel.filter}
                            layout="horizontal"
                            onFinish={onSuccessFormValidation}
                            onValuesChange={onValuesChange}
                        >
                            <Form.Item
                                label={t('statements.filters.name.title')}
                                name="name"
                                rules={[{ required: true, message: t('common.required') || '' }]}
                            >
                                <Input placeholder={t('statements.filters.name.title') || ''} />
                            </Form.Item>

                            <Form.Item
                                label={t('statements.filters.desc.title')}
                                name="desc"
                                rules={[{ required: true, message: t('common.required') || '' }]}
                            >
                                <Input placeholder={t('statements.filters.desc.title') || ''} />
                            </Form.Item>

                            <Form.Item
                                label={t('statements.filters.depends_on.title')}
                                tooltip={t('statements.filters.depends_on.hint')}
                                name="depends_on"
                            >
                                <Select style={{ minWidth: '200px' }} allowClear={true}>
                                    {viewModel.filters &&
                                        viewModel.filters.map((f, i) => (
                                            <Select.Option value={f.id} label={`${f.id}: ${f.desc}`} key={`avail_filter_${i}`}>
                                                <Space direction="vertical">
                                                    <Tag>{`${f.id}: ${f.desc}`}</Tag>
                                                </Space>
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label={t('statements.filters.query.title')}
                                tooltip={t('statements.filters.query.hint')}
                                name="query"
                                rules={[{ required: true, message: t('common.required') || '' }]}
                            >
                                <TextArea rows={1} placeholder={t('statements.filters.query.hint') || ''} />
                            </Form.Item>

                            <Form.Item
                                label={t('statements.filters.parameter.title')}
                                tooltip={t('statements.content.parameter.hint')}
                                name="parameter"
                                rules={[{ required: true, message: t('common.required') || '' }]}
                            >
                                <Input placeholder={t('statements.filters.parameter.hint') || ''} />
                            </Form.Item>

                            <Form.Item
                                name="is_dict"
                                label={t('statements.filters.is_dict.title')}
                                tooltip={t('statements.filters.is_dict.desc')}
                                valuePropName="checked"
                            >
                                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                            </Form.Item>

                            {showDictInput && (
                                <Form.Item
                                    label={t('statements.filters.dict_ref.title')}
                                    tooltip={t('statements.filters.dict_ref.hint')}
                                    name="dict_ref"
                                    rules={[{ required: true, message: t('common.required') || '' }]}
                                >
                                    <Select options={viewModel.dicts} />
                                </Form.Item>
                            )}

                            <Form.Item
                                name="is_show"
                                label={t('statements.filters.is_show.title')}
                                tooltip={t('statements.filters.is_show.hint')}
                                valuePropName="checked"
                            >
                                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                            </Form.Item>
                        </Form>
                    </div>

                    {!showDictInput && (
                        <div>
                            <Divider orientation="left">{t('statements.filters.values')}</Divider>

                            <div className="edit-content-view">
                                <Row gutter={12} justify="end" align="middle">
                                    <Col></Col>
                                    <Col>
                                        <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => viewModel.onCreateStatementFilterItem()}>
                                            {t('common.create')}
                                        </Button>
                                    </Col>
                                </Row>
                                <br />

                                <EditableTable
                                    loading={viewModel.loadingStatementFilterItems}
                                    rows={viewModel.StatementFilterItems}
                                    onEdit={onEditItem}
                                    onDelete={onDeleteItem}
                                />
                            </div>
                            {viewModel.StatementFilterItemOnEdit && (
                                <EditStatementFilterItem
                                    visible={!!viewModel.StatementFilterItemOnEdit}
                                    StatementFilterItem={viewModel.StatementFilterItemOnEdit}
                                    onClose={onCloseConstructor}
                                    onSave={(item: StatementFilterItem) => viewModel.editStatementFilterItem(item)}
                                    onCreate={(item: StatementFilterItem) => viewModel.createStatementFilterItem(item)}
                                />
                            )}
                        </div>
                    )}
                    <br />
                </div>
            </Spin>
        </div>
    );
});

export default FiltersEditView;
