import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import '@assets/css/App.css';
import { Button, Col, Divider, Form, Input, notification, Row, Select, Space, Spin, Switch, Tooltip, Typography } from 'antd';
import { useViewModel } from '@models/model';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined, UsergroupAddOutlined, CalculatorOutlined, ExperimentOutlined } from '@ant-design/icons';
import { StartupEditViewModel } from './startup.edit.view.model';
import { plainToInstance } from 'class-transformer';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { StartupData } from '@models/startup-model';
import { formatDate } from '@utils/date.utils';
import WYSIWYGEditor from '@components/UI/WYSIWYG/WYSIWYG.editor';

const StartupEditView: React.FC = observer(() => {
    const { t, i18n } = useTranslation();
    const { id: startupId } = useParams();
    const navigate = useNavigate();

    const [notifyer, contextHolder] = notification.useNotification();
    const showSuccessNotification = (title: string, description: string) => {
        notifyer.success({
            message: title,
            description: description
        });
    };
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const onClickCancel = () => {
        navigate('/startups-manage');
    };

    const onClickShowMembers = () => {
        if (!viewModel.startup.id) return;

        const searchparams = createSearchParams({
            startup_id: `${viewModel.startup.id}`
        }).toString();

        window.open('/members-manage?' + searchparams, '_blank', 'noreferrer');
    };

    const onClickShowFinance = () => {
        if (!viewModel.startup.id) return;

        const searchparams = createSearchParams({
            startup_id: `${viewModel.startup.id}`
        }).toString();

        window.open('/finances-manage?' + searchparams, '_blank', 'noreferrer');
    };

    const onClickShowRid = () => {
        if (!viewModel.startup.id) return;

        const searchparams = createSearchParams({
            startup_id: `${viewModel.startup.id}`
        }).toString();

        window.open('/rids-manage?' + searchparams, '_blank', 'noreferrer');
    };

    const viewModel = useViewModel(
        () =>
            new StartupEditViewModel({
                t,
                startupId,
                showSuccess: showSuccessNotification,
                showError: showErrorNotification
            })
    );
    const [form] = Form.useForm<StartupData>();

    useEffect(() => {
        form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModel.startup]);

    const onClickSave = () => form.submit();

    const getFormData = () => {
        const valFromFields: Record<string, any> = form.getFieldsValue();
        var converted: StartupData = plainToInstance(StartupData, { ...viewModel.startup, ...valFromFields });

        return converted;
    };

    const onSuccessFormValidation = async () => {
        const data = getFormData();
        await viewModel.onSave(data);
    };

    const getStartupTypeSelectorItems = () =>
        viewModel.startupTypes.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        });

    const getStartupDirectionsSelectorItems = () =>
        viewModel.startupDirections.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        });
    const getRegionSelectorContent = () =>
        viewModel.regionDictionary.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        });

    const getTRLSelectorContent = () =>
        viewModel.trlDictionary.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        });

    return (
        <div>
            <br />

            <div>
                {contextHolder}
                <Row gutter={12} justify="end" align="middle">
                    <Col>
                        <Button onClick={onClickCancel}>{t('common.cancel')}</Button>
                    </Col>
                    <Col>
                        <Button type="primary" onClick={onClickSave} loading={viewModel.isLoading} disabled={viewModel.isLoading}>
                            {t('common.save')}
                        </Button>
                    </Col>
                </Row>

                <br />
                <Form form={form} initialValues={viewModel.startup} layout="horizontal" onFinish={onSuccessFormValidation}>
                    <div className="edit-content-view">
                        <Spin spinning={viewModel.isLoading}>
                            <Form.Item>
                                <Space size="large">
                                    <div>
                                        <Typography.Text>ID: </Typography.Text>
                                        <Typography.Text strong copyable>
                                            {viewModel.startup.id}
                                        </Typography.Text>
                                    </div>
                                    <div>
                                        <Typography.Text>{t('startups.content.startup_date')}: </Typography.Text>
                                        <Typography.Text strong>
                                            {formatDate(viewModel.startup.startup_date, t('common.date.pattern.date'))}
                                        </Typography.Text>
                                    </div>
                                </Space>
                            </Form.Item>

                            <Form.Item
                                name="name"
                                label={t('startups.content.name.title')}
                                rules={[{ required: true, message: t('common.required') || '' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name={'startup_type_id'}
                                label={t('startups.content.type.title')}
                                rules={[{ required: true, message: t('common.required') || '' }]}
                            >
                                <Select
                                    options={getStartupTypeSelectorItems()}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                />
                            </Form.Item>

                            <Form.Item
                                name={'startup_direction_id'}
                                label={t('startups.content.direction')}
                                rules={[{ required: true, message: t('common.required') || '' }]}
                            >
                                <Select
                                    options={getStartupDirectionsSelectorItems()}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                />
                            </Form.Item>

                            <Form.Item name={'startup_direction_ids'} label={t('startups.content.directions')}>
                                <Select
                                    mode="multiple"
                                    options={getStartupDirectionsSelectorItems()}
                                    showSearch
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    allowClear
                                    placeholder={t('common.sorting.not_selected')}
                                />
                            </Form.Item>

                            <Form.Item name="target_description" label={t('startups.content.target.title')} rules={[]}>
                                <Input.TextArea rows={3} placeholder={t('startups.content.target.placeholder') || ''} />
                            </Form.Item>

                            <Form.Item
                                name="has_mvp"
                                label={t('startups.content.has_mvp.title')}
                                tooltip={t('startups.content.has_mvp.hint')}
                                valuePropName="checked"
                            >
                                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                            </Form.Item>

                            <Form.Item name={'trl_id'} label={t('startups.content.trl.title')} tooltip={t('startups.content.trl.hint')}>
                                <Select
                                    options={getTRLSelectorContent()}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                />
                            </Form.Item>

                            <Form.Item name="startup_plan" label={t('startups.content.plan.title')} rules={[]}>
                                <Input.TextArea rows={3} placeholder={t('startups.content.plan.placeholder') || ''} />
                            </Form.Item>

                            <Form.Item name={'region_id'} label={t('startups.content.region.title')}>
                                <Select
                                    options={getRegionSelectorContent()}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                />
                            </Form.Item>

                            <Form.Item label={t('startups.content.member_count.title')}>
                                <Tooltip title={t('startups.content.member_count.show')}>
                                    <Button
                                        style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                                        type="text"
                                        onClick={onClickShowMembers}
                                        disabled={viewModel.startup.members_count ? false : true}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                                            <UsergroupAddOutlined style={{ fontSize: 18, color: 'gray' }} />
                                            <Typography.Text style={{ fontSize: 18, paddingTop: 2, paddingLeft: 4 }}>
                                                {new Intl.NumberFormat('ru-ru', {}).format(viewModel.startup.members_count || 0)}
                                            </Typography.Text>
                                        </div>
                                    </Button>
                                </Tooltip>
                            </Form.Item>
                            {/*  */}
                            <Form.Item label={t('startups.content.finance_count.title')}>
                                <Tooltip title={t('startups.content.finance_count.show')}>
                                    <Button
                                        style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                                        type="text"
                                        onClick={onClickShowFinance}
                                        disabled={viewModel.startup.finance_count ? false : true}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                                            <CalculatorOutlined style={{ fontSize: 18, color: 'gray' }} />
                                            <Typography.Text style={{ fontSize: 18, paddingTop: 2, paddingLeft: 4 }}>
                                                {new Intl.NumberFormat('ru-ru', {}).format(viewModel.startup.finance_count || 0)}
                                            </Typography.Text>
                                        </div>
                                    </Button>
                                </Tooltip>
                            </Form.Item>

                            <Form.Item label={t('startups.content.rid_count.title')}>
                                <Tooltip title={t('startups.content.rid_count.show')}>
                                    <Button
                                        style={{ padding: 0, paddingTop: 0, paddingLeft: 5, paddingRight: 5 }}
                                        type="text"
                                        onClick={onClickShowRid}
                                        disabled={viewModel.startup.rid_count ? false : true}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                                            <ExperimentOutlined style={{ fontSize: 18, color: 'gray' }} />
                                            <Typography.Text style={{ fontSize: 18, paddingTop: 2, paddingLeft: 4 }}>
                                                {new Intl.NumberFormat('ru-ru', {}).format(viewModel.startup.rid_count || 0)}
                                            </Typography.Text>
                                        </div>
                                    </Button>
                                </Tooltip>
                            </Form.Item>
                        </Spin>
                    </div>

                    <br />
                    <Divider orientation="left">{t('startups.content.idea_description.title')}</Divider>
                    <div className="edit-content-view">
                        <Form.Item name="idea_description" rules={[]}>
                            <WYSIWYGEditor
                                editorDefaultValue={viewModel.startup.idea_description || ''}
                                placeholder={t('startups.content.idea_description.placeholder') || ''}
                                languageCode={i18n.language}
                                onChange={(content: string) => form.setFieldValue('idea_description', content)}
                            />
                        </Form.Item>
                    </div>

                    <br />
                    <Divider orientation="left">{t('startups.content.org_info')}</Divider>
                    <div className="edit-content-view">
                        <Form.Item name="startup_address" label={t('startups.content.address.title')} rules={[]}>
                            <Input.TextArea rows={2} placeholder={t('startups.content.address.placeholder') || ''} />
                        </Form.Item>

                        <Form.Item name="inn" label={t('common.inn')}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="kpp" label={t('common.kpp')}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="ogrn" label={t('common.ogrn')}>
                            <Input />
                        </Form.Item>
                    </div>
                </Form>
            </div>
            <br />
            <br />
        </div>
    );
});

export default StartupEditView;
