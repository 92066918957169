import React from 'react';
import { useViewModel } from '@models/model';
import { Button, Col, Divider, Empty, Form, Row, Segmented, Select, Spin, Typography, UploadFile, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { DigitalFootprintFileData } from '@models/digital-footprint/files';
import { FootprintFileCreateViewModel } from './digital-footprint-file.create.view.model';
import { plainToInstance } from 'class-transformer';
import FilesCatalogView from '@components/UI/files-catalog/catalog.view';
import { CatalogFile } from '@components/UI/files-catalog';
import { formatDate } from '@utils/date.utils';

const DigitalFootprintFileCreateView: React.FC = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [notifyer, contextHolder] = notification.useNotification();
    const showSuccessNotification = (title: string, description: string) => {
        notifyer.success({
            message: title,
            description: description
        });
    };
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const onClickCancel = () => {
        navigate('/digital-footprint-files');
    };

    const [form] = Form.useForm<DigitalFootprintFileData>();
    const clearFormEvent = () => {
        form.resetFields(['event_id']);
    };
    const clearFormMember = () => {
        form.resetFields(['member_id']);
    };

    const viewModel = useViewModel(
        () =>
            new FootprintFileCreateViewModel({
                t,
                showSuccess: showSuccessNotification,
                showError: showErrorNotification,
                close: onClickCancel,
                clearEvent: clearFormEvent,
                clearMember: clearFormMember
            })
    );

    const onClickSave = () => form.submit();

    const getFormData = () => {
        const valFromFields: Record<string, any> = form.getFieldsValue();
        var converted: DigitalFootprintFileData = plainToInstance(DigitalFootprintFileData, { ...valFromFields });
        return converted;
    };

    const onSuccessFormValidation = async () => {
        const data = getFormData();
        await viewModel.onSave(data);
    };

    const getFileTypeSelectorContent = () =>
        viewModel.fileTypesDictionary.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        });

    const memberSelector = () => {
        return (
            <Form.Item
                name="member_id"
                label={t('digital_footprint.files.content.member.title')}
                rules={[{ required: true, message: t('digital_footprint.files.create.required') || '' }]}
            >
                <Select
                    value={viewModel.selectedMember}
                    onChange={viewModel.onSelectMember}
                    showSearch
                    autoClearSearchValue
                    allowClear
                    notFoundContent={<Empty description={''} />}
                    placeholder={t('digital_footprint.filter.member.placeholder')}
                    onSearch={(value: string) => viewModel.searchMembers(value)}
                    loading={viewModel.isMembersLoading}
                    filterOption={false}
                >
                    {viewModel.members.map((item, i) => (
                        <Select.Option value={item.id} key={`org_${i}`}>
                            <Typography.Text>
                                {item.last_name} {item.first_name} {item.middle_name}
                            </Typography.Text>
                            {item.birth_date && item.birth_date.length > 0 && (
                                <Typography.Text type="secondary"> ({formatDate(item.birth_date, t('common.date.pattern.date'))})</Typography.Text>
                            )}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        );
    };

    const eventSelector = () => {
        return (
            <Form.Item
                name="event_id"
                label={t('digital_footprint.files.content.event.title')}
                rules={[{ required: true, message: t('digital_footprint.files.create.required') || '' }]}
            >
                <Select
                    onChange={viewModel.onSelectEvent}
                    autoClearSearchValue
                    showSearch
                    allowClear
                    notFoundContent={<Empty description={''} />}
                    placeholder={t('digital_footprint.files.content.event.placeholder')}
                    onSearch={(value: string) => viewModel.searchEvents(value)}
                    loading={viewModel.isEventsLoading}
                    filterOption={false}
                >
                    {viewModel.events.map((item, i) => (
                        <Select.Option value={item.id} key={`org_${i}`}>
                            <Typography.Text>{item.name}</Typography.Text>
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        );
    };

    return (
        <div>
            {contextHolder}
            <Row gutter={12} align="middle" justify="space-between">
                <Col>
                    <Typography.Title>{t('digital_footprint.files.create.title')}</Typography.Title>
                </Col>
                <Col>
                    <Row gutter={12}>
                        <Col>
                            <Button
                                onClick={() => {
                                    onClickCancel();
                                }}
                            >
                                {t('common.cancel')}
                            </Button>
                        </Col>

                        <Col>
                            <Button
                                type="primary"
                                disabled={viewModel.currentStep === 'upload' ? !viewModel.isFileUpdloaded : false}
                                loading={viewModel.isLoading}
                                onClick={() => {
                                    switch (viewModel.currentStep) {
                                        case 'create':
                                            onClickSave();
                                            break;
                                        case 'upload':
                                            viewModel.onFinishUpload();
                                            break;
                                        default:
                                            break;
                                    }
                                }}
                            >
                                {t('common.next')}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {viewModel.currentStep === 'upload' && (
                <div>
                    <div className="edit-content-view">
                        <Spin spinning={viewModel.isLoading}>
                            <FilesCatalogView
                                disabled={viewModel.currentStep !== 'upload'}
                                name="file"
                                uploadURL={viewModel.getUploaderURL()}
                                getHeaders={viewModel.getUploaderHeaders()}
                                changeOnUpload={true}
                                onChangeFileList={(list: UploadFile[] | CatalogFile[] | undefined) => viewModel.onChangeFileList(list)}
                                method="PUT"
                            />
                        </Spin>
                    </div>
                    <br />
                </div>
            )}

            <Form
                form={form}
                disabled={viewModel.currentStep === 'upload' || viewModel.currentStep === 'done'}
                layout="horizontal"
                onFinish={onSuccessFormValidation}
            >
                <div className="edit-content-view">
                    <Form.Item
                        name="file_type_id"
                        label={t('digital_footprint.files.content.type.title')}
                        rules={[{ required: true, message: t('digital_footprint.files.create.required') || '' }]}
                    >
                        <Select
                            options={getFileTypeSelectorContent()}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            allowClear
                            placeholder={t('digital_footprint.files.content.type.placeholder')}
                        />
                    </Form.Item>
                    <Divider />
                    <Form.Item
                        label={t('digital_footprint.files.create.segmented.title')}
                        tooltip={t('digital_footprint.files.create.segmented.hint')}
                    >
                        <Segmented
                            disabled={viewModel.currentStep !== 'create'}
                            options={viewModel.segmentedOptions}
                            value={viewModel.selectedSegment}
                            onChange={(value: any) => viewModel.onChangeSegmented(value)}
                        />
                    </Form.Item>
                    {viewModel.isEventFirst() ? (
                        <div>
                            {eventSelector()}
                            {memberSelector()}
                        </div>
                    ) : (
                        <div>
                            {memberSelector()}
                            {eventSelector()}
                        </div>
                    )}
                </div>
            </Form>
        </div>
    );
});

export default DigitalFootprintFileCreateView;
