import { Alert, Button, Card, Divider, Drawer, Form, Input, Space, Typography } from 'antd';
import '@assets/css/App.css';
import { plainToInstance } from 'class-transformer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatementFilterItem } from '@models/statements-model';

interface Props {
    visible: boolean;
    StatementFilterItem: StatementFilterItem;
    onClose: () => void;
    onSave: (StatementFilterItem: StatementFilterItem) => void;
    onCreate: (StatementFilterItem: StatementFilterItem) => void;
}

const EditStatementFilterItem: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [errorText, setErrorText] = useState<string | null>(null);

    useEffect(() => {
        form.resetFields();
    }, [props.StatementFilterItem]);

    const onClose = async () => {
        props.onClose();
    };

    const onSave = () => form.submit();

    const onSuccessFormValidation = async () => {
        try {
            const valFromFields: Record<string, any> = form.getFieldsValue();
            var converted: StatementFilterItem = plainToInstance(StatementFilterItem, {
                id: props.StatementFilterItem?.id,
                ...valFromFields
            });
            if (converted.id) {
                await save(converted);
            } else {
                await create(converted);
            }
        } catch (error) {
            showError(`${t('common.save')}: ${error}`);
        }
    };

    const save = async (item: StatementFilterItem) => {
        const result = await props.onSave(item);
        if (typeof result === 'string') {
            showError(`${t('common.error.save')}: ${result}`);
        } else {
            onClose();
        }
    };

    const create = async (item: StatementFilterItem) => {
        const result = await props.onCreate(item);
        if (typeof result === 'string') {
            showError(`${t('common.error.save')}: ${result}`);
        } else {
            onClose();
        }
    };

    const showError = (errorText: string) => {
        setErrorText(errorText);
        setTimeout(() => {
            setErrorText(null);
        }, 4000);
    };

    return (
        <Drawer
            forceRender
            title={t('members.edit.title')}
            width={500}
            onClose={onClose}
            open={props.visible}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
                <Space>
                    <Button onClick={onSave} type="primary">
                        {t('common.save')}
                    </Button>
                </Space>
            }
        >
            {errorText && (
                <Alert
                    style={{
                        marginBottom: 10
                    }}
                    message={errorText}
                    type="error"
                    showIcon
                />
            )}

            <Card title="id" size="small">
                <Typography.Text copyable>{props.StatementFilterItem.id}</Typography.Text>
            </Card>

            <Divider />
            <Form layout="vertical" form={form} initialValues={props.StatementFilterItem} requiredMark={true} onFinish={onSuccessFormValidation}>
                <Form.Item name="title" label={t('statements.filters.items.title')} rules={[{ required: true, message: t('common.required') || '' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="value" label={t('statements.filters.items.value')} rules={[{ required: true, message: t('common.required') || '' }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="sort_id"
                    label={t('statements.filters.items.sort_id')}
                    rules={[{ required: true, message: t('common.required') || '' }]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Drawer>
    );
};

export default EditStatementFilterItem;
