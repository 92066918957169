import { APIInstance } from '@utils/api';
import {
    GetStatementsResponse,
    GetStatementByIdResponse,
    StatementData,
    StatementFilters,
    StatementStatementFilterItemsResponse,
    StatementStatementFilterDataResponse,
    StatementFilterData,
    StatementStatementFilterItemResponse,
    StatementFilterItem,
    StatementDictsResponse
} from './statements.contract';
import { saveAs } from 'file-saver';

export class StatementsModel {
    public async getStatements(): Promise<GetStatementsResponse> {
        try {
            const result = await APIInstance.get<GetStatementsResponse>('/cp-manager/statements');
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getStatementById(_id: string | number): Promise<GetStatementByIdResponse> {
        try {
            const result = await APIInstance.get<GetStatementByIdResponse>(`/cp-manager/statements/${_id}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async createStatement(statement: StatementData): Promise<GetStatementByIdResponse> {
        try {
            const result = await APIInstance.post<GetStatementByIdResponse>(`/cp-manager/statements`, { ...statement });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async updateStatement(statement: StatementData): Promise<GetStatementByIdResponse> {
        try {
            const result = await APIInstance.put<GetStatementByIdResponse>(`/cp-manager/statements/${statement.id}`, { ...statement });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getFilters(statementId: number | string): Promise<StatementFilters> {
        try {
            const result = await APIInstance.get<StatementFilters>(`/cp-manager/statements/${statementId}/filters`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async getFilter(statementId: number | string, filterId: number | string): Promise<StatementStatementFilterDataResponse> {
        try {
            const result = await APIInstance.get<StatementStatementFilterDataResponse>(`/statements/${statementId}/filters/${filterId}`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async editFilter(
        statementId: number | string,
        filterId: number | string,
        StatementFilterData: StatementFilterData
    ): Promise<StatementStatementFilterDataResponse> {
        try {
            const result = await APIInstance.put<StatementStatementFilterDataResponse>(`/cp-manager/statements/${statementId}/filters/${filterId}`, {
                ...StatementFilterData
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async createFilter(statementId: number | string, StatementFilterData: StatementFilterData): Promise<StatementStatementFilterDataResponse> {
        try {
            const result = await APIInstance.post<StatementStatementFilterDataResponse>(`/cp-manager/statements/${statementId}/filters`, {
                ...StatementFilterData
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async createDefaultFilter(statementId: number | string, value: string): Promise<void> {
        try {
            await APIInstance.post<any>(`/cp-manager/statements/${statementId}/filters/default`, { value });
        } catch (error) {
            throw error;
        }
    }

    public async getStatementFilterItems(statementId: number | string, filterId: number | string): Promise<StatementStatementFilterItemsResponse> {
        try {
            const result = await APIInstance.get<StatementStatementFilterItemsResponse>(
                `/cp-manager/statements/${statementId}/filters/${filterId}/items`
            );
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async editStatementFilterItems(
        statementId: number | string,
        filterId: number | string,
        StatementFilterItem: StatementFilterItem
    ): Promise<StatementStatementFilterItemResponse> {
        try {
            const result = await APIInstance.put<StatementStatementFilterItemResponse>(
                `/cp-manager/statements/${statementId}/filters/${filterId}/items/${StatementFilterItem.id}`,
                { ...StatementFilterItem }
            );
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async createStatementFilterItems(
        statementId: number | string,
        filterId: number | string,
        StatementFilterItem: StatementFilterItem
    ): Promise<StatementStatementFilterItemResponse> {
        try {
            const result = await APIInstance.post<StatementStatementFilterItemResponse>(
                `/cp-manager/statements/${statementId}/filters/${filterId}/items`,
                {
                    ...StatementFilterItem
                }
            );
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async deleteStatementFilterItem(statementId: number | string, filterId: number | string, itemId: number | string): Promise<void> {
        try {
            await APIInstance.delete<StatementStatementFilterItemResponse>(
                `/cp-manager/statements/${statementId}/filters/${filterId}/items/${itemId}`
            );
        } catch (error) {
            throw error;
        }
    }

    public async getData(statementId: number | string, filters: any[]): Promise<StatementFilters> {
        try {
            const result = await APIInstance.post<StatementFilters>(`/cp-manager/statements/${statementId}`, {
                filters: filters,
                limit: 20,
                offset: 0
            });
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async exportData(statementId: number | string, filters: any[], ext: string) {
        try {
            await APIInstance({
                url: `/cp-manager/statements/${statementId}/export`,
                method: 'POST',
                data: { filters, ext },
                responseType: 'blob'
            }).then((response) => {
                saveAs(response.data, `report.${ext}`);
            });
        } catch (error) {
            throw error;
        }
    }

    public async getDicts(): Promise<StatementDictsResponse> {
        try {
            const result = await APIInstance.get<StatementDictsResponse>(`/cp-manager/statements/filters/dicts`);
            return result.data;
        } catch (error) {
            throw error;
        }
    }

    public async testQuery(query: string): Promise<any> {
        try {
            const result = await APIInstance.post<any>(`/cp-manager/statements/test_query`, { sql: query });
            return result.data;
        } catch (error) {
            throw error;
        }
    }
}
