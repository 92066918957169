// STATEMENT

export class StatementData {
    id?: number | undefined;
    name: string;
    query: string;
    count_query: string;
    is_visible: boolean = false;
}

export class GetStatementsResponse {
    data: StatementData[];
    total: number;
    count: number;
}

export class GetStatementByIdResponse {
    data: StatementData;
}

// FILTERS

export class StatementFilterItem {
    value: string | number;
    title: string;

    id?: number;
    filter_id?: number;
    sort_id?: number;
}

export class StatementFilterData {
    id: number;
    name: string;
    desc: string;
    query: string;
    depends_on: number;
    is_show: boolean = false;
    is_dict: boolean = false;
    dict_ref: string;
    dict_name?: string;
    parameter: string;
    values?: StatementFilterItem[];
}

export class StatementFilters {
    data: StatementFilterData[];
    total: number;
    count: number;
}

export class StatementStatementFilterDataResponse {
    data: StatementFilterData;
}

export class StatementStatementFilterItemsResponse {
    data: StatementFilterItem[];
    total: number;
    count: number;
}

export class StatementStatementFilterItemResponse {
    data: StatementFilterItem;
}

// DICTS

export class StatementDictItem {
    value: string;
    label: string;
}

export class StatementDictsResponse {
    data: StatementDictItem[];
    total: number;
    count: number;
}
