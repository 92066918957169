export class MemberData {
    id: number;
    birth_date: string;
    citizenship_id: number;
    email: string;
    first_name: string;
    inn: string;
    is_email_verify: boolean;
    is_phone_verify: boolean;
    is_snils_verify: null;
    last_name: string;
    middle_name: string;
    phone: string;
    snils: string;
    user_id?: string | undefined;
    event_count?: number | undefined;
    startup_count?: number | undefined;
    institution: MemberInstitution[];

    created_at: string;
    updated_at: string;
}

export class MemberInstitution {
    id: number;
    name: string;
    info: MemberInstitutionInfo[];
}

export class MemberInstitutionInfo {
    spec?: string;
    vo?: string;
    finance_source?: string;
    course_ed?: number;
    level_ed?: number;
    member_status: string;
}

export class GetMembersResponse {
    data: MemberData[];
    total: number;
    count: number;
}

export class MemberResponse {
    data: MemberData;
}
